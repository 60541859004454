import React from "react";
import { Link, withRouter } from "react-router-dom";
import config from "./admin/config.js"
import Footer from "./footer";
import Header from "./header";
import jwt from 'jsonwebtoken'

class Termsconditions extends React.Component{

    state={
        user_name:'',
        user_id:'',
        data:[],
    }
    
    componentDidMount(){
        console.log('componentDidMount');

        jwt.verify(localStorage.getItem("user_name"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({user_name:decoded.user_name});                             
            }
        }) 
          
        let sessionFlag = jwt.verify(localStorage.getItem("user_flag"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                return decoded                            
            }
        })  

        jwt.verify(localStorage.getItem("user_id"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({user_id:decoded.user_id});  
                this.getTermConditions(decoded.user_id,sessionFlag.user_flag);                              
            }
        })         
    }

    getTermConditions = (stdId,user_flag) => {
        let options={
            method:"POST",
            headers:{
            Accept:"application/json",
              "Content-Type":"application/json"
            },
            body:JSON.stringify({id:stdId,flag:user_flag}) 
        }
          fetch(`${config.googleAuth.backURL}user/getTermConditions`,options)
          .then(res=>{
                return res.json();
            })

            .then(data=>{
              if(data.status == true){
                    this.setState({data:data.data[0]});
              }
            })

          .catch(err=>{
            console.log("error",err)
        })         
           
    }

render(){
  return (
    <div class="appHdr">
    <header>
    <Header />
    </header>
    <section class="appBody wrapper">
                <div class="login-block form login">
                    <div class="login-row">
                        <div class="term-sec">
                            <div class="term-icn">
                                <img src="images/terms.png" />
                            </div>   
                            <div class="main-heading text-center">
                                <h2>Terms Conditions</h2>	
                            </div>
                            <div class="term-detail">
                                <p>{ this.state.data['text']!=='' ? this.state.data['text']:'Coming soon...    '}</p>
                            </div>
                        </div>    
                    </div>  
                </div> <Footer />
            </section>
        </div>
        );
    }
}
export default Termsconditions;