import React from "react";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./admin/config.js";
import $ from "jquery";

const axios = require("axios");
class Resetpassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.match.params.token,
      formdata: {
        newPass: "",
        confPass: "",
      },
      /*  Assets: [],
      errors: [], */
    };
  }

  componentDidMount() {
    var token = this.state.token;
  }

  mySubmit = (e) => {
    e.preventDefault();

    let error = 0;
    let arry = "";

    if (this.state.formdata.newPass === "") {
      toast("New Password can not be empty");
      error++;
    }

    if (this.state.formdata.confPass === "") {
      toast("Confirm Password can not be empty");
      error++;
    }

    if (this.state.formdata.newPass !== this.state.formdata.confPass) {
      toast("New password and confirm password does not match!!");
      error++;
    }

    console.log(error);

    //this.setState({ errors: arry })
    if (error > 0) {
      $("#error").html(arry);
    } else {
      let newObj = this.state.formdata;
      // newObj["user_id"] = this.state.user_id;
      newObj["token"] = this.state.token;
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newObj),
      };
      fetch(`${config.googleAuth.backURL}user/resetpasswordrequest`, options)
        .then((res) => {
          return res.json();
        })

        .then((data) => {
          if (data.status == true) {
            toast("Password updated!!");
            // setTimeout(() => this.props.history.push("/change-password"), 3000);
          } else {
            toast("Ops! You entered wrong  old password");
          }
        })

        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  changedata = (e) => {
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
    console.log(this.state);
  };
  render() {
    return (
      <div class="appHdr">
        <ToastContainer />
        <header>
          <div class="main-header">
            <div class="hdr-row">
              <a href="/" class="mdlBack-btn">
                <i class="fa fa-angle-left" aria-hidden="true"></i> Home
              </a>
            </div>
          </div>
        </header>
        <section class="appBody wrapper">
          <div class="login-block form login">
            <div class="login-row">
              <div class="main-heading text-center">
                <h2>Forgot Password</h2>
              </div>
              <form onSubmit={this.mySubmit}>
                <p id="error"></p>
                <div class="login-form common-form">
                  <div class="field input form-group">
                    <label>New Password </label>
                    <div class="src-cat-grp">
                      <input
                        class="form-control"
                        type="password"
                        name="newPass"
                        onChange={this.changedata}
                        value={this.state.newPass}
                        placeholder="Enter New Password"
                      />
                    </div>
                  </div>

                  <div class="field input form-group">
                    <label>Confirm Password </label>
                    <div class="src-cat-grp">
                      <input
                        class="form-control"
                        type="password"
                        name="confPass"
                        onChange={this.changedata}
                        value={this.state.confPass}
                        placeholder="Enter Confirm Password"
                      />
                    </div>
                  </div>
                  <div class="field form-btn button mt-40">
                    <input
                      class="btn btn-common btn-blue btn-round btn-big"
                      type="submit"
                      name="submit"
                      value="Forgot"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Resetpassword);
