import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { GlobalStoreProvider } from "./ContextAPI/MyContext";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStoreProvider>
      <ToastContainer />
        <Routes />
      </GlobalStoreProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
