import React from "react";
import { Link, withRouter } from "react-router-dom";
import Menu from "./menu";
import Footer from "./footer";
import Header from "./header";
import jwt, { decode } from "jsonwebtoken";
import config from "./admin/config.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import debounce from "lodash.debounce";
import Select from "react-select";
import { GlobalStoreContext } from "../ContextAPI/MyContext";

const axios = require("axios");
class CreateTicket extends React.Component {
  static contextType = GlobalStoreContext;
  state = {
    user_name: "",
    user_flag:"",
    selectedFile: [],
    studentList: [],
    //asset_id:this.props.data,
    asset_id: "",
    asset_id_error: "",
    AssetDetail: [],
    selectedStudent: null,
    formdata: {
      student_id: "",
      service_id: "",
      priority: "",
      service: "",
      caller: "",
      short_desc: "",
      work_note: "",
    },
    services: [],
  };

  componentDidMount = (e) => {
    try {
      const { myData, updateData } = this.context;

      //console.log(this.props.priyanka());
      const stdId = localStorage.getItem("stdId");
      console.log(stdId, "stdId");
      this.setState({
        formdata: { ...this.state.formdata, student_id: stdId },
      });
      // jwt.verify(localStorage.getItem("mainID"), "pokemon", (err, decoded) => {
      //   if (err) {
      //     localStorage.clear();
      //   } else {
      //     this.setState({
      //       formdata: { ...this.state.formdata, student_id: decoded.mainID },
      //     });
      //   }
      // });
      jwt.verify(localStorage.getItem("user_flag"), "pokemon", (err, decoded) => {
        if (err) {
          localStorage.clear();
        } else {
          this.setState({ user_flag: decoded.user_flag });
        }
      });
      jwt.verify(
        localStorage.getItem("user_name"),
        "pokemon",
        (err, decoded) => {
          if (err) {
            localStorage.clear();
          } else {
            this.setState({ user_name: decoded.user_name });

            this.getallServices();
          }
        }
      );

      const queryParams = new URLSearchParams(window.location.search);

      // Update your component's state with assetList from the context

      if (myData) {
        this.setState({
          asset_id: myData.value,
        });

        setTimeout(() => {
          let options = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              asset_id: myData.value,
              student_id: this.state.formdata.student_id,
            }),
          };
          fetch(`${config.googleAuth.backURL}user/verifyQrcode`, options)
            .then((res) => {
              return res.json();
            })

            .then((data) => {
              if (data.status == true) {
                this.setState({ asset_id_error: "" });
              } else {
                this.setState({ asset_id_error: data.message });
              }
            })

            .catch((err) => {
              console.log("error", err);
            });
        }, 300);
      }
      this.getAssetDetail(stdId);
      this.getStudentList(stdId);
    } catch (e) {
      console.log("error", e?.message);
    }
  };

  getStudentList = (value) => {
    try {
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ stdId: value }),
      };
      fetch(`${config.googleAuth.backURL}user/ur_studentList`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data.data);
          if (data.status == true) {
            this.setState({
              studentList: data.students.map((v) => ({
                label: v.fullname,
                value: v.student_id_fk,
              })),
            });
          }
        });
    } catch (e) {
      console.log("error:", e.message);
    }
  };
  getAssetDetail = (stdId) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ student_id: stdId }),
    };
    fetch(`${config.googleAuth.backURL}user/getAssetDetailTicket`, options)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        console.log(data.data);
        if (data.status == true) {
          this.setState({
            AssetDetail: data.data.map((v) => ({
              label: `${v.itemName} : ${v.asset_id}`,
              value: v.asset_id,
            })),
          });
        } else {
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  getallServices = () => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stdId: "" }),
    };
    fetch(`${config.googleAuth.backURL}user/getallServices`, options)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        if (data.status == true) {
          this.setState({ services: data.data });
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();

    let error = 0;
    let arry = "";
    if (["", null].includes(this.context.myData.value)) {
      toast("Asset can not be empty");
      error++;
    }
    if (this.state.asset_id_error !== "") {
      toast("Valid asset id required");
      error++;
    }
    if (this.state.formdata.priority === "") {
      toast("Priority can not be empty");
      error++;
    }

    if (this.state.formdata.service_id === "") {
      toast("Service can not be empty");
      error++;
    }

    if (this.state.formdata.short_desc === "") {
      toast("Short Description can not be empty");
      error++;
    }

    // if (this.state.formdata.work_note === "") {
    //   toast("Work Note can not be empty");
    //   error++;
    // }

    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");

      let newObj = new FormData();
      for (const key of Object.keys(this.state.selectedFile)) {
        newObj.append("file", this.state.selectedFile[key]);
      }

      newObj.append("student_id", this.state.formdata.student_id);
      newObj.append("asset_id", this.context.myData.value);
      newObj.append("service_id", this.state.formdata.service_id);
      newObj.append("priority", this.state.formdata.priority);
      newObj.append("caller", this.state.formdata.caller);
      newObj.append("short_desc", this.state.formdata.short_desc);
      newObj.append("work_note", "Ticket created");
      newObj.append("open_date", Date.now());

      axios
        .post(`${config.googleAuth.backURL}user/createTicket`, newObj)
        .then((res) => {
          console.log("response", res.data);
          if (res.data.status == true) {
            toast("Ticket Generated Successfully!!");
            this.context.updateData(null);
            setTimeout(() => this.props.history.push("/my-ticket"), 3000);
          } else {
            toast("Ops! Something went wrong please try again later.");
          }
          //return res.json();
        })

        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  changedata = (e) => {
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });

    if (e.target.name === "asset_id") {
      const { updateData } = this.context;
      updateData(e.target.value);
      setTimeout(() => {
        let options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            asset_id: e.target.value,
            student_id: this.state.formdata.student_id,
          }),
        };
        fetch(`${config.googleAuth.backURL}user/verifyQrcode`, options)
          .then((res) => {
            return res.json();
          })

          .then((data) => {
            if (data.status == true) {
              this.setState({ asset_id_error: "" });
            } else {
              this.setState({ asset_id_error: data.message });
            }
          })

          .catch((err) => {
            console.log("error", err);
          });
      }, 300);
    }
  };

  onImgChange = (e) => {
    // Update the state
    this.setState({
      selectedFile: [...this.state.selectedFile, ...e.target.files],
    });
  };

  removeImage = (e) => {
    let id = e.target.getAttribute("data-id");
    console.log("key--->", id);

    var newArry = [];
    this.state.selectedFile.map((preimg, index) => {
      if (index != id) {
        newArry.push(preimg);
      }
    });

    this.setState({ selectedFile: newArry });
  };

  handleChange = (newValue) => {
    this.context.updateData(newValue);
    this.setState({ asset_id_error: "" });
  };

  handleStudentChange = (newValue) => {
    const { updateData } = this.context;
    updateData(null);
    this.setState({
      selectedStudent:newValue,

      user_name: newValue.label,
      formdata: { ...this.state.formdata, student_id: newValue.value , caller: newValue.label },
    }); 

    this.getAssetDetail(newValue.value)
  };
  render() {
    console.log("this.state", this.state);
    return (
      <div class="appHdr">
        <ToastContainer />
        <header>
          <Header />
        </header>
        <section class="appBody wrapper">
          <div class="login-block form login">
            <div class="login-row">
              <div class="main-heading text-center">
                <h2>Create New Ticket</h2>
              </div>
              <form onSubmit={this.mySubmit} enctype="multipart/form-data">
                <p id="error"></p>
                <div class="login-form common-form">
               {this.state.user_flag==="UR" &&  <div
                    class="field input form-group"
                    style={{ marginBottom: "0px" }}
                  >
                    <label>Select Student </label>

                    <Select
                      onChange={this.handleStudentChange}
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      name="asset_id"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          position: "relative",
                          color: "red",
                          top: "-14px",
                          minHeight: "50px", // You can adjust the height here
                        }),
                      }}
                      value={this.state.selectedStudent}
                      // options={this.state.AssetDetail}
                      options={
                        this.state.studentList.length > 0
                          ? this.state.studentList
                          : [{ label: "No student found", value: null }]
                      }
                    />

                    {/* <Link class=" notop-qr-icn " to="/Qrsubmit?id=CreateTicket">
                      <i class="fa fa-qrcode" aria-hidden="true"></i>
                    </Link> */}
                  </div>}
                
                  <div
                    class="field input form-group"
                    style={{ marginBottom: "0px" }}
                  >
                    <label>Asset ID </label>
                    {/* <input
                      class="form-control"
                      type="text"
                      value={this.context.myData}
                      name="asset_id"
                      placeholder="123AACCDDl"
                      required
                      onChange={this.changedata}
                    /> */}

                    <Select
                      onChange={this.handleChange}
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      name="asset_id"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          position: "relative",
                          color: "red",
                          top: "-14px",
                          minHeight: "50px", // You can adjust the height here
                        }),
                      }}
                      value={this.context.myData}
                      // options={this.state.AssetDetail}
                      options={
                        this.state.AssetDetail.length > 0
                          ? this.state.AssetDetail
                          : [{ label: "No Asset found", value: null }]
                      }
                    />

                    {/* <Link class=" notop-qr-icn " to="/Qrsubmit?id=CreateTicket">
                      <i class="fa fa-qrcode" aria-hidden="true"></i>
                    </Link> */}
                  </div>
                  {this.state.asset_id_error !== "" && (
                    <p style={{ color: "red" }}>{this.state.asset_id_error}</p>
                  )}
                  <div class="field input form-group">
                    <label>Priority </label>
                    <div class="src-cat-grp">
                      <select
                        class="form-control"
                        name="priority"
                        id="priority"
                        required
                        onChange={this.changedata}
                      >
                        <option value="">Choose</option>
                        <option value="Low">Low</option>
                        <option value="Normal">Normal</option>
                        <option value="High">High</option>
                        <option value="Critical">Critical</option>
                      </select>
                      <div class="src-bars">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <div class="field input form-group">
                    <label>Service </label>
                    <div class="src-cat-grp">
                      <select
                        class="form-control"
                        name="service_id"
                        required
                        onChange={this.changedata}
                      >
                        <option value="">Choose</option>
                        {this.state?.services?.map((service, index) => {
                          return (
                            <option value={service.id}>{service.name} </option>
                          );
                        })}
                      </select>
                      <div class="src-bars">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>

                  <div class="field input form-group">
                    <label>Caller</label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.state.user_name}
                      name="caller"
                      readOnly="readonly"
                      required
                    />
                  </div>

                  <div class="field input form-group">
                    <label>Short Description</label>
                    <input
                      class="form-control"
                      type="text"
                      name="short_desc"
                      onChange={this.changedata}
                      placeholder="Enter Description"
                      required=""
                    />
                  </div>
                  {/* <div class="field input form-group">
                    <label>Work notes</label>
                    <textarea
                      class="form-control"
                      type="text"
                      placeholder="Notes..."
                      onChange={this.changedata}
                      id="work_note"
                      name="work_note"
                    ></textarea>
                  </div> */}
                  <div class="upload-section">
                    <label class="upload-image" for="upload-image-one">
                      <input
                        type="file"
                        name="file"
                        id="upload-image-one"
                        defaultValue=""
                        multiple
                        accept="image/*"
                        onChange={this.onImgChange}
                      />
                    </label>
                    <span class="add-more">
                      <i class="fa fa-camera" aria-hidden="true"></i>
                      Upload Image
                    </span>
                  </div>
                  <div class="attach-files">
                    <ul>
                      {this.state.selectedFile.length > 0
                        ? this.state.selectedFile.map((preimg, index) => {
                            return (
                              <li>
                                <img src={URL.createObjectURL(preimg)} />
                                <a
                                  class="fa fa-close file-remove"
                                  href="#!"
                                  data-id={index}
                                  onClick={this.removeImage}
                                ></a>
                              </li>
                            );
                          })
                        : ""}
                    </ul>
                  </div>
                  <div class="field form-btn button mt-40">
                    <input
                      class="btn btn-common btn-blue btn-round btn-big"
                      type="submit"
                      name="submit"
                      value="Submit"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </section>
      </div>
    );
  }
}
export default withRouter(CreateTicket);
