import React from "react";
import { Link, withRouter } from "react-router-dom";
import Menu from "./menu";
import Header from "./header";
import Footer from "./footer";
import jwt from 'jsonwebtoken'
import config from "./admin/config.js"


class ServicesList extends React.Component {
    state={
        user_name:'',
        serviceList:[]
    }
    
    componentDidMount = (e) => {
        jwt.verify(localStorage.getItem("user_name"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({user_name:decoded.user_name});
                this.getserviceList();                                
            }
        }) 
               
    }

    getserviceList = () => {
        
        let options={
            method:"POST",
            headers:{
                Accept:"application/json",
                "Content-Type":"application/json"
            }     
        } 
        fetch(`${config.googleAuth.backURL}user/getserviceList`,options) 
        .then(res=>{
            return res.json();
        })

            .then(data=>{                
                if(data.status == true){
                    this.setState({serviceList:data.data});
                    console.log('888888',this.state.serviceList);
                }else{
                    
                }
            })

          .catch(err=>{
            console.log("error",err)
        });        
    }

    render() {
        const fallbackSrc = config.googleAuth.backend+"no-img.png";
    return (
    <div class="appHdr">
        <Header/>
        <section class="appBody wrapper">
            <div class="login-block form login">
                <div class="services-blk">
                    <div class="main-heading text-center mb-50">
                        <h2>Our Services</h2>	
                    </div>    
                    <div class="servc-outr-blk">    
                        <div class="row">                          

                            {this.state.serviceList.map((servc,index)=>{
                                const imageSrc = config.googleAuth.backend+servc.image; 
                                return (

                                    <div class="col-xs-6">
                                        {/* <Link class="servc-lnk" to="service-price"> */}
                                            <div class="servc-card">
                                                <div class="card">  
                                                    <div class="servc-img">
                                                        <img src={imageSrc} onError={(e)=>{e.target.onError = null; e.target.src = fallbackSrc}} height='150px'/>
                                                        
                                                    </div>  
                                                    <div class="srvc-nme-hd">
                                                        <h4>{servc.name}</h4>    
                                                    </div>     
                                                </div>     
                                            </div>
                                        {/* </Link> */}
                                    </div>

                                ); 
                           })}

                        </div>   
                    </div>
                </div>   
            </div>
            <Footer />
        </section>
    </div>
  );
}
}
export default ServicesList;