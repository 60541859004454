import React from 'react'
import Adminheader from './common/adminheader'
import Adminfooter from './common/adminfooter'
import Adminsidebar from './common/adminsidebar'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "./config.js"
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import jwt from 'jsonwebtoken'
const axios = require("axios");
class Adminpassword extends React.Component {

  state = {
    data: [],
    loginflag: false,
    user_id: "",
    OLD_PASSWORD: "",
    NEW_PASSWORD: "",
    CONFIRM_PASSWORD: "",
    ADMIN_PASSWORD: '',
  }
  initialstate = {
    loginflag: true,
    user_id: "",
  }

  componentDidMount() {
    const decoded_id = jwt.verify(
      localStorage.getItem("Uidadmin"),
      config.login_secretadmin.key
    );
    this.setState({ user_id: decoded_id.Uidadmin })
    //alert(decoded_id.Uidadmin);
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: '0' }),
    }
    fetch(`${config.googleAuth.backURL}admin/getgenralsetting`, options)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.data[0])
        this.setState(
          {
            ADMIN_PASSWORD: responseJson.data[0].ADMIN_PASSWORD,
          
           
          }
        )
        
      })
      .catch((error) => {
        console.error(error);
      });
  }
  changedata = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    //console.log(this.state.username)
  }
  admineditpassword = e => {
    e.preventDefault();
    //alert(this.state.ADMIN_PASSWORD)
    if (this.state.ADMIN_PASSWORD == this.state.OLD_PASSWORD){
      if (this.state.NEW_PASSWORD == this.state.CONFIRM_PASSWORD) {
        let optionsuploads2 = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ NEW_PASSWORD: this.state.NEW_PASSWORD}),
        }
        fetch(`${config.googleAuth.backURL}admin/adminpassword_request`, optionsuploads2)
          .then((response) => response.json())
          .then((responseJson) => {
            
            toast('password updated successfully');
    
          })
          .catch((error) => {
            console.error(error);
          });
        }else{
        toast('New password or confirm password not matched!');
        }
    }else{
      toast('Old password not matched!');
    }
  }
  
 
  render() {
    var i = 1;
    return (
      <div>

        <Adminheader />
        <Adminsidebar />
        <ToastContainer />
        <div class="wrapper">
          <div class="main">

            <div class="main-content">
              <div class="container-fluid">
                <h3 class="page-title">Change password</h3>
                <div class="row">
                  <div class="col-md-12">

                    <div class="panel">
                      <div class="panel-heading"><h3 class="panel-title">Change password</h3></div>
                      <div class="panel-body">
                        <form onSubmit={this.admineditpassword}>
                          <input type="password" name="OLD_PASSWORD" value={this.state.OLD_PASSWORD} onChange={this.changedata} class="form-control" placeholder="Enter old password" required="true" />
                          <br />

                          <input type="password" name="NEW_PASSWORD" class="form-control" value={this.state.NEW_PASSWORD} onChange={this.changedata} placeholder="Enter new password" required="true" />
                          <br />
                          <input type="password" name="CONFIRM_PASSWORD" class="form-control" placeholder="Enter confirm password" value={this.state.CONFIRM_PASSWORD} onChange={this.changedata} required="true" />
                          <br />
                          
                          <button type="submit" class="btn btn-success">Submit</button>
                        </form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Adminfooter />
      </div>

    )
  }
}

export default Adminpassword