import React from "react";
import { Link, withRouter } from "react-router-dom";
import jwt from 'jsonwebtoken'
import config from "./admin/config.js"
    class MenuLinks extends React.Component {
      
        constructor(props) {
          super(props);
          // Any number of links can be added here
          this.state = {  
            user_name:'',
            user_img:'',
            user_unicode:'',
            user_flag:''
          }

          

        }


     
    componentDidMount(){
        console.log('componentDidMount');

        let session = jwt.verify(localStorage.getItem("user_name"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                return decoded                            
            }
        })  
        this.setState({user_name:session.user_name});           
          let session1 = jwt.verify(localStorage.getItem("user_unicode"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                return decoded                            
            }
        })     
        
        this.setState({user_flag:session.user_flag});           
          let session2 = jwt.verify(localStorage.getItem("user_flag"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                return decoded                            
            }
        })     
      
      this.setState({user_flag:session2.user_flag});  
      
      
      let session3 = jwt.verify(localStorage.getItem("user_img"), 'pokemon', (err, decoded)=>{
        if(err){
            localStorage.clear()
        }else{
            return decoded                            
        }
    })     
  
    this.setState({user_img:session3.user_img});  
       
    }

    logout=(e)=>{
      localStorage.clear()
      
    }

        render() {
          const fallbackSrc = "/images/user1.png";
        const imageSrc = config.googleAuth.backend+'profile/'+this.state.user_img;
          return (
              <div className={this.props.menuStatus} id='menu'>
                <div class="menuSlideDiv">
                  <div class="menuSlideDivCol">
                    <div class="side-bar-prof">
                       <Link to="profile" class="side-pro-icon">
                            <span class="side-pr-img">
                                
                            <img src={imageSrc} onError={(e)=>{e.target.onError = null; e.target.src = fallbackSrc}} />
                           
                            </span>
                            <h4>{this.state.user_name}</h4>
                            <p>{this.state.user_unicode}</p>
                        </Link>
                    </div>
                    <ul>
                       

                      <li><i aria-hidden="true" class="fa undefined"></i><a href="profile">My profile</a></li>
                      { this.state.user_flag=='student' ? (<>
                      <li><i aria-hidden="true" class="fa undefined"></i><a href="add-asset">Add Asset</a></li>
                      <li><i aria-hidden="true" class="fa undefined"></i><a href="hostel-detail">Hostel Detail</a></li>
                      </>) : ( <>

                      </>)}
                      <li><i aria-hidden="true" class="fa undefined"></i><a href="services-list">Service List</a></li>
                      <li><i aria-hidden="true" class="fa undefined"></i><a href="privacy-policy">Privacy Policy</a></li>
                      <li><i aria-hidden="true" class="fa undefined"></i><a href="terms-conditions">Terms and conditions</a></li>                      
                      <li><i aria-hidden="true" class="fa undefined"></i><a href="change-password">Change Password</a></li>
                      

                    </ul>
                    <div class="sign-out">
                       <a href="#!" class="side-sign-out" onClick={this.logout}>
                          <p>
                             <i class="fa fa-power-off" aria-hidden="true"></i>
                             Sign Out
                          </p>  
                        </a>
                    </div>
                  </div>
                </div>
              </div>
          )
        }
      }
      
      class Menu extends React.Component {
        constructor(props) {
          super(props);
          this.state = {
            isOpen: false
          }
          this._menuToggle = this._menuToggle.bind(this);
          this._handleDocumentClick = this._handleDocumentClick.bind(this);
        }
        componentDidMount() {
          document.addEventListener('click', this._handleDocumentClick, false);
        }
        componentWillUnmount() {
          document.removeEventListener('click', this._handleDocumentClick, false);
        }
        _handleDocumentClick(e) {
          if (!this.refs.root.contains(e.target) && this.state.isOpen === true) {
            this.setState({
            isOpen: false
          });
          };
        }
        _menuToggle(e) {
          e.stopPropagation();
          this.setState({
            isOpen: !this.state.isOpen
          });
        }
        render() {
          let menuStatus = this.state.isOpen ? 'isopen' : '';
      
          return (
            <div ref="root">
              <div className="menubar">
                <div className="hambclicker" onClick={ this._menuToggle }>

                <div id="hambmenu" className={ menuStatus }><span></span><span></span><span></span><span></span></div>
                </div>
                
                <div className="title">
                  <span>{ this.props.title }</span>
                </div>
              </div>
              <div class="menuSlideDivOuter">
                <MenuLinks menuStatus={ menuStatus }/>
              </div>
            </div>
          )
        }
      }
      export default withRouter(Menu)