import React from "react";
import Adminheader from "./common/adminheader";
import Adminfooter from "./common/adminfooter";
import Adminsidebar from "./common/adminsidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./config.js";
import { Switch, Route, Redirect, Link } from "react-router-dom";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Modal from "react-bootstrap-modal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//import QRCode from "react-qr-code";
import QRCode from "react-qr-code";
import Pagination1 from "./Pagination";
import * as htmlToImage from 'html-to-image';
import html2canvas from "html2canvas";
const axios = require("axios");

class Adminqritems extends React.Component {
  state = {
    data: [],
    qrcodeData: [],
    loginflag: false,
    services: [],
    blockroomId: "",
    formdata: {
      id: "",
      name: "",
      service_id: "",
    },
    errors: [],
    openModal: false,
    currentPage: 1,
    totalPage: 1,
    goToVal: 1,
    image: null,
  };

  initialState = {
    id: "",
    name: "",
    service_id: "",
    price: "",
  };

  closeModal = () => this.setState({ open: false });
  closeOpenModal = () => this.setState({ openModal: false });
  setNewCurrentPage = (pageVal = 1) => {
    this.setState({ currentPage: pageVal });
  };
  openModal = async (id = 0) => {
    await this.getservices();
    this.setState({ open: true });
    if (id > 0) {
      await this.getitem(id);
    } else {
      this.setState({ formdata: this.initialState });
    }
    setTimeout(() => {
      // $(".modal").css({ display: "block" });
      $(".modal").css({ display: "block" });
    }, 500);
  };
  openModal2 = async (blockId, roomId) => {
    this.setState({ openModal: true });
    this.setState({ blockroomId: `${blockId}-${roomId}` });
    setTimeout(() => {
      $(".modal").css({ display: "block" });
    }, 200);
  };
  getservices = () => {
    fetch(`${config.googleAuth.backURL}admin/services`)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ services: responseJson.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  changedata = (e) => {
    //this.setState({[e.target.name]:e.target.value.toLowerCase()})
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
  };
  componentDidMount() {
    this.getqritems();
    // $(document).ready(function () {
    //   setTimeout(function () {
    //     $("#example1").DataTable();
    //   }, 1000);
    // });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.blockroomId !== this.state.blockroomId) {
      this.getqrcodes();
    }
    if (prevState.currentPage !== this.state.currentPage) {
      this.getqritems(this.state.currentPage);
    }
  }

  getqritems = (page = 1, limit = 10) => {
    fetch(
      `${config.googleAuth.backURL}admin/qritems3?page=${page}&limit=${limit}&paging=1`
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          data: responseJson?.data,
          totalPage: responseJson?.totalPage,
          currentPage: responseJson?.currentPage,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getqrcodes = () => {
    fetch(
      `${config.googleAuth.backURL}admin/qritems3?roomId=${
        this.state.blockroomId.split("-")[0]
      }&blockId=${this.state.blockroomId.split("-")[1]}&getItem=1`
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          qrcodeData: responseJson?.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.formdata.service_id === "") {
      arry += "Please select any one Service* <br/>";
      error++;
    }
    if (this.state.formdata.name === "") {
      arry += "Item name can not be empty* <br/>";
      error++;
    }
    console.log(error);
    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.formdata),
      };
      fetch(`${config.googleAuth.backURL}admin/additem`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            this.getitems();
            data.code == 500
              ? toast("Item updated successfully!!")
              : toast("Item added successfully!!");
            this.setState({ formdata: this.initialState });
          } else {
            toast("Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  delhost = (id) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    };
    fetch(`${config.googleAuth.backURL}admin/delitem`, options)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status == true) {
          this.getitems();
          toast("Item deleted successfully!!");
          this.setState(this);
        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  delUni = (id) => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => (
        <div className="custom-ui">
          <h3>Are you sure?</h3>
          <p>You want to delete this User?</p>
          <button onClick={onClose}>No</button>
          <button
            onClick={() => {
              this.delhost(id);
              onClose();
            }}
          >
            Yes, Delete it!
          </button>
        </div>
      ),
    });
  };

  // downloadQR = (id) => {
  //   const qrCodeURL = document
  //     .getElementById(id)
  //     .toDataURL("image/png")
  //     .replace("image/png", "image/octet-stream");
  //   let aEl = document.createElement("a");
  //   aEl.href = qrCodeURL;
  //   aEl.download = id + ".png";
  //   document.body.appendChild(aEl);
  //   aEl.click();
  //   document.body.removeChild(aEl);
  // };

  downloadQR = async (id) => {
    const qrCodeURL = document.getElementById(id);
    //   const node = document.getElementById(this.props.id);

    htmlToImage
      .toPng(qrCodeURL,{ pixelRatio: 2 })
      .then((dataUrl) => {
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = `${id}.png`;
        a.click();
      })
      .catch((error) => {
        console.error("Error capturing screenshot:", error);
      });
  };

  render() {
    var i = 1;
    return (
      <div>
        <Adminheader />
        <Adminsidebar />
        <ToastContainer />
        <div class="wrapper">
          <div class="main">
            <div class="main-content">
              <div class="container-fluid">
                <h3 class="page-title">Items List</h3>
                <div class="row">
                  <div class="col-md-12">
                    <div class="panel">
                      <div class="panel-body">
                        <table class="table" id="example1">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>University Name</th>
                              <th>Hostel Name</th>
                              <th>Block</th>
                              <th>Room</th>
                              <th>Show All</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data
                              ? this.state.data.map((l) => {
                                  return (
                                    <tr>
                                      <td>
                                        {(this.state.currentPage - 1) * 10 +
                                          i++}
                                      </td>
                                      <td>{l.universityName}</td>
                                      <td>{l.HostelName}</td>
                                      <td>{l.Block}</td>
                                      <td>{l.Room}</td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            this.openModal2(l.roomId, l.blockId)
                                          }
                                        >
                                          Show QR Codes
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                        <Pagination1
                          totalPage={this.state.totalPage}
                          currentPage={this.state.currentPage}
                          prevPage={
                            this.state.currentPage - 1 == 0
                              ? 1
                              : this.state.currentPage - 1
                          }
                          nextPage={
                            this.state.currentPage == this.state.totalPage
                              ? this.state.totalPage
                              : this.state.currentPage + 1
                          }
                          setNewCurrentPage={this.setNewCurrentPage}
                        />
                        <p>
                          Go To{" "}
                          <input
                            value={this.state.goToVal}
                            type="number"
                            onChange={(e) => {
                              e.target.value > 0 &&
                                e.target.value <= this.state.totalPage &&
                                this.setState({
                                  goToVal: parseInt(e.target.value),
                                });
                            }}
                          />
                          <button
                            onClick={(e) => {
                              this.setNewCurrentPage(this.state.goToVal);
                            }}
                          >
                            Page
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Adminfooter />
        <Modal
          show={this.state.openModal}
          onHide={this.closeOpenModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id="shareModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">Detail View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <table class="table" id="example1"> */}
            <input
              type="button"
              className="download-btn"
              value="Download All QR"
              onClick={() => {
                this.state.qrcodeData.forEach((element) => {
                  this.downloadQR(`qrCode${element.qrcodenew}`);
                });
              }}
            />
            {this.state.qrcodeData?.map((element, i) => {
              return (
                <div key={element.qrcodenew}>
                  {element.name} - {element.qrcodenew}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      id={"qrCode" + element.qrcodenew}
                      style={{
                        width: "fit-content",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        background:"white"
                      }}
                    >
                      <QRCode
                        // id={`${element.qrcodenew}`}
                        value={JSON.stringify(element)}
                        size={120}
                        level={"L"}
                        includeMargin={true}
                      />
                    </div>
                    <input
                      type="button"
                      className="download-btn"
                      value="Download"
                      onClick={() => {
                        this.downloadQR(`qrCode${element.qrcodenew}`);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Adminqritems;
