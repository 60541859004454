import React from "react";
import Adminheader from "./common/adminheader";
import Adminfooter from "./common/adminfooter";
import Adminsidebar from "./common/adminsidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./config.js";
import { Switch, Route, Redirect, Link } from "react-router-dom";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Modal from "react-bootstrap-modal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DownloadIcon from "./downloadIcon";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import * as htmlToImage from 'html-to-image';
class Adminrooms extends React.Component {
  state = {
    data: [],
    items: [],
    roomitems: [],
    lengthOfEachItem: [],
    iems: [],
    loginflag: false,
    hostel: "",
    block: "",
    floor: "",
    codeDiv: false,
    editStdId: 0,
    editStdValue: "",
    openi: false,
    formdata: {
      id: "",
      name: "",
      hostel_id: this.props.match.params.id,
      block_id: this.props.match.params.bid,
      floor_id: this.props.match.params.fid,
    },
    itemdata: {
      id: "",
      room_id: "",
      item_id: "",
    },
    errors: [],
  };
  initialState = {
    id: "",
    name: "",
    hostel_id: this.props.match.params.id,
    block_id: this.props.match.params.bid,
    floor_id: this.props.match.params.fid,
  };
  inititem = {
    id: "",
    room_id: "",
    item_id: "",
  };
  closeModal = () => this.setState({ open: false, openi: false });
  // downloadQR = async (id) => {
  //   const qrCodeURL = document.getElementById(id);

  //   const canvas = await html2canvas(qrCodeURL);

  //   // Find the non-empty bounding box of the content
  //   const scaledWidth = canvas.width * 3;
  //   const scaledHeight = canvas.height * 3;

  //   const scaledCanvas = document.createElement("canvas");
  //   scaledCanvas.width = scaledWidth;
  //   scaledCanvas.height = scaledHeight;
  //   const ctx2 = scaledCanvas.getContext("2d");
  //   ctx2.imageSmoothingEnabled = false;
  //   // Scale and draw the original content onto the scaled canvas
  //   ctx2.drawImage(canvas, 0, 0, scaledWidth, scaledHeight);

  //   // Convert the scaled canvas to a PNG
  //   const imgData = scaledCanvas.toDataURL("image/png");

  //   // Create a download link and trigger the download
  //   const a = document.createElement("a");
  //   a.href = imgData;
  //   a.download = `${id}.png`;
  //   a.click();
  // };

  downloadQR = async (id) => {
    const qrCodeURL = document.getElementById(id);
    //   const node = document.getElementById(this.props.id);

    htmlToImage
      .toPng(qrCodeURL,{ pixelRatio: 2 })
      .then((dataUrl) => {
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = `${id}.png`;
        a.click();
      })
      .catch((error) => {
        console.error("Error capturing screenshot:", error);
      });
  };
  openModal = async (id = 0) => {
    this.setState({ open: true });
    if (id > 0) {
      await this.getroom(id);
    } else {
      this.setState({ formdata: this.initialState });
    }
    setTimeout(() => {
      $(".modal").css({ display: "block" });
    }, 500);
  };
  openiModal = async (id = 0) => {
    console.log("kkkkkkk");
    this.setState({ openi: true });
    this.getitems();
    console.log("kkkkkkk33");
    this.getroomitems(id);
    console.log("kkkkkkk44");
    this.setState({ itemdata: this.inititem });
    this.setState({ itemdata: { ...this.state.itemdata, room_id: id } });
    setTimeout(() => {
      $(".modal").css({ display: "block" });
    }, 500);
  };
  changedata = (e) => {
    console.log(e, "oop");
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
    console.log(e.target.name, "oop");
  };
  changeidata = (e) => {
    console.log(e.target.name, "1221");
    this.setState({
      itemdata: { ...this.state.itemdata, [e.target.name]: e.target.value },
    });
    console.log(this.state.itemdata, "1221");
  };
  changeqrdata = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    console.log(this.state.openi, "1147");
    this.getrooms();
    this.gethostel();
    this.getBlock();
    this.getFloor();
    $(document).ready(function () {
      setTimeout(function () {
        $("#example1").DataTable();
      }, 1000);
    });
  }
  getroomitems = (id) => {
    fetch(`${config.googleAuth.backURL}admin/roomitems/` + id)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responseJson.data", responseJson.data);
        this.setState({
          roomitems: responseJson.data,
          items: responseJson.uniqueItems,
          lengthOfEachItem: responseJson.uniqueItems[0].items,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getitems = () => {
    fetch(`${config.googleAuth.backURL}admin/items`)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ items: responseJson.data });
        console.log(this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  gethostel = async () => {
    await fetch(
      `${config.googleAuth.backURL}admin/hostel/` + this.props.match.params.id
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ hostel: responseJson.data });
        //console.log(this.state)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getBlock = async () => {
    await fetch(
      `${config.googleAuth.backURL}admin/block/` + this.props.match.params.bid
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ block: responseJson.data });
        //console.log(this.state)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getFloor = async () => {
    await fetch(
      `${config.googleAuth.backURL}admin/block/` + this.props.match.params.fid
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ floor: responseJson.data });
        //console.log(this.state)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getroom = async (id) => {
    await fetch(`${config.googleAuth.backURL}admin/room/` + id)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ formdata: responseJson.data });
        console.log(this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getrooms = () => {
    fetch(
      `${config.googleAuth.backURL}admin/rooms/` +
        this.props.match.params.id +
        "/" +
        this.props.match.params.bid +
        "/" +
        this.props.match.params.fid
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ data: responseJson.data });
        console.log(this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  mySubmit = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.formdata.name === "") {
      //this.setState({ loginpassValid: 'Password is required*' })
      arry += "room name can not be empty* <br/>";
      error++;
    }
    console.log(error);
    //this.setState({ errors: arry })
    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.formdata),
      };
      fetch(`${config.googleAuth.backURL}admin/addroom`, options)
        .then((res) => {
          //console.log("response",res)
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            this.getrooms();
            data.code == 500
              ? toast("room updated successfully!!")
              : toast("room added successfully!!");
            this.setState({ formdata: this.initialState });
          } else {
            toast("Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  itemSubmit = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.itemdata.item_id === "") {
      arry += "Please select any item* <br/>";
      error++;
    }
    console.log(error);
    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.itemdata),
      };
      console.log(this.state.itemdata, "itemdataitemdata");
      fetch(`${config.googleAuth.backURL}admin/addroomitem`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            this.getroomitems(this.state.itemdata.room_id);
            data.code == 500
              ? toast("Item updated successfully!!")
              : toast("Item added successfully!!");
            //this.setState({itemdata: this.inititem})
          } else {
            toast("Item already exist!");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  delhost = (id) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    };
    fetch(`${config.googleAuth.backURL}admin/delroom`, options)
      .then((res) => {
        //console.log("response",res)
        return res.json();
      })
      .then((data) => {
        if (data.status == true) {
          this.getrooms();
          toast("room deleted successfully!!");
          this.setState(this);
        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  delitem = (id, roomid) => {
    if (window.confirm("Are you sure?")) {
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id }),
      };
      fetch(`${config.googleAuth.backURL}admin/delroomitem`, options)
        .then((res) => {
          //console.log("response",res)
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            this.getroomitems(roomid);
            toast("Item deleted successfully!!");
          } else {
            toast("Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  delUni = (id) => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => (
        <div className="custom-ui">
          <h3>Are you sure?</h3>
          <p>You want to delete this User?</p>
          <button onClick={onClose}>No</button>
          <button
            onClick={() => {
              this.delhost(id);
              onClose();
            }}
          >
            Yes, Delete it!
          </button>
        </div>
      ),
    });
  };
  codeDivStatus = (e) => {
    var editStdValue = e.target.getAttribute("data-value");
    this.setState({
      codeDiv: true,
      editStdId: e.target.id,
      editStdValue: editStdValue,
    });
  };
  codeDivStatus1 = (e) => {
    this.setState({ codeDiv: false, editStdId: 0, editStdValue: "" });
  };
  updateStdCode = (e) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.state.editStdId,
        value: this.state.editStdValue,
      }),
    };
    fetch(`${config.googleAuth.backURL}admin/updateqrcodeID`, options)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status == true) {
          $("#uniquecodeid" + this.state.editStdId).text(
            this.state.editStdValue
          );
          this.setState({ codeDiv: false, editStdId: 0, editStdValue: "" });
        } else {
          toast("Ops! Please try again later.");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  render() {
    var i = 1;
    var j = 1;
    return (
      <div>
        <Adminheader />
        <Adminsidebar />
        <ToastContainer />
        <div class="wrapper">
          <div class="main">
            <div class="main-content">
              <div class="container-fluid">
                <h3 class="page-title">Rooms List</h3>
                <h5>
                  {this.state.hostel.name} - {this.state.block.name} -{" "}
                  {this.state.floor.name}
                </h5>
                <button
                  onClick={this.openModal}
                  class="pull-right btn btn-success"
                >
                  Add Room
                </button>
                <div class="row">
                  <div class="col-md-12">
                    <div class="panel">
                      <div class="panel-body">
                        <table class="table" id="example1">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th> Room Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data
                              ? this.state.data.map((l) => {
                                  return (
                                    <tr>
                                      <td>{i++}</td>

                                      <td>{l.name}</td>

                                      <td>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.openiModal(l.id);
                                          }}
                                          class="btn btn-success"
                                        >
                                          <i class="fa fa-eye"></i> Items
                                        </a>{" "}
                                        &nbsp;&nbsp;
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.openModal(l.id);
                                          }}
                                          class="btn btn-success"
                                        >
                                          <i class="fa fa-pencil"></i>
                                        </a>
                                        &nbsp;&nbsp;
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.delUni(l.id);
                                          }}
                                          class="btn btn-success"
                                        >
                                          <i class="fa fa-trash"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Adminfooter />
        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id="shareModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">Add Room</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p id="error"></p>
            <form onSubmit={this.mySubmit}>
              <input
                class="form-control"
                placeholder="Room Name"
                onChange={this.changedata}
                value={this.state.formdata.name}
                name="name"
                type="text"
              />
              <br />
              <button type="submit" class="btn btn-success">
                Submit
              </button>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.openi}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg"
          id="shareModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">Add Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="row">
              {/* <div class="col-md-4">
                <p id="error"></p>
                <form onSubmit={this.itemSubmit}>
                  <select
                    class="form-control"
                    name="item_id"
                    value={this.state.itemdata.item_id}
                    onChange={this.changeidata}
                  >
                    <option value="">-- Select Item ---</option>
                    {this.state.items &&
                      this.state.items &&
                      this.state.items?.length > 0
                      ? this.state.items.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))
                      : ""}
                  </select>
                  <br />
                  <button type="submit" class="btn btn-success">
                    Submit
                  </button>
                </form>
              </div> */}
              <div class="col">
                {/* <table class="table" id="example1">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> Item Name</th>
                      <th> QRCode</th>
                      <th> QRCode Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log('shweta', this.state.roomitems)}


                    {this.state.roomitems && this.state.roomitems.length > 0
                      ? this.state.roomitems.map((l) => {
                        return (
                          <tr>
                            <td>{j++}</td>

                            <td>{l.name}</td>
                            <td>
                              <div>
                                <QRCode
                                  id={"qrCode" + l.id}
                                  value={l?.qrcodenew}
                                  size={180}
                                  level={"H"}
                                  includeMargin={true}
                                />
                                <a
                                  href="#!"
                                  onClick={() => {
                                    this.downloadQR("qrCode" + l.id);
                                  }}
                                >
                                  {" "}
                                  Download QR{" "}
                                </a>
                              </div>
                            </td>
                            <td>
                              <div style={{ 'display': (this.state.codeDiv && this.state.editStdId == l.id) ? 'block' : 'none' }}>
                                <input type='text' name='editStdValue' defaultValue={l?.qrcodenew} size='7' onChange={this.changeqrdata} /><i class="fa fa-check" style={{ 'padding-left': `2px`, 'color': 'green' }} onClick={this.updateStdCode}></i> <i class="fa fa-times" style={{ 'padding-left': `2px`, 'color': 'red' }} onClick={this.codeDivStatus1}></i>
                              </div>

                              <div style={{ 'display': (this.state.codeDiv && this.state.editStdId == l.id) ? 'none' : 'block' }}>
                                <span id={`uniquecodeid${l.id}`}> {l?.qrcodenew} </span> <i class="fa fa-edit" style={{ 'padding-left': `2px`, 'color': '#256BFE' }} onClick={this.codeDivStatus} id={l.id} data-value={l?.qrcodenew}></i>
                              </div>
                            </td>

                            <td>
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  this.delitem(l.id, l.room_id);
                                }}
                                class="btn btn-success"
                              >
                                <i class="fa fa-trash"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </table> */}
                <table class="table" id="example1">
                  <thead>
                    <tr>
                      <th>#</th>
                      {this.state.items.map((element) => {
                        return <th>{element.name}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.lengthOfEachItem.map((distinctItem, i1) => {
                      return (
                        <tr>
                          <td>{i1 + 1}</td>
                          {this.state.items.map((element) => {
                            console.log("element", element);
                            let qrCode = element?.items?.[i1]?.qrcodenew || "";
                            let qrCodeId = element?.items?.[i1]?.id || "";
                            let roomId = element?.items?.[i1]?.room_id || "";
                            return (
                              <>
                                <td>
                                  <div
                                    style={{
                                      display:
                                        this.state.codeDiv &&
                                        this.state.editStdId == qrCodeId
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      name="editStdValue"
                                      defaultValue={qrCode}
                                      size="7"
                                      onChange={this.changeqrdata}
                                    />
                                    <i
                                      class="fa fa-check"
                                      style={{
                                        "padding-left": `2px`,
                                        color: "green",
                                      }}
                                      onClick={this.updateStdCode}
                                    ></i>{" "}
                                    <i
                                      class="fa fa-times"
                                      style={{
                                        "padding-left": `2px`,
                                        color: "red",
                                      }}
                                      onClick={this.codeDivStatus1}
                                    ></i>
                                  </div>
                                  <div
                                    style={{
                                      display:
                                        this.state.codeDiv &&
                                        this.state.editStdId == qrCodeId
                                          ? "none"
                                          : "block",
                                    }}
                                  >
                                    <span id={`uniquecodeid${qrCodeId}`}>
                                      {" "}
                                      {qrCode}{" "}
                                    </span>{" "}
                                    <i
                                      class="fa fa-edit"
                                      style={{
                                        "padding-left": `2px`,
                                        color: "#256BFE",
                                      }}
                                      onClick={this.codeDivStatus}
                                      id={qrCodeId}
                                      data-value={qrCode}
                                    ></i>
                                  </div>
                                  <div
                                    id={"qrCode" + qrCode}
                                    style={{
                                      width: "fit-content",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      padding: "10px",
                                      background:"white"
                                    }}
                                  >
                                    <QRCode
                                      id={"qrCode" + qrCode}
                                      value={JSON.stringify({
                                        id: element?.items?.[i1]?.ItemId,
                                        name: element?.items?.[i1]?.name,
                                        room_id: element?.items?.[i1]?.room_id,
                                        qrcodenew:
                                          element?.items?.[i1]?.qrcodenew,
                                      })}
                                      size={80}
                                      level={"L"}
                                      includeMargin={false}
                                    />
                                  </div>
                                  <div
                                    onClick={() => {
                                      this.downloadQR("qrCode" + qrCode);
                                    }}
                                    class="btn btn-success"
                                  >
                                    <DownloadIcon />
                                  </div>
                                  <div>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        this.delitem(qrCode, roomId);
                                      }}
                                      class="btn btn-success"
                                    >
                                      <i class="fa fa-trash"></i>
                                    </a>
                                  </div>
                                </td>
                              </>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Adminrooms;
