import React from 'react'
import {Switch,Route,Redirect,Link} from 'react-router-dom';
class Adminfooter extends React.Component{

  

  render(){
    return(
      <div>
      
      	<footer>
			<div class="container-fluid">
				<p class="copyright">&copy; 2021 <a href="#" target="_blank">Duple IT Solutions</a>. All Rights Reserved.</p>
			</div>
		</footer>
	

  </div>
      )
  }
}

export default Adminfooter