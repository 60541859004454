import React from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import $ from 'jquery';

import config from "../config.js"
//import '../../main.css';

class Adminheader extends React.Component {
  state = {
    data: [],
    name: '',
    email: '',
    image: null,
    loginflag: false,
  }
  initialstate = {
    loginflag: true,
  }

  componentDidMount() {

    

    $('link[href*="/front/css/master.css"]').attr("disabled", "true");
    $('head').append($('<link rel="stylesheet" type="text/css" />').attr('href', '/css/main.css'));
    

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: '0' }),
    }
    fetch(`${config.googleAuth.backURL}admin/getgenralsetting`, options)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState(
          {
            //data : responseJson.data,
            email: responseJson.data[0].ADMIN_EMAIL,
            name: responseJson.data[0].ADMIN_NAME,
            image: responseJson.data[0].ADMIN_IMAGE,
          }
        )
        console.log(this.state)
        $('link[href="/css/main.css"]').remove();
        $('head').append($('<link rel="stylesheet" type="text/css" />').attr('href', '/css/main.css'));

      })
      .catch((error) => {
        console.error(error);
      });

      console.log("Hiiii",this.state)
  }
  togglewidth() {
    if (!$('body').hasClass('layout-fullwidth')) {
      $('body').addClass('layout-fullwidth');

    } else {
      $('body').removeClass('layout-fullwidth');
      $('body').removeClass('layout-default'); // also remove default behaviour if set
    }

    $(this).find('.lnr').toggleClass('fa-arrow-circle-o-left fa-arrow-circle-o-right');

    if ($(window).innerWidth() < 1025) {
      if (!$('body').hasClass('offcanvas-active')) {
        $('body').addClass('offcanvas-active');
      } else {
        $('body').removeClass('offcanvas-active');
      }
    }
  };
  logout = () => {
    localStorage.removeItem('Uidadmin');
    this.setState(this.initialstate)
  }
  render() {
    return (
      <div>

        {this.state.loginflag ? <Redirect to='/admin/' /> : ""}
        {/* <div class="page-loader-wrapper">
    <div class="loader">
       
        <p>Please wait...</p>        
    </div>
</div> */}



        <div class="wrapper">

          <nav class="navbar navbar-default navbar-fixed-top">
            
            <div class="brand">
              {/* <a href="index.html"><img src="../images/admin/logo.svg" alt="elev8 Logo" class="img-responsive logo" /></a> */}

              <h3 style={{'text-align': 'left','color': 'white'}}>Furniture Management</h3>
            </div>

            <div class="container-fluid">
              <div class="navbar-btn">
                <button type="button" onClick={() => this.togglewidth()} class="btn-toggle-fullwidth"><i class="fa fa-arrow-circle-o-left"></i></button>
              </div>


              <div id="navbar-menu">
                <ul class="nav navbar-nav navbar-right">


                  <li class="dropdown">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown"><img src={`${config.googleAuth.backend}${this.state.image}`} class="img-circle" alt="Avatar" /> <span>{this.state.name}</span> <i class="icon-submenu fa fa-angle-down"></i></a>
                    <ul class="dropdown-menu">                      
                      <li><Link to="/admin/chgpwd"><i class="fa fa-lock"></i> <span>Change Password</span></Link></li>
                      <li><Link to="/admin/adminprofileedit"><i class="fa fa-cog"></i> <span>Profile Edit</span></Link></li>
                      <li><a href="javascript:void(0)" onClick={() => this.logout()}><i class="fa fa-sign-out"></i> <span>Logout</span></a></li>
                    </ul>
                  </li>

                </ul>
              </div>
            </div>
          </nav>


        </div>
      </div>

    )
  }
}

export default Adminheader