import React from "react";
import Adminheader from "./common/adminheader";
import Adminfooter from "./common/adminfooter";
import Adminsidebar from "./common/adminsidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./config.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { confirmAlert } from "react-confirm-alert";
import { Button } from "reactstrap";

class Stddetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      data: [],
      Assets: [],
      errors: [],
    };
  }
  componentDidMount() {
    this.getstdDetails();
  }
  getstdDetails = async () => {
    var id = this.state.id;
    await fetch(`${config.googleAuth.backURL}admin/getstdDetails/` + id)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          this.setState({
            data: responseJson.data,
            Assets: responseJson.asset,
          });
          console.log(this.state);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  handleCheckOut=()=>{
    try {
        let options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: this.state.id,
          }),
        };
        fetch(`${config.googleAuth.backURL}admin/checkoutstudent`, options)
          .then((res) => {
            
            //console.log("response",res)
            return res.json();
          })
          .then((data) => {
            if (data.status == true) {
              this.getstdDetails()
              toast("updated successfully!!");
            } else {
              toast(data.message || "Something wrong!");
            }
          })
          .catch((err) => {
            console.log("error", err);
          });
      } catch (err) {
        console.log("error", err);
      }
  }
  render() {
    var i = 1;
    let assetsLength = 0;
    return (
      <div>
        <Adminheader />
        <Adminsidebar />
        <ToastContainer />
        <div class="wrapper">
          <div class="main">
            <div class="main-content">
              <div class="container-fluid">
                <h3
                  class="page-title "
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Student Details{" "}
                  <div>
                    <Button
                    disabled={this.state.data.checkOut}
                      type="button"
                      color="primary"
                      style={{ fontWeight: 500 }}
                      onClick={(value) =>
                        confirmAlert({
                          customUI: ({ title, message, onClose }) => (
                            <div className="custom-ui">
                              <h3>Are you sure?</h3>
                              <p>You want to check out this student?</p>
                              <button onClick={onClose}>No</button>
                              <button
                                onClick={(e) => {
                                  this.handleCheckOut();
                                  onClose();
                                }}
                              >
                                Yes!
                              </button>
                            </div>
                          ),
                          overlayClassName: "overlay-custom-class-name",
                        })
                      }
                    >
                      Check Out
                    </Button>
                  </div>
                </h3>
                <div class="row">
                  <div class="col-md-12">
                    <div class="panel">
                      <div class="panel-body">
                        <p id="error"></p>
                        <div class="row">
                          <div class="col-md-6">
                            <label>Name</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.fullname}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          <div class="col-md-6">
                            <label>Email</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.email}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          <div class="col-md-6">
                            <label>Mobile</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.mobile}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          <div class="col-md-6">
                            <label>Student Id</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.student_id}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          <div class="col-md-6">
                            <label>University</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.university}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          <div class="col-md-6">
                            <label>Hostel</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.hostel}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          <div class="col-md-6">
                            <label>Block</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.block}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          <div class="col-md-6">
                            <label>Floor</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.floor}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          <div class="col-md-6">
                            <label>Room</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.room}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          <div class="col-md-7">
                            <label>Assets </label>
                            {this.state.Assets.map((asset, index) => {
                              if (
                                asset.itemName != null ||
                                asset.asset_id != null
                              ) {
                                assetsLength += 1;
                                return (
                                  <>
                                    <input
                                      class="form-control"
                                      readOnly
                                      type="text"
                                      placeholder={`${asset.itemName}:  ${asset.asset_id}`}
                                    />
                                    <br />
                                  </>
                                );
                              }
                            })}
                            {assetsLength == 0 && (
                              <input
                                class="form-control"
                                readOnly
                                type="text"
                                placeholder={`No Assets To Show`}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Adminfooter />
      </div>
    );
  }
}

export default Stddetail;
