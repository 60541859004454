import React from 'react'
import Adminheader from './common/adminheader'
import Adminfooter from './common/adminfooter'
import Adminsidebar from './common/adminsidebar'
import { ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import config from "./config.js"
import Modal from 'react-bootstrap-modal'
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';


const axios = require("axios");
class Adminengineers extends React.Component{

      state={    
        codeDiv:false,
        editStdId:0,
        editStdValue:'',
        data:[],
        university:[],
        formdata:{  
            id:'',
            uni_id:'',
            hostel_id:''
        }        
      }
      
       
        componentDidMount(){            
            this.getallEngineers(); 
        }

          getallEngineers = () => {

              fetch(`${config.googleAuth.backURL}admin/getallEngineers`)
              .then((response) => response.json())
              .then((responseJson) => {
                if(responseJson.status){
                  this.setState({ data : responseJson.data, })

                  
                }
                
              })
              .catch((error) => {
                  console.error(error);
              });    
              $(document).ready(function () {
                  setTimeout(function () {
                    $('#example1').DataTable();
                  }, 1000);
              });  
          }
          changedata=(e)=>{      

            if(e.target.name==='uni_id'){
              
              this.getHostelbyUni(e.target.value);
            }
            this.setState({ formdata: { ...this.state.formdata, [e.target.name]:e.target.value} });
            
          }  


          closeModal = () => this.setState({ open: false })

          openModal = async (id=0,uni_id=0,hostel_id=0) => { 
             

                this.setState({ open: true })

                if(uni_id!==''&&uni_id!==0){
                  this.getHostelbyUni(uni_id);
                }  
                       
                this.setState({ formdata: { ...this.state.formdata, uni_id:uni_id,id:id,hostel_id:hostel_id } });            
                this.getuniversities();  
                
                setTimeout(() => { $('.modal').css({'display':'block'}); }, 500)
          }

          getHostelbyUni = (id) => {
            let options={
              method:"POST",
              headers:{
              Accept:"application/json",
                "Content-Type":"application/json"
              },
              body:JSON.stringify({id:id})
            }
            fetch(`${config.googleAuth.backURL}admin/getHostelbyUni`,options)
                .then((response) => response.json())
                .then((responseJson) => {
                        this.setState({ hostels : responseJson.data, })
                        console.log(this.state)
                })
                .catch((error) => {
                  console.error(error);
                });        
          }
          
          changeStatus = (e) => {
            
            let status=(e.target.checked)?0:1;
              
             
            let options={
              method:"POST",
              headers:{
              Accept:"application/json",
                "Content-Type":"application/json"
              },
              body:JSON.stringify({id:e.target.value,status:status})
            }
            fetch(`${config.googleAuth.backURL}admin/updateUserStatus`,options)
                .then((response) => response.json())
                .then((responseJson) => {
                    this.getallEngineers(); 
                })
                .catch((error) => {
                  console.error(error);
                });        
          }

          getuniversities = () => {
            fetch(`${config.googleAuth.backURL}admin/universities`)
                .then((response) => response.json())
                .then((responseJson) => {
                  this.setState({ university : responseJson.data, })
                  console.log(this.state)
                })
                .catch((error) => {
                  console.error(error);
                });        
          }

        mySubmit = e => {
            e.preventDefault();
            let error = 0;
            let arry = "";
            if (this.state.formdata.uni_id === "") {              
              arry += 'University name can not be empty* <br/>';
              error++;
            }

            if (error > 0) {
                $('#error').html(arry)
              } else {
                $('#error').html('')
                  let options={
                      method:"POST",
                      headers:{
                      Accept:"application/json",
                        "Content-Type":"application/json"
                      },
                      body:JSON.stringify(this.state.formdata)
                  }
                    fetch(`${config.googleAuth.backURL}admin/assignUniversity`,options)
                    .then(res=>{
                      //console.log("response",res)
                      return res.json();
                    })
                    .then(data=>{                
                      if(data.status == true){
                        toast("University Assigned successfully!!")
                      }
                      else{
                        toast("Ops! Please try again later");
                      }
                    })
                    .catch(err=>{
                      console.log("error",err)
                    })         
             }      
      
      }
      closeModal = () => this.setState({ open1: false })

      openModal1 = async (id=0,email='',mobile='',code='') => { 
        
          this.setState({ open1: true, eng_id:id, tmp_email:email, tmp_mobile:mobile, tmp_code:code})          
          setTimeout(() => { $('.modal').css({'display':'block'}); }, 500)
      }


      changedata=(e)=>{
          this.setState({ [e.target.name]: e.target.value })  
      }  


      mySubmit1 = e => {
          e.preventDefault();
            
              let options={
                  method:"POST",
                  headers:{
                  Accept:"application/json",
                    "Content-Type":"application/json"
                  },
                  body:JSON.stringify({id:this.state.eng_id, tmp_email:this.state.tmp_email, tmp_mobile:this.state.tmp_mobile, tmp_code:this.state.tmp_code})
              }

                fetch(`${config.googleAuth.backURL}admin/verifyEngeeUpdates`,options)
                .then(res=>{
                  //console.log("response",res)
                  return res.json();
                })
                .then(data=>{                
                  if(data.status == true){
                    this.setState({ open1: false })
                    this.getallEngineers();
                  }
                  else{
                    toast("Something wrong!");
                  }
                })
                .catch(err=>{
                  console.log("error",err)
                }) 
      }



      codeDivStatus = (e) => {
        var editStdValue = e.target.getAttribute('data-value');
        
          this.setState({ codeDiv:true ,editStdId: e.target.id, editStdValue: editStdValue});
    
      }
    
      codeDivStatus1 = (e) => {
          this.setState({ codeDiv:false ,editStdId:0, editStdValue: '' });
    
      }
    
        updateStdCode = (e) => {
          console.log('Id',this.state.editStdId);
          console.log('Value',this.state.editStdValue);
            let options={
              method:"POST",
              headers:{
              Accept:"application/json",
                "Content-Type":"application/json"
              },
              body:JSON.stringify({id:this.state.editStdId, value:this.state.editStdValue})
            }
            fetch(`${config.googleAuth.backURL}admin/updateEngineerID`,options)
            .then(res=>{
              return res.json();
            })
            .then(data=>{                
              if(data.status == true){
                
    
                $("#uniquecodeid"+this.state.editStdId).text(this.state.editStdValue);
                this.setState({ codeDiv:false ,editStdId:0, editStdValue: '' });
    
              }
              else{
                toast("Ops! Please try again later.");
              }
            })
            .catch(err=>{
              console.log("error",err)
            })
        }

  render(){
    var i =1;
    const fallbackSrc = `/images/user1.png`;
    return(
      <div>
        
        <Adminheader/>
        <Adminsidebar/>
        <ToastContainer />
        <div class="wrapper">
        <div class="main">
      
      <div class="main-content">
        <div class="container-fluid">
          <h3 class="page-title">Engineers List</h3>
          <div class="row">
            <div class="col-md-12">
                    <div class="panel">
              </div>
              <div class="panel">
                  <div class="panel-body">
                        <table class="table" id="example1">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th> Photo</th>
                                          <th> Name</th>
                                          <th> Email</th>
                                          <th> Phone</th>
                                          <th> Location</th>
                                          <th> Service Engineers Id</th>
                                          <th> Status</th>
                                          <th>Updates</th>
                                          <th> Assign University</th>

                                        </tr>
                                      </thead>

                                        <tbody>
                                        {this.state.data.length>0 ?<>
                                        {this.state.data.map((l) => {
                                          const imageSrc = `${config.googleAuth.backend}profile/`+l.photo;
                                          return(
   
                                              <tr>
                                                  <td>{i++}</td>
                                                  <td><img src={imageSrc} onError={(e)=>{e.target.onError = null; e.target.src = fallbackSrc}} height='50px' width='50px'/></td>
                                                  <td>{l.fullname}</td>
                                                  <td>{l.email}</td>
                                                  <td>{l.mobile}</td>
                                                  <td>{l.location}</td>
                                                   


                                                    <td>
                                                        <div style={{'display':(this.state.codeDiv&&this.state.editStdId==l.eng_id_fk)?'block':'none'}}>
                                                            <input type='text' name='editStdValue' defaultValue={l.eng_id} size='7' onChange={this.changedata} /><i class="fa fa-check" style={{'padding-left':`2px`,'color':'green'}}  onClick={this.updateStdCode}></i> <i class="fa fa-times" style={{'padding-left':`2px` ,'color':'red' }} onClick={this.codeDivStatus1}></i>
                                                        </div>

                                                        <div style={{'display':(this.state.codeDiv&&this.state.editStdId==l.eng_id_fk)?'none':'block'}}>
                                                          <span id={`uniquecodeid${ l.eng_id_fk }`}> { l.eng_id } </span> <i class="fa fa-edit" style={{'padding-left':`2px`,'color':'#256BFE' }}  onClick={this.codeDivStatus}  id={l.eng_id_fk}  data-value={l.eng_id}></i> 
                                                        </div>
                                                    </td>


                                                  <td>
                                                    <label class="switch">
                                                      <input type="checkbox" name='status' value={l.eng_id_fk} onChange={this.changeStatus} checked={l.status==0?true:false}/>
                                                      <span class="slider round"></span>
                                                    </label>
                                                  </td>

                                                <td>
                                                  {((l.tmp_email!==''&&l.tmp_email!=null)||(l.tmp_mobile!==''&&l.tmp_mobile!=null)||(l.tmp_code!==''&&l.tmp_code!=null))?

                                                  <a href="javascript:void(0)" onClick={() => { this.openModal1(l.uid,l.tmp_email,l.tmp_mobile,l.tmp_code) }} class="btn btn-success btn-sm"><i class="fa fa-edit"></i></a>
                                                  :''}
                                                </td>


                                                  <td>
                                                      <a href="javascript:void(0)" onClick={() => { this.openModal(l.id,l.uni_id,l.hostel_id) }} class="btn btn-success"><i class="fa fa-plus"></i></a>&nbsp;&nbsp;
                                                  </td>
                                              </tr>
                                            
                                              )
                                          })}
                                          </>:<></>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
          </div>
        </div>
      </div>
     
    </div>
    </div>
        <Adminfooter/>



        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id='shareModal'>
          <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'>Assign University</Modal.Title>
          </Modal.Header>
          <Modal.Body>   
          <p id="error"></p>
            <form onSubmit={this.mySubmit}>
                  
                <select class="form-control" name="uni_id" value={this.state.formdata.uni_id} onChange={this.changedata}>
                    <option value="">-- Select University ---</option>
                  {this.state.university && this.state.university && this.state.university.length>0 ?  (this.state.university.map((item) =>(<option value={item.id} >{item.name}</option>))   ) : '' }
                </select>
                <br/>

                <select class="form-control" name="hostel_id" value={this.state.formdata.hostel_id} onChange={this.changedata}>
                    <option value="">-- Select Hostel---</option>
                  {this.state.hostels && this.state.hostels && this.state.hostels.length>0 ?  (this.state.hostels.map((item) =>(<option value={item.id} >{item.name}</option>))   ) : '' }
                </select>
                <br/>
                <button type="submit" class="btn btn-success">Submit</button>
            </form>
          </Modal.Body>          
      </Modal>


      <Modal
          show={this.state.open1}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id='shareModal'>
          <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'>Verify Updates</Modal.Title>
          </Modal.Header>
      <Modal.Body>   
          <p id="error"></p>
              <form onSubmit={this.mySubmit1}>
                {this.state.tmp_email!=''&&this.state.tmp_email!=null?<>
                  <input class="form-control" placeholder="Email" name="tmp_email" onChange={this.changedata} value={this.state.tmp_email} type="text"/>
                  <br/></>
                  :''}

                  {this.state.tmp_mobile!=''&&this.state.tmp_mobile!=null?<>
                  <input class="form-control" placeholder="Mobile" name="tmp_mobile" onChange={this.changedata} value={this.state.tmp_mobile}  type="text"/>
                  <br/></>
                  :''}


                  {this.state.tmp_code!=''&&this.state.tmp_code!=null?<>
                  <input class="form-control" placeholder="Student ID" name="tmp_code" onChange={this.changedata} value={this.state.tmp_code}  type="text"/>
                  <br/>                                  
                  </>
                  :''}

                  <button type="submit" class="btn btn-success">Verify</button>
              </form>
          </Modal.Body>          
      </Modal>


        </div>
        
      )
  }
}

export default Adminengineers