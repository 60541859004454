import React from 'react'
import Adminheader from './common/adminheader'
import Adminfooter from './common/adminfooter'
import Adminsidebar from './common/adminsidebar'
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "./config.js"
import {Switch,Route,Redirect,Link} from 'react-router-dom';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Modal from 'react-bootstrap-modal'

import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const axios = require("axios");
class Adminsettings extends React.Component{
    state={    
        loginflag:false,
        open: false,
         
            id:0,
            table:'',
            text:"",
         
        errors: []
      }

    initialState = {
        id:0,
        table:'',
        text:""
    };
    closeModal = () => this.setState({ open: false })

      openModal = async (id=0,table='') => {
        
          await this.setState({ open: true,id:id,table:table })
          await this.getContent(id,table);
          
      }
       
     changedata=(e)=>{
        //this.setState({[e.target.name]:e.target.value.toLowerCase()}) 
        this.setState({ [e.target.name]:e.target.value});
         
      }  
      componentDidMount(){
        this.getuniversities();
        $(document).ready(function () {
          setTimeout(function () {
            $('#example1').DataTable();
          }, 1000);
        });
      }



      getContent = (id,table) => {
        
        let options={
            method:"POST",
            headers:{
              Accept:"application/json",
              "Content-Type":"application/json"
            },
            body:JSON.stringify({id:id,table:table})
        }
        fetch(`${config.googleAuth.backURL}admin/getContentPrivacyTerm`,options)
            .then((response) => response.json())
            .then( async(responseJson) => {
              
               
              await this.setState({ text : responseJson.data, })
              $("#text").val(responseJson.data);

              setTimeout(() => { $('.modal').css({'display':'block'}); }, 500)

            })
            .catch((error) => {
              console.error(error);
            });        
      }


      getuniversities = () => {
        fetch(`${config.googleAuth.backURL}admin/universities`)
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ data : responseJson.data, })
              
            })
            .catch((error) => {
              console.error(error);
            });        
      }
     
     mySubmit = e => {
        e.preventDefault();
        let error = 0;
        let arry = "";
        if (this.state.text === "") {
          
          arry += 'Text can not be empty* <br/>';
          error++;
        } 
        

        
        if (error > 0) {
            $('#error').html(arry)
          } else {
            $('#error').html('')
              let options={
                  method:"POST",
                  headers:{
                  Accept:"application/json",
                    "Content-Type":"application/json"
                  },
                  body:JSON.stringify({id:this.state.id,text:this.state.text,table:this.state.table})
                }
                fetch(`${config.googleAuth.backURL}admin/addPrivacyTermCondition`,options)
                .then(res=>{
                   
                  return res.json();
                })
                .then(data=>{                
                  if(data.status == true){
                    toast("Content updated successfully");
                    this.setState({ open: false })
                  }
                  else{
                    toast("Something wrong!");
                  }
                })
                .catch(err=>{
                  console.log("error",err)
                })         
         }      
  
  }
  
  render(){
    var i =1;
    return(
      <div>
        
        <Adminheader/>
        <Adminsidebar/>
        <ToastContainer />
        <div class="wrapper">
        <div class="main">
      
      <div class="main-content">
        <div class="container-fluid">
          <h3 class="page-title">Settings</h3>
          
          <div class="row">
            <div class="col-md-12">
                            
                            <div class="panel">
                                
                                <div class="panel-body">
                        <table class="table" id="example1">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>University Name</th>
                                                <th>Add Privacy Policy</th>                                              
                                                <th>Add Terms Conditions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {(this.state.data)?this.state.data.map((l) => {
   return(
   
                                            <tr>
                                                <td>{i++}</td>
                                                
                                                <td>{l.name}</td>
                                                <td><a href="javascript:void(0)" onClick={() => { this.openModal(l.id,'privacypolicy') }} class="btn btn-success"><i class="fa fa-plus"></i></a>&nbsp;&nbsp;</td>                                                           
                                                <td>

                                                                                           
                                                <a href="javascript:void(0)" onClick={() => { this.openModal(l.id,'termconditions') }} class="btn btn-success"><i class="fa fa-plus"></i></a>&nbsp;&nbsp;
                                                

                                                </td>
                                            </tr>
                                            
                                            )
}):''}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
          </div>
        </div>
      </div>
     
    </div>
    </div>
        <Adminfooter/>



        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id='shareModal'>
          <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'>Add Content</Modal.Title>
          </Modal.Header>
          <Modal.Body>   
          <p id="error"></p>
            <form onSubmit={this.mySubmit}>
                  <textarea class="form-control" placeholder="Enter text" id='text' onChange={this.changedata} name="text" type="text">{this.state.text}</textarea>
                  <br/>
                  
                  <button type="submit" class="btn btn-success">Submit</button>
                  </form>
          </Modal.Body>          
      </Modal>
        </div>
        
    )
  }
}

export default Adminsettings