import React from "react";
import { Link, withRouter } from "react-router-dom";
import Footer from "./footer";
import $ from "jquery";
import jwt from "jsonwebtoken";
import Header from "./header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./admin/config.js";
import { GlobalStoreContext } from "../ContextAPI/MyContext";
import Toggle from "react-styled-toggle";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import { Rating } from "react-simple-star-rating";

class TicketDetail extends React.Component {
  static contextType = GlobalStoreContext;
  state = {
    user_name: "",
    user_flag: "",
    ticketData: [],
    Activities: [],
    asset_id_error: "",
    images: [],
    ticket_status: "",
    checked: false,
    inventoryList: [],
    admin_status: "",
    admin_rating: 0,
    formdata: {
      ticket_status: "",
      asset_id: "",
      new_asset_id: "",
      work_note: "",
      ticket_id: "",
      insertdate: Date.now(),
    },
  };

  componentDidMount = (e) => {
    const { myData } = this.context;
    // Update your component's state with assetList from the context
    if (myData) {
      this.setState({
        formdata: { ...this.state.formdata, new_asset_id: myData },
      });
      if (myData.value && this.state.ticketData?.replacedAsset === 0) {
        this.verifyQrCode(myData.value);
      }
    }

    jwt.verify(localStorage.getItem("user_name"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_name: decoded.user_name });
      }
    });

    jwt.verify(localStorage.getItem("user_flag"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_flag: decoded.user_flag });
      }
    });
    const queryParams = new URLSearchParams(window.location.search);

    var tktid = queryParams.get("id");
    this.getTicketDetails(tktid);
  };

  getTicketDetails = (value) => {
    this.setState({ formdata: { ...this.state.formdata, ticket_id: value } });
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: value }),
    };
    fetch(`${config.googleAuth.backURL}user/getTicketDetails`, options)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        if (data.status == true) {
          this.setState({
            ticketData: data.data,
            ticket_status: data.data.ticket_status,
            images: data.images,
            admin_status: data.data.admin_status,
            admin_rating: data.data.admin_rating,
            // formdata: { ...this.state.formdata, work_note: data.data[0].work_note }
          });
          if (data.data.replaceFlag === 0) {
            this.getAvailInventory(data.data.asset_id);
          }
          if (data.data.replaceFlag === 1 && data.data.rAsset_id) {
            this.getAvailInventory(data.data.rAsset_id);
          }
          if (data.data.replaceFlag === 1 && data.data.rAsset_id === null) {
            this.getAvailInventory(data.data.asset_id);
          }

          if (data.data.replaceFlag === 1 && data.data.rAsset_id !== null) {
            this.context.updateData({
              label: data.data.rAsset_id,
              value: data.data.rAsset_id,
            });
            this.setState({ checked: true });
          } else {
            this.setState({ checked: false });
          }
          this.getActivities(this.state.ticketData.ticket_no);
        } else {
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  verifyQrCode = (value) => {
    setTimeout(() => {
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          asset_id: value,
        }),
      };
      fetch(`${config.googleAuth.backURL}user/verifyInventoryQrcode`, options)
        .then((res) => {
          return res.json();
        })

        .then((data) => {
          if (data.status == true) {
            this.setState({ asset_id_error: "" });
          } else {
            this.setState({ asset_id_error: data.message });
          }
        })

        .catch((err) => {
          console.log("error", err);
        });
    }, 300);
  };
  getAvailInventory = (asset_id) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ asset_id: asset_id }),
    };
    fetch(`${config.googleAuth.backURL}user/getAvailInventory`, options)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        console.log(data.data);
        if (data.status == true) {
          this.setState({
            inventoryList: data.data.map((v) => ({
              label: `${v.name} : ${v.qrcodenew}`,
              value: v.qrcodenew,
            })),
          });
        } else {
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };
  handleChange = (event) => {
    const { name, value, checked } = event.target;
    const { updateData } = this.context;
    if (this.state.ticketData?.replacedAsset === 0) {
      this.context.updateData(null);
    }
    if (!checked) {
      // updateData(null);
      this.setState({ new_asset_id: "" });
    }

    // If you want to update a nested property like formdata, create a copy of it
    // and then update the specific property
    if (name.startsWith("formdata.")) {
      const fieldName = name.replace("formdata.", "");
      this.setState((prevState) => ({
        formdata: {
          ...prevState.formdata,
          // [fieldName]: checked,
        },
      }));
    } else {
      // If not a nested property, update it directly
      this.setState({ [name]: checked });
    }
  };
  getActivities = (ticket_no) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ticket_no: ticket_no }),
    };
    fetch(`${config.googleAuth.backURL}user/getTicketActivities`, options)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        if (data.status == true) {
          this.setState({ Activities: data.data });
        } else {
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  changedata = (e) => {
    const { updateData } = this.context;
    if (e.target.name == "ticket_status") {
      this.setState({ [e.target.name]: e.target.value });
    }

    if (e.target.name == "new_asset_id") {
      updateData(e.target.value);
      this.setState({
        formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
      });
    }
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
  };

  mySubmit = (e) => {
    e.preventDefault();
    try {
      if (this.state.user_flag === "UR") {
        if (this.state?.admin_rating === "") {
          toast("Verify status can not be empty");
          document.getElementById("submit").disabled = false;
          return false;
        }
        const newBody = {
          id: this.state.ticketData["id"],
          admin_status: this.state.admin_status,
          work_note: this.state.formdata.work_note,
        };
        this.changeStatus(newBody);
      } else {
        let error = 0;
        let arry = "";
        if (this.state.ticket_status === "") {
          toast("Ticket Status can not be empty");
          error++;
        }

        if (this.state.formdata.work_note === "") {
          toast("Work Note can not be empty");
          error++;
        }

        if (error > 0) {
          $("#error").html(arry);
        } else {
          $("#error").html("");
          document.getElementById("submit").disabled = true;
          let newObj = {
            ...this.state.formdata,
            new_asset_id: this.context.myData?.value,
            asset_id:
              this.state.ticketData["replaceFlag"] === 1 &&
              this.context.myData?.value
                ? this.state.ticketData.rAsset_id
                : this.state.ticketData.asset_id,
            student_id: this.state.ticketData.student_id,
          };

          if (
            this.state.ticketData["replaceFlag"] === 1 &&
            this.context.myData?.value === this.state.ticketData.rAsset_id
          ) {
            delete newObj.new_asset_id;
          }
          if (newObj.asset_id === null) {
            newObj.asset_id = this.state.ticketData.asset_id;
          }

          if (newObj?.ticket_status === "") {
            toast("Ticket Status can not be empty");
            document.getElementById("submit").disabled = false;
            return false;
          }

          let options = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newObj),
          };
          fetch(`${config.googleAuth.backURL}user/updateTicketStatus`, options)
            .then((res) => {
              //console.log("response",res)
              //window.location.reload();

              return res.json();
            })

            .then((data) => {
              if (data.status == true) {
                document.getElementById("submit").disabled = false;
                toast("Successfully Updated!!");
                setTimeout(() => {
                  this.props.history.push("/my-ticket");
                  window.location.reload();
                }, 2000);
              } else {
                document.getElementById("submit").disabled = false;
                toast(data.message || "Ops! Somthing went wrong.");
              }
            })

            .catch((err) => {
              document.getElementById("submit").disabled = false;
              console.log("error", err);
            });
        }
      }
    } catch (err) {
      document.getElementById("submit").disabled = false;
      console.log(err?.message, "Error");
    }
  };

  handleSelectChange = (newValue) => {
    this.context.updateData(newValue);
    this.setState({ asset_id_error: "" });
  };

  handleRating = (value) => {
    this.setState({ admin_rating: value });
    try {
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: this.state.ticketData["id"],
          admin_rating: value,
        }),
      };
      fetch(`${config.googleAuth.backURL}admin/addrating`, options)
        .then((res) => {
          //console.log("response",res)
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            this.getTicketDetails(data.ticket_id);
            toast("updated successfully!!");
          } else {
            toast(data.message || "Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (err) {
      console.log("error", err);
    }
  };

  changeStatus = (value) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    };
    fetch(`${config.googleAuth.backURL}admin/updateTicketStatus`, options)
      .then((res) => {
        //console.log("response",res)
        return res.json();
      })
      .then((data) => {
        if (data.status == true) {
          this.getTicketDetails(data.ticket_id);
          this.closeModal();
          toast("updated successfully!!");
          this.setState(this);
        } else {
          toast(data.message || "Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  handleTicketStatus = (e) => {
    e.preventDefault();
    const value = e.target.value;
    confirmAlert({
      customUI: ({ title, message, onClose }) => (
        <div className="custom-ui">
          <h4 className="m-0 text-primary">Are you sure?</h4>
          <hr className="my-2"></hr>
          <p>You want to change the status?</p>
          <button className="btn btn-warning mr-2" onClick={onClose}>
            No
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              this.setState({ admin_status: value });
              onClose();
            }}
          >
            Yes!
          </button>
        </div>
      ),
      overlayClassName: "overlay-custom-class-name",
    });
  };

  render() {
    console.log("this.state", this.state);
    return (
      <div class="appHdr">
        <ToastContainer />
        <header>
          <Header />
        </header>

        <section class="appBody wrapper">
          <div class="login-block form login">
            <div class="login-row">
              <div class="main-heading text-center">
                <h2>Ticket Detail</h2>
              </div>
              <form onSubmit={this.mySubmit}>
                <p id="error"></p>
                <div class="login-form common-form">
                  <div class="field input form-group">
                    <label>Number</label>
                    <input
                      class="form-control"
                      type="text"
                      name="ticket_no"
                      placeholder={this.state.ticketData["ticket_no"]}
                      readOnly
                    />
                  </div>
                  <div class="field input form-group">
                    <label>Asset ID </label>
                    <input
                      class="form-control"
                      type="text"
                      name="asset_id"
                      placeholder={this.state.ticketData["asset_id"]}
                      readOnly
                    />
                  </div>

                  <div class="field input form-group">
                    <label>Caller</label>
                    <input
                      class="form-control"
                      type="text"
                      name="Caller"
                      placeholder={this.state.ticketData["sName"]}
                      readOnly
                    />
                  </div>

                  <div class="field input form-group">
                    <label>Assigned To</label>
                    <input
                      class="form-control"
                      type="text"
                      name="Caller"
                      placeholder={
                        this.state.ticketData["eName"]
                          ? this.state.ticketData["eName"]
                          : "Waiting to be assigned"
                      }
                      readOnly
                    />
                  </div>

                  <div class="field input form-group">
                    <label>Opened</label>
                    <input
                      class="form-control"
                      type="text"
                      name="Caller"
                      placeholder={new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(this.state.ticketData["open_date"])}
                      readOnly
                    />
                  </div>

                  <div class="field input form-group">
                    <label>Service Name</label>
                    <input
                      class="form-control"
                      type="text"
                      name="Caller"
                      placeholder={this.state.ticketData["serName"]}
                      readOnly
                    />
                  </div>

                  {this.state.user_flag === "SE" ? (
                    <>
                      <div class="field input form-group">
                        <label>State </label>
                        <div class="src-cat-grp">
                          <select
                            disabled={
                              this.state.ticketData["admin_status"] ===
                              "verified"
                            }
                            class="form-control"
                            value={this.state.ticket_status}
                            onChange={this.changedata}
                            name="ticket_status"
                            id="ticket_status"
                          >
                            <option value="">Choose State</option>
                            <option value="open">Open </option>
                            <option value="wip">WIP </option>
                            <option value="closed">Closed</option>
                          </select>
                          <div class="src-bars">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div class="field input form-group">
                        <label>State</label>
                        <input
                          class="form-control"
                          type="text"
                          name="Caller"
                          placeholder={this.state.ticketData["ticket_status"]}
                          readOnly
                        />
                      </div>
                    </>
                  )}

                  {/* <div class="field input form-group">
                            <label>Priority </label>
                            <div class="src-cat-grp">
                                <select class="form-control" name="dpdHomeCategory" id="dpdHomeCategory">
                                    <option value="">Choose Priority</option>
                                    <option value="Request 1">Opened</option>
                                    <option value="Request 2">Work in Process </option>
                                    <option value="Request 3">Closed</option>
                                </select>
                                <div class="src-bars">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div> */}

                  <div class="field input form-group">
                    <label>Short Discription</label>
                    <input
                      class="form-control"
                      type="text"
                      name="short_desc"
                      placeholder={this.state.ticketData["short_desc"]}
                      readOnly
                    />
                  </div>
                  {/* this.state.ticketData["replacedAsset"]!==0 && */}
                  {this.state.user_flag === "SE" ? (
                    <div
                      class="field input form-group"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <label>Replace Asset </label>
                      <Toggle
                        disabled={
                          this.state.ticketData["admin_status"] === "verified"
                        }
                        checked={this.state.checked}
                        backgroundColorChecked={"#f9ab1a"}
                        sliderHeight={25}
                        sliderWidth={25}
                        height={32}
                        name={"checked"}
                        value={this.state.checked}
                        onChange={this.handleChange}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {this.state.checked ? (
                    <div
                      class="field input form-group"
                      style={{ marginBottom: "0px" }}
                    >
                      <label>New Asset ID </label>
                      {/* <input
                        disabled={this.state.ticketData["replacedAsset"]}
                        class="form-control"
                        type="text"
                        value={this.context.myData}
                        name="new_asset_id"
                        placeholder="123AACCDDl"
                        required
                        onChange={this.changedata}
                      /> */}

                      <Select
                        isDisabled={this.state.user_flag === "UR" ||this.state.ticketData['ticket_status']==="closed" || this.state.ticketData['admin_status']==="verified"}
                        onChange={this.handleSelectChange}
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="asset_id"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            position: "relative",
                            color: "red",
                            top: "-14px",
                            minHeight: "50px", // You can adjust the height here
                          }),
                        }}
                        value={this.context.myData}
                        // options={this.state.AssetDetail}
                        options={
                          this.state.inventoryList.length > 0
                            ? this.state.inventoryList
                            : [{ label: "No Asset found", value: null }]
                        }
                      />

                      {this.state.ticketData["replaceFlag"] === 0 && (
                        <Link
                          class=" notop-qr-icn"
                          to={`/Qrsubmit?id=ReplaceAsset&oldAsset=${this.state.ticketData["asset_id"]}&ticketId=${this.state.formdata.ticket_id}`}
                        >
                          <i class="fa fa-qrcode" aria-hidden="true"></i>
                        </Link>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.asset_id_error !== "" && (
                    <p style={{ color: "red" }}>{this.state.asset_id_error}</p>
                  )}

                  {this.state.user_flag === "UR" &&
                  this.state.ticketData["admin_status"] !== "verified" &&
                  this.state.ticketData["ticket_status"] === "closed" ? (
                    <>
                      <div class="field input form-group">
                        <label>Verify status </label>
                        <div class="src-cat-grp">
                          <select
                            class="form-control"
                            name="admin_status"
                            value={this.state.admin_status}
                            onChange={this.handleTicketStatus}
                            required
                          >
                            <option value="">-- Select status ---</option>
                            {[
                              { label: "Reopened", value: "reopened" },
                              {
                                label: "Verified",
                                value: "verified",
                              },
                            ].map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </select>
                          <div class="src-bars">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    this.state.ticketData["ticket_status"] === "closed" && ["UR","students"].includes(this.state.user_flag) && (
                      <>
                        <div class="field input form-group">
                          <label>Verify status</label>
                          <input
                            class="form-control"
                            type="text"
                            name="Caller"
                            placeholder={this.state.admin_status}
                            readOnly
                          />
                        </div>
                      </>
                    )
                  )}
                  {this.state.user_flag === "UR" &&
                    this.state.ticketData["admin_status"] === "verified" && (
                      <div class="field input form-group">
                        <label>
                          {" "}
                          {Number(this.state.admin_rating)
                            ? "Rating"
                            : "Rate this ticket"}{" "}
                        </label>{" "}
                        <br />
                        <div
                          class="src-cat-grp"
                          style={{ marginLeft: "10px", marginTop: "10px" }}
                        >
                          <Rating
                            key={this.state.key}
                            initialValue={this.state.admin_rating}
                            disableFillHover
                            readonly={this.state.admin_rating > 0}
                            // value={this.state.admin_rating}
                            onClick={(value) =>
                              confirmAlert({
                                customUI: ({ title, message, onClose }) => (
                                  <div className="custom-ui">
                                    <h4 className="m-0 text-primary">
                                      Are you sure?
                                    </h4>
                                    <hr className="my-2"></hr>
                                    <p>You want to rate this?</p>
                                    <button
                                      className="btn btn-warning mr-2"
                                      onClick={() => {
                                        this.setState({
                                          admin_rating: 0,
                                          key: Math.random(),
                                        });
                                        onClose();
                                      }}
                                    >
                                      No
                                    </button>
                                    <button
                                      className="btn btn-primary"
                                      onClick={(e) => {
                                        this.handleRating(value);
                                        onClose();
                                      }}
                                    >
                                      Yes!
                                    </button>
                                  </div>
                                ),
                                overlayClassName: "overlay-custom-class-name",
                              })
                            }
                            size={30}
                            fillColor="#f1b345"
                          />
                        </div>
                      </div>
                    )}
                  {["UR"].includes(this.state.user_flag) &&
                  this.state.ticketData["admin_status"] !== "verified" &&
                  this.state.ticketData["ticket_status"] === "closed" ? (
                    <>
                      <div class="field input form-group">
                        <label>Work notes</label>
                        <textarea
                          class="form-control"
                          type="text"
                          id="work_note"
                          onChange={this.changedata}
                          name="work_note"
                        ></textarea>
                      </div>

                      <div class="field form-btn button mt-40">
                        <input
                          disabled={
                            this.state.ticketData["admin_status"] === "verified"
                          }
                          class="btn btn-common btn-blue btn-round btn-big"
                          type="submit"
                          name="submit"
                          id="submit"
                          value="Update"
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {["SE"].includes(this.state.user_flag) &&
                  this.state.ticketData["admin_status"] !== "verified" ? (
                    <>
                      <div class="field input form-group">
                        <label>Work notes</label>
                        <textarea
                          class="form-control"
                          type="text"
                          id="work_note"
                          onChange={this.changedata}
                          name="work_note"
                        ></textarea>
                      </div>

                      <div class="field form-btn button mt-40">
                        <input
                          disabled={
                            this.state.ticketData["admin_status"] === "verified"
                          }
                          class="btn btn-common btn-blue btn-round btn-big"
                          type="submit"
                          name="submit"
                          id="submit"
                          value="Update"
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div class="field input form-group mt-15">
                    <label>Activities</label>
                    {this.state.Activities.map((actvity, index) => {
                      return (
                        <>
                          <textarea
                            class="form-control"
                            readOnly
                            type="text"
                            placeholder={
                              actvity.work_note +
                              "  " +
                              new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(actvity.open_date)
                            }
                          ></textarea>{" "}
                          <br />{" "}
                        </>
                      );
                    })}
                  </div>

                  <div class="field input form-group mt-15">
                    {this.state.images.map((image, index) => {
                      return (
                        <>
                          <a
                            href={`${config.googleAuth.backend}ticket/${image.path}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={`${config.googleAuth.backend}ticket/${image.path}`}
                              height="150px"
                              width="150px"
                            />
                          </a>{" "}
                        </>
                      );
                    })}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </section>
      </div>
    );
  }
}
export default withRouter(TicketDetail);
