import React from "react";
import { Link, withRouter } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import jwt from "jsonwebtoken";
import config from "./admin/config.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { GlobalStoreContext } from "../ContextAPI/MyContext";

const axios = require("axios");
class Assetadd extends React.Component {
  static contextType = GlobalStoreContext;
  state = {
    formdata: {
      //asset_id:this.props.data,
      asset_id: "",
      student_id: "",
      insertdate: Date.now(),
    },
    user_name: "",
  };

  componentDidMount = (e) => {
    const queryParams = new URLSearchParams(window.location.search);
    const asset_id = queryParams.get("asset_id");
    const { myData, updateData } = this.context;
    console.log(myData, "myData");
    if (myData) {
      this.setState({
        formdata: {
          ...this.state.formdata,
          asset_id: myData,
        },
      });
    }

    //this.setState({ formdata: { ...this.state.formdata, asset_id:asset_id} });
    jwt.verify(localStorage.getItem("user_id"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({
          formdata: {
            ...this.state.formdata,
            student_id: decoded.user_id,
            asset_id: asset_id,
          },
        });
        //this.setState({user_id:decoded.user_id});
      }
    });

    jwt.verify(localStorage.getItem("user_name"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_name: decoded.user_name });
      }
    });
  };

  mySubmit = (e) => {
    e.preventDefault();
    console.log(this.state.formdata.asset_id);
    let error = 0;
    let arry = "";
    if (!this.context.myData) {
      toast("Asset can not be empty");
      error++;
    }

    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");

      const newBody = {
        ...this.state.formdata,
        asset_id: this.context.myData,
      };

      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newBody),
      };
      fetch(`${config.googleAuth.backURL}user/addAssetByStd`, options)
        .then((res) => {
          //console.log("response",res)
          return res.json();
        })

        .then((data) => {
          if (data.status == true) {
            toast("Asset added Successfully");

            setTimeout(() => {
              window.location.replace("/asset-detail");
            }, 2000);
          } else {
            toast(data.message || "Ops! Something went wrong.");
          }
        })

        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  render() {
    return (
      <div class="appHdr">
        <header>
          <Header />
        </header>
        <section class="appBody wrapper">
          <div class="login-block form login">
            <div class="login-row">
              <div class="main-heading text-center">
                <h2>Add New Asset</h2>
              </div>

              <form onSubmit={this.mySubmit} enctype="multipart/form-data">
                <div class="login-form common-form">
                  <div class="field input form-group">
                    <label>Asset ID </label>
                    <input
                      class="form-control"
                      type="text"
                      value={this.context.myData}
                      name="asset_id"
                      placeholder="123AACCDDl"
                      required
                      onChange={(e) => {
                        this.context.updateData(e.target.value);
                        // this.setState({
                        //   formdata: {
                        //     ...this.state.formdata,
                        //     asset_id: e.target.value,
                        //   },
                        // });
                      }}
                    />
                    <Link class="qr-icn" to="/Qrsubmit?id=Assetadd">
                      <i class="fa fa-qrcode" aria-hidden="true"></i>
                    </Link>
                  </div>
                  {/* <GlobalStoreContext.Consumer>
                    {(context) => (
                      <div
                        style={{
                          margin: "3px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        {console.log(context.myData, "context.myData")}
                        {context.myData?.map((v) => {
                          return (
                            <div
                              key={v.qrcodenew}
                              style={{
                                border: "1px solid #f9ab1a",
                                borderRadius: "5px",
                                display: "flex",
                                padding: "8px",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",

                                  justifyContent: "center",
                                }}
                              >
                                <p style={{ margin: "0px" }}>
                                  {" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    Name
                                  </span>
                                  : {v.name}
                                </p>
                                <p style={{ margin: "0px" }}>
                                  {" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    Code
                                  </span>
                                  : {v.qrcodenew}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  title="remove"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const objectToRemove = v;

                                    // Use the filter method to create a new array without 'objectToRemove'
                                    const updatedDataRes =
                                      context.myData.filter(
                                        (item) =>
                                          item.qrcodenew !==
                                          objectToRemove.qrcodenew
                                      );

                                    // Call the updateData function with the updated array
                                    console.log(
                                      updatedDataRes,
                                      "updatedDataRes"
                                    );
                                    context.removeData(updatedDataRes);
                                  }}
                                  style={{
                                    color: "#f9ab1a",
                                    margin: "0",
                                    fontWeight: "bold",
                                    padding: "0",
                                    border: "0",
                                    background: "none",
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                  }}
                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                      "#f9ab1a"; // Change background color on hover
                                    e.currentTarget.style.color = "black"; // Change text color on hover
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                      "transparent"; // Restore background color when not hovered
                                    e.currentTarget.style.color = "#f9ab1a"; // Restore text color when not hovered
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </GlobalStoreContext.Consumer> */}
                </div>
                <div class="field form-btn button mt-40">
                  <p id="error"></p>
                  <input
                    class="btn btn-common btn-blue btn-round btn-big"
                    type="submit"
                    name="submit"
                    value="Submit"
                  />
                  {/* <button onClick={this.check}>Check</button> */}
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </section>
      </div>
    );
  }
}
export default withRouter(Assetadd);
