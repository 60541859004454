import React from 'react'
import Adminheader from './common/adminheader'
import Adminfooter from './common/adminfooter'
import Adminsidebar from './common/adminsidebar'
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "./config.js"
import {Switch,Route,Redirect,Link} from 'react-router-dom';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Modal from 'react-bootstrap-modal'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const axios = require("axios");
class Adminservice extends React.Component{
    state={    
        data:[],
        loginflag:false,
        selectedFile:'',
        formdata: {
          id:"",
          //price: "",
          name:"",
          imagePath:''
        },
        errors: []
      }

      initialState = {
        selectedFile:'',
          formdata: {
            id:"",
            //price: "",
            name:"",
            imagePath:''
          },  
        };
    closeModal = () => this.setState({ open: false })

    openModal = async (id=0) => { 
      this.setState({ open: true })
      if(id>0){
        await this.getservice(id)        
      }else {
        this.setState({formdata: this.initialState});
      }
      setTimeout(() => { $('.modal').css({'display':'block'}); }, 500)
    }
       
     changedata=(e)=>{
        //this.setState({[e.target.name]:e.target.value.toLowerCase()}) 
        this.setState({ formdata: { ...this.state.formdata, [e.target.name]:e.target.value} });        
      }  
      componentDidMount(){
        this.getservices();
        $(document).ready(function () {
          setTimeout(function () {
            $('#example1').DataTable();
          }, 1000);
        });
      }

      getservice = async (id) => {
        await fetch(`${config.googleAuth.backURL}admin/service/`+id)
            .then((response) => response.json())
            .then((responseJson) => {
              if(responseJson.status){
              this.setState({ formdata : responseJson.data, })
              }
            })
            .catch((error) => {
              console.error(error);
            });        
      }

      getservices = () => {
        fetch(`${config.googleAuth.backURL}admin/services`)
            .then((response) => response.json())
            .then((responseJson) => {
              if(responseJson.status){
              this.setState({ data : responseJson.data, })
              }
            })
            .catch((error) => {
              console.error(error);
            });        
      }

     
     mySubmit = e => {
        e.preventDefault();
        let error = 0;
        let arry = "";
        if (this.state.formdata.name === "") {
          arry += 'service name can not be empty* <br/>';
          error++;
        } 

        // if (this.state.formdata.price === "") {
        //   arry += 'Price can not be empty* <br/>';
        //   error++;
        // }  
        
        
        

        if (error > 0) {
            $('#error').html(arry)
          } else {
            $('#error').html('')
            let newObj = new FormData()
            newObj.append('file',this.state.selectedFile)
            newObj.append('name',this.state.formdata.name)
            newObj.append('price',this.state.formdata.price)
            newObj.append('id',this.state.formdata.id)
            newObj.append('imagePath',this.state.formdata.imagePath)
            
            
                axios.post(`${config.googleAuth.backURL}admin/addservice`,newObj)
                //fetch(config.googleAuth.backURL+`admin/addservice`,options)
                .then(res=>{
                  console.log("response",res.data)
                  if(res.data.status == true){
                    this.getservices();
                    (res.data.code == 500)?toast("service updated successfully!!"):toast("service added successfully!!");                  
                    this.setState(this.initialState)

                    document.getElementById("cat_form").reset();

                  }else{
                    toast("Something wrong!");
                  }
                  //return res.json();
                })
                .catch(err=>{
                  console.log("error",err)
                })         
         }      
  
  }


  delhost = (id) => {    
        let options={
                  method:"POST",
                  headers:{
                  Accept:"application/json",
                    "Content-Type":"application/json"
                  },
                  body:JSON.stringify({'id':id})
                }
                fetch(`${config.googleAuth.backURL}admin/delservice`,options)
                .then(res=>{
                  //console.log("response",res)
                  return res.json();
                })
                .then(data=>{                
                  if(data.status == true){
                    this.getservices();
                    toast("service deleted successfully!!");                  
                    this.setState(this)
                  }
                  else{
                    toast("Something wrong!");
                  }
                })
                .catch(err=>{
                  console.log("error",err)
                })   
      }

  delUni = (id) => {
    

    confirmAlert({      
    customUI: ({ title, message, onClose }) => <div className='custom-ui'>
        <h3>Are you sure?</h3>
        <p>You want to delete this User?</p>
        <button onClick={onClose}>No</button>
        <button onClick={() => {
            this.delhost(id)
            onClose()
        }}>Yes, Delete it!</button>
      </div>,
    })    
  }

  onFileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
    //this.setState({ selectedFile: [...this.state.selectedFile, ...e.target.files] })

  };
  
  render(){
    var i =1;
    return(
      <div>
        
        <Adminheader/>
        <Adminsidebar/>
        <ToastContainer />
        <div className="wrapper">
        <div className="main">
      
      <div className="main-content">
        <div className="container-fluid">
          <h3 className="page-title">Services List</h3>
          <button  onClick={this.openModal} className="pull-right btn btn-success">Add Service</button>
          <div className="row">
            <div className="col-md-12">
                            
                            <div className="panel">
                                
                                <div className="panel-body">
                        <table className="table" id="example1">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th> Service Name</th>  
                                                                                    
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {(this.state.data)?this.state.data.map((l) => {
   return(
   
                                            <tr>
                                                <td>{i++}</td>
                                                
                                                <td>{l.name}</td>
                                                                                                      
                                                <td>

                                                {/*<Link to={'/admin/hostels/'+l.id} class="btn btn-success"><i class="fa fa-plus"></i> Hostels</Link> &nbsp;&nbsp;                                               */}
                                                <a href="javascript:void(0)" onClick={() => { this.openModal(l.id) }} className="btn btn-success"><i className="fa fa-pencil"></i></a>&nbsp;&nbsp;
                                                <a href="javascript:void(0)"  onClick={() => { this.delUni(l.id) }} className="btn btn-success"><i className="fa fa-trash"></i></a>

                                                </td>
                                            </tr>
                                            
                                            )
}):''}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
          </div>
        </div>
      </div>
     
    </div>
    </div>
        <Adminfooter/>



        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id='shareModal'>
          <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'>Add Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>   
          <p id="error"></p>
            <form onSubmit={this.mySubmit} encType="multipart/form-data" id="cat_form">
            <input type="file" name='file' id='file' defaultValue={this.state.selectedFile} class="form-control" accept="image/*" onChange={this.onFileChange}/>
                  <br/>
                  <input className="form-control" value={this.state.formdata.image} name="imagePath" type="hidden"/>
                  <input className="form-control" placeholder="Service Name" onChange={this.changedata} value={this.state.formdata.name} name="name" type="text"/>
                  <br/>                  
                  {/* <input className="form-control" placeholder="Service Price" onChange={this.changedata} value={this.state.formdata.price} name="price" type="text"/>
                  <br/> */}
                  
                  <button type="submit" className="btn btn-success">Submit</button>
              </form>
          </Modal.Body>          
      </Modal>
        </div>
        
      )
  }
}

export default Adminservice