import React from 'react'
import Adminheader from './common/adminheader'
import Adminfooter from './common/adminfooter'
import Adminsidebar from './common/adminsidebar'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "./config.js"
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import jwt from 'jsonwebtoken'
import $ from 'jquery';
const axios = require("axios");
class Adminprofileedit extends React.Component {

  state = {
    formdata: {},
    selectedFile:'',
    loginflag: false,
    user_id: "",
  }
  initialstate = {
    selectedFile:'',
    loginflag: true,
    user_id: "",
  }

  componentDidMount() {
    const decoded_id = jwt.verify(
      localStorage.getItem("Uidadmin"),
      config.login_secretadmin.key
    );
    this.setState({ user_id: decoded_id.Uidadmin })
    //alert(decoded_id.Uidadmin);
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: '0' }),
    }
    fetch(`${config.googleAuth.backURL}admin/getadminDetails`, options)
      .then((response) => response.json())
      .then((responseJson) => {
        
        this.setState(
          {
            formdata: responseJson.data[0],
          }
        )
        this.setState({ formdata: { ...this.state.formdata, imagePath:responseJson.data[0].ADMIN_IMAGE } });        
         

      })
      .catch((error) => {
        console.error(error);
      });
  }

  mySubmit = e => {
      e.preventDefault();
      let error = 0;
      let arry = "";
      if (this.state.formdata.ADMIN_NAME === "") {  
        toast('Name can not be empty');
        error++;
      } 

      if (this.state.formdata.ADMIN_EMAIL === "") { 
        toast('Email can not be empty');
        error++;
      }
      
      if (this.state.formdata.ADMIN_PHONE === "") {          
        toast('Mobile can not be empty');
          error++;
      } 

      if(error > 0){
          $('#error').html(arry)
      }else{
          $('#error').html('');
              
              let newObj = new FormData()
              newObj.append('file',this.state.selectedFile)
              newObj.append('ADMIN_EMAIL',this.state.formdata.ADMIN_EMAIL)
              newObj.append('ADMIN_NAME',this.state.formdata.ADMIN_NAME)
              newObj.append('ADMIN_PHONE',this.state.formdata.ADMIN_PHONE)
              newObj.append('imagePath',this.state.formdata.imagePath)
          

              axios.post(`${config.googleAuth.backURL}admin/updateAdminProfile`,newObj)
              
              .then(res=>{
                console.log("response",res.data)
                  if(res.data.status == true){
                      


                      //this.getUserInfo(this.state.user_id,this.state.user_flag); 

                      toast("Profile updated Successfully!!");

                      setTimeout(() => {
                          window.location.reload();   
                      }, 3000);
                  }else{
                      toast("Ops! Something went wrong please try again later.");
                  }
                
              })
              .catch(err=>{
                console.log("error",err)
              })       
      }

  }


    
  changedata=(e)=>{
    this.setState({ formdata: { ...this.state.formdata, [e.target.name]:e.target.value} });        
  }  

  onFileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0] });

  };

  render() {
    var i = 1;
    return (
      <div>

        <Adminheader />
        <Adminsidebar />
        <ToastContainer />
        <div class="wrapper">
          <div class="main">

            <div class="main-content">
              <div class="container-fluid">
                <h3 class="page-title">Edit Profile</h3>
                <div class="row">
                  <div class="col-md-12">

                    <div class="panel">
                      <div class="panel-heading"><h3 class="panel-title">Edit Profile</h3></div>
                      <div class="panel-body">
                        <form onSubmit={this.mySubmit}  encType="multipart/form-data">
                          <input type="email" name="ADMIN_EMAIl" class="form-control" placeholder="Enter Email" required="true" onChange={this.changedata} defaultValue={this.state.formdata.ADMIN_EMAIL}/>
                          <br />
                          <input type="text" name="ADMIN_NAME" class="form-control" placeholder="Enter Name" required="true" onChange={this.changedata} defaultValue={this.state.formdata.ADMIN_NAME}/>
                          <br />
                          <input type="text" name="ADMIN_PHONE" class="form-control" placeholder="Enter Phone" required="true" onChange={this.changedata} defaultValue={this.state.formdata.ADMIN_PHONE}/>
                          <br />
                          <input type="file" name='file' id='file' defaultValue={this.state.selectedFile} class="form-control" accept="image/*" onChange={this.onFileChange}/>
                          <input  value={this.state.formdata.ADMIN_IMAGE} name="imagePath" type="hidden"/>
                          <br />


                          {this.state.formdata.ADMIN_IMAGE!==''?<>
                          <img src={`${config.googleAuth.backend}${this.state.formdata.ADMIN_IMAGE}`} height='100px' height='100px' width='100px'/>
                          </>
                          : <></>
                          }<br /><br /> 

                          <button type="submit" class="btn btn-success">Submit</button>
                        </form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Adminfooter />
      </div>

    )
  }
}

export default Adminprofileedit