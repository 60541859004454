import React from 'react'
import Adminheader from './common/adminheader'
import Adminfooter from './common/adminfooter'
import Adminsidebar from './common/adminsidebar'
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "./config.js"
import {Switch,Route,Redirect,Link} from 'react-router-dom';
import Modal from 'react-bootstrap-modal'
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';


class Adminstudentlist extends React.Component{

  state={    
    codeDiv:false,
    editStdId:0,
    editStdValue:'',
    data:[],
    tmp_email:'',
    tmp_mobile:'',
    tmp_code:'',
    std_id:''
    
  }
      componentDidMount(){
          this.getallStudents();
      }
      
      getallStudents = () => {
        fetch(`${config.googleAuth.backURL}admin/getallStudents`)
            .then((response) => response.json())
            .then((responseJson) => {
              if(responseJson.status){
                this.setState({ data : responseJson.data })       
              } 
          })
          .catch((error) => {
              console.error(error);
          });    

          $(document).ready(function () {
            setTimeout(function () {
              $('#example1').DataTable();
            }, 1000);
          });
      }


      changeStatus = (e) => {
            
        let status=(e.target.checked)?0:1;
          
         
        let options={
          method:"POST",
          headers:{
          Accept:"application/json",
            "Content-Type":"application/json"
          },
          body:JSON.stringify({id:e.target.value,status:status})
        }
        fetch(`${config.googleAuth.backURL}admin/updateUserStatus`,options)
            .then((response) => response.json())
            .then((responseJson) => {
                this.getallStudents(); 
            })
            .catch((error) => {
              console.error(error);
            });        
      }

      closeModal = () => this.setState({ open: false })

      openModal = async (id=0,email='',mobile='',code='') => { 
          this.setState({ open: true, std_id:id, tmp_email:email, tmp_mobile:mobile, tmp_code:code})          
          setTimeout(() => { $('.modal').css({'display':'block'}); }, 500)
      }


      changedata=(e)=>{
          this.setState({ [e.target.name]: e.target.value }) 
      }  


      mySubmit = e => {
        e.preventDefault();
        
            
              let options={
                  method:"POST",
                  headers:{
                  Accept:"application/json",
                    "Content-Type":"application/json"
                  },
                  body:JSON.stringify({id:this.state.std_id, tmp_email:this.state.tmp_email, tmp_mobile:this.state.tmp_mobile, tmp_code:this.state.tmp_code})
                }
                fetch(`${config.googleAuth.backURL}admin/verifyStudentsUpdates`,options)
                .then(res=>{
                  //console.log("response",res)
                  return res.json();
                })
                .then(data=>{                
                  if(data.status == true){
                    this.setState({ open: false })
                    this.getallStudents();
                  }
                  else{
                    toast("Something wrong!");
                  }
                })
                .catch(err=>{
                  console.log("error",err)
                })         
             
                
              
  }

  
  codeDivStatus = (e) => {
    var editStdValue = e.target.getAttribute('data-value');
    
      this.setState({ codeDiv:true ,editStdId: e.target.id, editStdValue: editStdValue});

  }

  codeDivStatus1 = (e) => {
      this.setState({ codeDiv:false ,editStdId:0, editStdValue: '' });

  }

    updateStdCode = (e) => {
        let options={
          method:"POST",
          headers:{
          Accept:"application/json",
            "Content-Type":"application/json"
          },
          body:JSON.stringify({id:this.state.editStdId, value:this.state.editStdValue})
        }
        fetch(`${config.googleAuth.backURL}admin/updateStudentID`,options)
        .then(res=>{
          return res.json();
        })
        .then(data=>{                
          if(data.status == true){
            

            $("#uniquecodeid"+this.state.editStdId).text(this.state.editStdValue);
            this.setState({ codeDiv:false ,editStdId:0, editStdValue: '' });

          }
          else{
            toast("Ops! Please try again later.");
          }
        })
        .catch(err=>{
          console.log("error",err)
        })
    }


  render(){
    var i =1;
    const fallbackSrc = `/images/user1.png`;
    
    return(
      <div>
        
        <Adminheader/>
        <Adminsidebar/>
        <ToastContainer />
        <div class="wrapper">
        <div class="main">
      
      <div class="main-content">
        <div class="container-fluid">
          <h3 class="page-title">Students List</h3>
          <div class="row">
            <div class="col-md-12">
                <div class="panel">
                
                </div>
                <div class="panel">
                                
                  <div class="panel-body">
                        <table class="table" id="example1">
                            <thead>
                              <tr>
                                    <th>#</th>
                                    <th>Photo</th>
                                    <th> Name</th>
                                    <th> Email</th>
                                    <th>Phone</th>
                                    <th width='20%'>Student Id</th>
                                    <th>Status</th>
                                    <th>Updates</th>
                                    <th>Action</th>

                              </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.data.map((l) => {
                                          const imageSrc = `${config.googleAuth.backend}profile/`+l.photo;
                                        return(
   
                                            <tr>
                                                <td>{i++}</td>
                                                <td><img src={imageSrc} onError={(e)=>{e.target.onError = null; e.target.src = fallbackSrc}} height='50px' width='50px'/></td>
                                                <td>{l.fullname}</td>
                                                <td>{l.email}</td>
                                                <td>{l.mobile}</td>

                                                
                                                    <td>
                                                        <div style={{'display':(this.state.codeDiv&&this.state.editStdId==l.student_id_fk)?'block':'none'}}>
                                                            <input type='text' name='editStdValue' defaultValue={l.student_id} size='7' onChange={this.changedata} /><i class="fa fa-check" style={{'padding-left':`2px`,'color':'green'}}  onClick={this.updateStdCode}></i> <i class="fa fa-times" style={{'padding-left':`2px` ,'color':'red' }} onClick={this.codeDivStatus1}></i>
                                                        </div>

                                                        <div style={{'display':(this.state.codeDiv&&this.state.editStdId==l.student_id_fk)?'none':'block'}}>
                                                        <span id={`uniquecodeid${l.student_id_fk}`}> { l.student_id } </span> <i class="fa fa-edit" style={{'padding-left':`2px`,'color':'#256BFE' }}  onClick={this.codeDivStatus}  id={l.student_id_fk}  data-value={l.student_id}></i> 
                                                        </div>
                                                    </td>

                                                     
                                                  
                                                
                                                  
                                                  
                                                <td>
                                                    <label class="switch">
                                                      <input type="checkbox" name='status' value={l.student_id_fk} onChange={this.changeStatus} checked={l.status==0?true:false}/>
                                                      <span class="slider round"></span>
                                                    </label>
                                                </td>

                                                <td>
                                                  {((l.tmp_email!==''&&l.tmp_email!=null)||(l.tmp_mobile!==''&&l.tmp_mobile!=null)||(l.tmp_code!==''&&l.tmp_code!=null))?

                                                  <a href="javascript:void(0)" onClick={() => { this.openModal(l.uid,l.tmp_email,l.tmp_mobile,l.tmp_code) }} class="btn btn-success btn-sm"><i class="fa fa-edit"></i></a>
                                                  :''}
                                                </td>

                                                <td>
                                                 <Link to={'/admin/stddetail/'+l.id} class="btn btn-success  btn-sm"><i class="fa fa-eye "></i></Link></td>
                                                
                                                
                                            </tr>
                                            
                                            )})}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
          </div>
        </div>
      </div>
     
    </div>
    </div>
        <Adminfooter/>

        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id='shareModal'>
          <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'>Verify Updates</Modal.Title>
          </Modal.Header>
          <Modal.Body>   
          <p id="error"></p>
              <form onSubmit={this.mySubmit}>
                {this.state.tmp_email!=''&&this.state.tmp_email!=null?<>
                  <input class="form-control" placeholder="Email" name="tmp_email" onChange={this.changedata} value={this.state.tmp_email} type="text"/>
                  <br/></>
                  :''}

                  {this.state.tmp_mobile!=''&&this.state.tmp_mobile!=null?<>
                  <input class="form-control" placeholder="Mobile" name="tmp_mobile" onChange={this.changedata} value={this.state.tmp_mobile}  type="text"/>
                  <br/></>
                  :''}


                  {this.state.tmp_code!=''&&this.state.tmp_code!=null?<>
                  <input class="form-control" placeholder="Student ID" name="tmp_code" onChange={this.changedata} value={this.state.tmp_code}  type="text"/>
                  <br/>                                  
                  </>
                  :''}

                  <button type="submit" class="btn btn-success">Verify</button>
              </form>
          </Modal.Body>          
      </Modal>
        </div>
        
      )
  }
}

export default Adminstudentlist