import React from 'react'
import Adminheader from './common/adminheader'
import Adminfooter from './common/adminfooter'
import Adminsidebar from './common/adminsidebar'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
/*import CanvasJSReact from './canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;*/

class Admindashboard extends React.Component{
    state={
                test:"",
                weeklysale:"",
                weeklyorder:"",
                membercount:"",
                trans:[],
                data1:[],
        }
     componentDidMount() {
        $('link[href*="/front/css/master.css"]').attr("disabled", "true");
        $('head').append($('<link rel="stylesheet" type="text/css" />').attr('href', '/css/main.css'));
      }
    
    selectuser() {  
    this.setState({
        test : 'chatmodel',
    });
 }
  render(){
      
       

      const options3 = {
        animationEnabled: true,
        title: {
            text: "Weekly Sales"
        },
        subtitles: [{
            text: "$" +Number(this.state.weeklysale),
            verticalAlign: "center",
            fontSize: 24,
            dockInsidePlotArea: true
        }],
        data: [{
            type: "doughnut",
            showInLegend: true,
            indexLabel: "{name}: {y}",
            yValueFormatString: "",
            dataPoints: [
                { name: "Weekly Sales", y: Number(this.state.weeklysale) },
            ]
        }]
    }
    const options4 = {
        animationEnabled: true,
        title: {
            text: "Weekly order"
        },
        subtitles: [{
            text: "" +Number(this.state.weeklyorder),
            verticalAlign: "center",
            fontSize: 24,
            dockInsidePlotArea: true
        }],
        data: [{
            type: "doughnut",
            showInLegend: true,
            indexLabel: "{name}: {y}",
            yValueFormatString: "",
            dataPoints: [
                { name: "Weekly order", y: Number(this.state.weeklyorder) },
            ]
        }]
    }
    const options5 = {
        animationEnabled: true,
        title: {
            text: "Subscribe Member"
        },
        subtitles: [{
            text: "" +Number(this.state.membercount),
            verticalAlign: "center",
            fontSize: 24,
            dockInsidePlotArea: true
        }],
        data: [{
            type: "doughnut",
            showInLegend: true,
            indexLabel: "{name}: {y}",
            yValueFormatString: "",
            dataPoints: [
                { name: "Earn $", y: Number(this.state.membercount*20) },
            ]
        }]
    }
    return(
      <div>
        
        <Adminheader/>
        <Adminsidebar/>
        <ToastContainer />
        <div class="wrapper">
        <div class="main">
      
      <div class="main-content">
        <div class="container-fluid">
          <h3 class="page-title">Dashboard</h3>
          <div class="row">
            
          </div>
        </div>
      </div>
     
    </div>
    </div>
        <Adminfooter/>
        </div>
        
      )
  }
}

export default Admindashboard