import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import $ from "jquery";
//import Dropdown from 'react-multilevel-dropdown';
class Adminsidebar extends React.Component {
  doSomething() {
    // body...
  }
  render() {
    return (
      <div>
        <div class="wrapper">
          <div id="sidebar-nav" class="sidebar">
            <div class="sidebar-scroll">
              <nav>
                <ul class="nav">
                  <li>
                    <Link to="/admin/admindashboard" class="active">
                      <i class="lnr lnr-home"></i> <span>Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/universities" class="">
                      <i class="fa fa-university"></i> <span>Universities</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/hostels" class="">
                      <i class="fa fa-key"></i> <span>Hostels</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/engineers" class="">
                      <i class="fa fa-user"></i>{" "}
                      <span>Service Engineers List</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/students" class="">
                      <i class="fa fa-user"></i> <span>Students List</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/services" class="">
                      <i class="fa fa-list"></i> <span>Services List</span>
                    </Link>
                  </li>
                  {/*<li><Link to="addservice" class=""><i class="fa fa-bookmark"></i> <span>Add Services</span></Link></li>
                   <li><Link to="adminlivelession" class=""><i class="fa fa-bookmark"></i> <span>Add Live Lession</span></Link></li> 
                 <li><Link to="additem" class=""><i class="fa fa-bookmark"></i> <span>Add Item</span></Link></li>
               */}
                  <li>
                    <Link to="/admin/items" class="">
                      <i class="fa fa-list"></i> <span>Items</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/inventory" class="">
                      <i class="fa fa-list"></i> <span>Inventory </span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/admin/itemsqrcode" class="">
                      <i class="fa fa-list"></i> <span>Items QrCode</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/admin/tickets" class="">
                      <i class="fa fa-file"></i> <span>Tickets</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/settings" class="">
                      <i class="fa fa-cog"></i> <span>Settings</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*<li><Dropdown
  title='Test'
>
  <Dropdown.Item
    
  >
    Item 1
  </Dropdown.Item>
  <Dropdown.Item>
    Item 2
    <Dropdown.Submenu>
      <Dropdown.Item>
        Subitem 1
      </Dropdown.Item>
      <Dropdown.Item>
        Subitem 2
      </Dropdown.Item>
    </Dropdown.Submenu>
  </Dropdown.Item>
  <Dropdown.Item>
    Item 3
  </Dropdown.Item>
</Dropdown></li>*/
export default Adminsidebar;
