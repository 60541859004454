import React from "react";
import { Switch, Route, Redirect } from "react-router-dom"; //
//import jwt from 'jsonwebtoken'
import {
  Home,
  Loader,
  Login,
  SignUp,
  ChatList,
  Hostel,
  Qrreader,
  Assetadd,
  Qrsubmit,
  Hosteldetail,
  Privacypolicy,
  Termsconditions,
  Changepassword,
  Forgot,
  AssetDetail,
  Profile,
  CreateTicket,
  MyTicket,
  ServicesList,
  TicketDetail,
  ServicesPrice,
  Maintenance,
  Chat,
  ProfileSrv,
} from "./component";

import Resetpassword from "./component/resetpassword";

//Front end Links
//import Home from './component/Home'
import Notfound from "./component/Notfound";

//admin routes admintickets
import Adminlogin from "./component/admin/adminlogin";
import Admindashboard from "./component/admin/admindashboard";
import Adminstudents from "./component/admin/adminstudents";
import Adminitems from "./component/admin/adminitems";
import Adminservices from "./component/admin/adminservices";
import Adminengineers from "./component/admin/adminengineers";
import Adminhostels from "./component/admin/adminhostels";
import Ticketdetail from "./component/admin/adminticketdetail";
import Stddetail from "./component/admin/adminstddetail";

import Admintickets from "./component/admin/admintickets";
import Adminsettings from "./component/admin/adminsettings";

import Adminblocks from "./component/admin/adminblocks";
import Adminrooms from "./component/admin/adminrooms";
import Adminuniversity from "./component/admin/adminuniversity";
import Adminprofileedit from "./component/admin/adminprofileedit";
import Adminpassword from "./component/admin/adminpassword";
import Adminqritems from "./component/admin/adminqritems";
import jwt from "jsonwebtoken";
import AdminInventory from "./component/admin/adminInventory";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.length > 0 ? (
        jwt.verify(
          localStorage.getItem("user_id"),
          "pokemon",
          (err, decoded) => {
            if (err) {
              localStorage.clear();
              console.log("props", props);
              props.history.push("/login");
            } else {
              return decoded;
            }
          }
        ).user_id !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
//to check for login and signup
const Private = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.length > 0 ? (
        jwt.verify(
          localStorage.getItem("user_id"),
          "pokemon",
          (err, decoded) => {
            if (err) {
              localStorage.clear();
              props.history.push("/login");
            } else {
              return decoded;
            }
          }
        ).user_id !== null ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      ) : (
        <Component {...props} />
      )
    }
  />
);

class Routes extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <PrivateRoute path="/" exact component={() => <AssetDetail />} />
          <Private path="/login" exact component={() => <Login />} />
          <Private path="/signup" exact component={() => <SignUp />} />

          <Private path="/hostel" exact component={() => <Hostel />} />

          <PrivateRoute path="/loader" exact component={() => <Loader />} />
          <PrivateRoute path="/qrreader" exact component={() => <Qrreader />} />
          <PrivateRoute path="/qrsubmit" exact component={() => <Qrsubmit />} />
          <PrivateRoute
            path="/add-asset"
            exact
            component={() => <Assetadd />}
          />

          <PrivateRoute
            path="/hostel-detail"
            exact
            component={() => <Hosteldetail />}
          />
          <PrivateRoute
            path="/privacy-policy"
            exact
            component={() => <Privacypolicy />}
          />
          <PrivateRoute
            path="/terms-conditions"
            exact
            component={() => <Termsconditions />}
          />
          <PrivateRoute
            path="/change-password"
            exact
            component={() => <Changepassword />}
          />

          <Private path="/forgot" exact component={() => <Forgot />} />
          <PrivateRoute
            path="/asset-detail"
            exact
            component={() => <AssetDetail />}
          />
          <PrivateRoute path="/profile" exact component={() => <Profile />} />
          <PrivateRoute
            path="/create-ticket"
            exact
            component={() => <CreateTicket />}
          />
          <PrivateRoute
            path="/my-ticket"
            exact
            component={() => <MyTicket />}
          />
          <PrivateRoute
            path="/services-list"
            exact
            component={() => <ServicesList />}
          />
          <PrivateRoute
            path="/ticket-detail"
            component={() => <TicketDetail />}
          />
          <PrivateRoute
            path="/service-price"
            exact
            component={() => <ServicesPrice />}
          />
          <PrivateRoute
            path="/manitance"
            exact
            component={() => <Maintenance />}
          />
          <PrivateRoute path="/chat" exact component={() => <Chat />} />
          <PrivateRoute path="/chatlist" exact component={() => <ChatList />} />
          <PrivateRoute
            path="/profilesrv"
            exact
            component={() => <ProfileSrv />}
          />

          <Route
            path="/Resetpassword/:token"
            render={(props) => <Resetpassword {...props} />}
          />

          <Route
            path="/admin/admindashboard"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Admindashboard />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/students"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminstudents />
              ) : (
                <Redirect to="/admin" />
              )
          }
          />
          <Route
            path="/admin/items"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminitems />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
           <Route
            path="/admin/inventory"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <AdminInventory />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/itemsqrcode"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminqritems />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/services"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminservices />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/engineers"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminengineers />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/tickets"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Admintickets />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/settings"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminsettings />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />

          <Route
            path="/admin/hostels/:id?"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminhostels />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />

          <Route
            path="/admin/stddetail/:id"
            render={(props) =>
              localStorage.getItem("Uidadmin") ? (
                <Stddetail {...props} />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/ticketdetail/:id"
            render={(props) =>
              localStorage.getItem("Uidadmin") ? (
                <Ticketdetail {...props} />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/blocks/:id"
            render={(props) =>
              localStorage.getItem("Uidadmin") ? (
                <Adminblocks {...props} />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/rooms/:id/:bid/:fid"
            render={(props) =>
              localStorage.getItem("Uidadmin") ? (
                <Adminrooms {...props} />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/universities"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminuniversity />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/adminprofileedit"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminprofileedit />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />
          <Route
            path="/admin/chgpwd"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Adminpassword />
              ) : (
                <Redirect to="/admin" />
              )
            }
          />

          <Route
            path="/admin"
            render={() =>
              localStorage.getItem("Uidadmin") ? (
                <Redirect to="/admin/admindashboard" />
              ) : (
                <Adminlogin />
              )
            }
          />

          <Route path="" component={Notfound} />
        </Switch>
      </div>
    );
  }
}
export default Routes;
