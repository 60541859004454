import React from "react";
import Footer from "./footer";
import Header from "./header";
import config from "./admin/config.js"
class Loader extends React.Component {
     

    render() {
         
        return (
    <div class="appHdr">
        
     
    <section class="appBody wrapper">
        <div class="login-block form login">
        <div class="login-row">
        <div class="text-center">
                 <img src={config.googleAuth.backend+'basicloader.gif'} />
            </div>  
            </div>  
           
        </div>
        <Footer />
    </section>
</div>
  );
}
}
export default Loader;