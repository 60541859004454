import React from "react";
import { Link, withRouter } from "react-router-dom"; 
import jwt from 'jsonwebtoken'
import $ from 'jquery'
import config from "./admin/config.js"
import Loader from "./Loader";
import io from 'socket.io-client'
let socket=io(config.googleAuth.backend,{transports: ['websocket', 'polling', 'flashsocket']});


const axios = require("axios");
class Chat extends React.Component {
    state={
        loader:false,
        room:'',
        sender_id:'',
        user_name:'',
        user_img:'',
        chat_user_name:'',
        chat_user_img:'',
        ticket_status:'',
        user_flag:'',
        messages:[]
    }

    componentDidMount(){
        jwt.verify(localStorage.getItem("user_id"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({sender_id:decoded.user_id});                             
            }
        }) 
          

        jwt.verify(localStorage.getItem("user_name"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({user_name:decoded.user_name});                             
            }
        })

        jwt.verify(localStorage.getItem("user_img"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({user_img:decoded.user_img});                             
            }
        })

        let sessionFlag = jwt.verify(localStorage.getItem("user_flag"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                return decoded                            
            }
        }) 

        const queryParams = new URLSearchParams(window.location.search);
        const ticketno = queryParams.get('ticketno'); 
        const ticket_status = queryParams.get('ticket_status'); 
        this.setState({room:ticketno,ticket_status:ticket_status});

        //GetAllMessages
        this.getUsername(ticketno,sessionFlag.user_flag);
        this.getAllMessages(ticketno,sessionFlag.user_flag);

        socket.emit("join",{
            room:ticketno
        })



        socket.off('setUser').on('setUser', (data) =>{
             
            console.log("socket.on"); 

            if(data.room==ticketno){
                console.log("msg received",data)
                var date=new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(Date.now())
                var lefteight = (data.name==this.state.user_name)?'right':'left';
                var html = `<div class="chat outgoing ${lefteight}"><div class="details"><p>${data.msg} <br/> <small>${date}</small></p></div></div>`;
                $(".chat-box").append(html);


                var clientHeight = document.getElementsByClassName("chat-box")[0].scrollHeight;
                $(".chat-box").animate({ scrollTop: clientHeight}, 10);
            }            
        
        })


        socket.off('setImage').on('setImage', (data) =>{
            
            console.log("socket.on"); 

            if(data.room==ticketno){
                console.log("msg received",data)
                $('.chat-box').children().last().remove();


                var lefteight = (data.name==this.state.user_name)?'right':'left';
                
                var date=new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(Date.now())

                var html = `<div class="chat outgoing ${lefteight}" ><div class="details" id=div${data.id}><p><img  src=${config.googleAuth.backend}chat/${data.msg} width='100%'/>`;
                if(data.name==this.state.user_name){
                    html +=`<a href='#!' ><i class="fa fa-trash-o" id=${data.id} aria-hidden="true"></i></a>`;
                }
                
                html +=`<br /><small>${date}</small></p></div></div>`;

                $(".chat-box").append(html);

                 
                if(data.name==this.state.user_name){
                    document.getElementById(data.id).addEventListener('click',this.deleteChat,false)
                }

                //this.scrollToBottom();

                setTimeout(() => {
                        
                    this.setState({loader:false})
                    try{
                        var clientHeight = document.getElementsByClassName("chat-box")[0].scrollHeight;                        
                        $(".chat-box").animate({ scrollTop: clientHeight}, 10);
                    }catch(error){
                        console.log(error);
                    }
                    
                    
                }, 1000);
                

            }
        
        })


        socket.off('deleteImge').on('deleteImge', (data) =>{
             
            console.log("deleteImge socket.on"); 

            $("#div"+data.id).remove();           
        
        })

        

	}

    componentDidUpdate() {
        //  this.scrollToBottom();
      }
    
      scrollToBottom() {
          try{
            this.el.scrollIntoView({ behavior: 'smooth' });
          }catch(error){
              console.log(error);
          }
        
      }
     
    enterPressed = (event) =>{    
        var code = event.keyCode || event.which;
        if(code === 13) {
            this.sendButton(event);
        } 
    }

    sendButton = (e) =>{    
        var msg = $("#message").val();         
        if(msg!=''){   
            
            

            let options={
                method:"POST",
                headers:{
                Accept:"application/json",
                  "Content-Type":"application/json"
                },
                body:JSON.stringify({msg:msg, room:this.state.room, sender_name:this.state.user_name, sender_id:this.state.sender_id,date:Date.now()})
              }
              
              fetch(`${config.googleAuth.backURL}user/saveChatRequest`,options)
              .then(res=>{
          
                return res.json();
              })

              .then(data=>{
                  if(data.status == true){
                    socket.emit("setUser",{
                        msg:msg,
                        name:this.state.user_name,
                        room:this.state.room
                    })
                      console.log("Successfully Save Message!!");
                      
                  }else{
                      console.log("Ops! Message not save in database!!");
                  }
              })

              .catch(err=>{
                console.log("error",err)
              }) 


            
           // this.priyanka(this.state.room);
        }
        $("#message").val('');
    }

    

    // sendTyping=()=>{
    //     console.log("inside the typing console")
    //     socket.emit("typing",{
    //     })
    // }

    
    getUsername=(ticketno,flag)=>{
        let options={
            method:"POST",
            headers:{
            Accept:"application/json",
              "Content-Type":"application/json"
            },
            body:JSON.stringify({ticketno:ticketno,flag:flag})
          }
          
          fetch(`${config.googleAuth.backURL}user/getUsername`,options)
          .then(res=>{
            return res.json();
          })

          .then(data=>{
                if(data.status == true){
                    console.log(data);
                    this.setState({chat_user_name:data.name,chat_user_img:data.photo})
                    //this.setState({chat_user_img:data.photo})
                }else{
                  console.log("Ops! Message not save in database!!");
                }
          })

          .catch(err=>{
            console.log("error",err)
          })  

    }
    
    getAllMessages=(ticketno,flag)=>{

        let options={
            method:"POST",
            headers:{
            Accept:"application/json",
              "Content-Type":"application/json"
            },
            body:JSON.stringify({ticketno:ticketno,flag:flag})
          }
          
          fetch(`${config.googleAuth.backURL}user/getAllMessages`,options)
          .then(res=>{
            return res.json();
          })

          .then(async data=>{
                if(data.status == true){
                    this.setState({loader:true})
                    await this.setState({messages:data.data});
                    
                    setTimeout(() => {
                        
                        this.scrollToBottom();
                        this.setState({loader:false})
                        try{
                            var clientHeight = document.getElementsByClassName("chat-box")[0].scrollHeight;                        
                            $(".chat-box").animate({ scrollTop: clientHeight}, 10);
                        }catch(error){
                            console.log(error);
                        }
                        
                    }, 4000);
                    
                    console.log("set State 2");

                }else{
                  console.log("Ops! Message not save in database!!");
                }
                 
          })

          .catch(err=>{
            console.log("error",err)
          })  
    }
    

      

    onFileChange = (e) => {
        //this.setState({loader_flag:true});

        let newObj = new FormData();
        newObj.append('file', e.target.files[0]);
        newObj.append('room', this.state.room);
        newObj.append('sender_name', this.state.user_name);
        newObj.append('sender_id', this.state.sender_id);
        newObj.append('date', Date.now());

        axios.post(`${config.googleAuth.backURL}user/addAttchmentChat`,newObj)
        .then(res=>{
            console.log("response",res.data)
            if(res.data.status == true){
               // this.setState({loader_flag:false});

                        
               this.setState({loader:true})  
                socket.emit("setImage", {
                    msg:res.data.photo,
                    id:res.data.id,
                    name:this.state.user_name,
                    room:this.state.room
                })
                $("#file").val('');
               
                var html = `<div class="chat outgoing right"><div class="details" id=div${res.data.id}><p>Processing... </p></div></div>`;
                $(".chat-box").append(html); 
            }else{
                alert("Ops! Something went wrong please try again later.");
            }
            
        })
        .catch(err=>{
            console.log("error",err)
        })
    }

    deleteChat = (e) => {
       
        let id = e.target.id
        console.log('deleteId', id);
        let options={
            method:"POST",
            headers:{
            Accept:"application/json",
              "Content-Type":"application/json"
            },
            body:JSON.stringify({id:id})
          }
          
          fetch(`${config.googleAuth.backURL}user/deleteChatRequest`,options)
          .then(res=>{
            return res.json();
          })

            .then(data=>{
                //this.setState({loader_flag:false});
                console.log(data);
                if(data.status == true){
                    
                    //$("#div"+id).remove();
                    socket.emit("deleteImge", {
                        room:this.state.room,
                        id:id
                    })

                     
                }else{
                  console.log("Ops! Message not save in database!!");
                }
            })

          .catch(err=>{
            console.log("error",err)
          })  

    }


    render() {
        const fallbackSrc = config.googleAuth.backend+"user1.png";
        const imageSrc = config.googleAuth.backend+'profile/'+this.state.chat_user_img

        const loaderDiv = this.state.loader?'block':'none';
        const chatDiv = this.state.loader?'none':'block';
        return ( <>
         {/* <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={this.state.loader_flag} //3 secs
        />   */}
           
        
    <div class="appHdr">
        <header>
        <div class="main-header">
            <div class="chatHeader">
                <Link to="my-ticket" class="back-icon">
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                </Link>
                <Link to="profile">
                    <img src="images/user1.png" />
                </Link>
                <div class="details">
                    <span>{this.state.chat_user_name}</span>
                    <p></p>
                </div>

            </div>
        </div>
        </header>

       
        <section class="appBody wrapper" style={{'display':loaderDiv}}>
            <div class="login-block form login">
            <div class="login-row">
            <div class="text-center">
                    <img src={config.googleAuth.backend+'loader.gif'} width='50%'/>
                </div>  
                </div>  
            
            </div>
             
        </section>

       
        <section class="appBody chatAreaBodyOuter" style={{'display':chatDiv}}>
            <div class="chat-drop-dwn">
            <div class="src-cat-grp">
                {/* <select class="form-control" name="dpdHomeCategory" id="dpdHomeCategory">
                    <option value="">Select Issue</option>
                    <option value="Request 1">Opened</option>
                    <option value="Request 2">Work in Process </option>
                    <option value="Request 3">Closed</option>
                </select> */}
                {/* <div class="src-bars">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                </div> */}
            </div>    
             </div>   
            <div class="commonBody chatAreaBody">
                    <div class="chat-box" id='chatboxDiv' ref={el => { this.el = el; }}>
                     
                    {this.state.messages.map((msg,index)=>{ 
                        return(
                        msg.sender_id.toString() === this.state.sender_id.toString()? (  <>
                            <div class="chat outgoing right" >
                                <div class="details" id={`div${msg.id}`}>
                                {msg.message? (  <>
                                    <p>{msg.message	} 
                                    <small>{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format( msg.date)}</small></p>
                                </>) : ( 
                                
                                <>
                                    <p><img src={`${config.googleAuth.backend}chat/${msg.attachment}`}  width='100%'/>
                                    <a href='#!' ><i class="fa fa-trash-o" aria-hidden="true" id={`${msg.id}`} onClick={this.deleteChat}></i></a><br />
                                    <small>{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format( msg.date)}</small></p>
                                </>
                                    
                                )}
                                </div>
                            </div> 
                        </>
                        ) : (
                        <>
                        <div class="chat outgoing left">
                            <div class="details" id={`div${msg.id}`}>
                                

                                {msg.message? (  <>
                                    <p>{ msg.message	}<small>{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format( msg.date)}</small></p>
                                </>) : ( 
                                
                                <>
                                   <p> <img id="imgid" src={`${config.googleAuth.backend}chat/${msg.attachment}`} class='chtimg' width='100%'/>
                                    <br />
                                     <small >{new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format( msg.date)}</small></p>
                                </>
                                    
                                )}
                            </div>
                        </div>
                        </>
                        )
                    )    
                    })}
                
                      
                        
                 </div>
            </div>
            {(this.state.ticket_status!=='closed')?
            <div class="chatFtr">
                <div class="typing-area">
                    <span class="pic-add">
                         <i class="fa fa-camera" aria-hidden="true"></i>
                         <input type="file" name='file' id='file' class="cmraFile" capture="camera" accept="image/*" onChange={this.onFileChange}/>
                         
                    </span>

                   
                     

                    <div class="form-group">
                        <input class="form-control chat-text" onKeyPress={this.sendTyping} type="text" id='message' placeholder="Type message here..." onKeyPress={this.enterPressed} />
                        <i class="fa fa-paper-plane chat-msg" aria-hidden="true" onClick={this.sendButton} ></i>
                         
                        {/* <label id="typing"></label> */}

                    </div>

                </div>
            </div>
            :<center><b style={{'color':'black'}}>Chat expired for this Ticket </b></center>}
            
        </section>
         
 
    </div>
        </>
  );
}
}
export default Chat;