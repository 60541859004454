import React from "react";
import { Link, withRouter } from "react-router-dom";
import Menu from "./menu";
import "react-toastify/dist/ReactToastify.css";
import jwt from "jsonwebtoken";
import config from "./admin/config.js";

class Header extends React.Component {
  state = {
    user_name: "",
    user_img: "",
  };

  componentDidMount = (e) => {
    jwt.verify(localStorage.getItem("user_name"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_name: decoded.user_name });
      }
    });

    jwt.verify(localStorage.getItem("user_img"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_img: decoded.user_img });
      }
    });
  };
  render() {
    const fallbackSrc =  "/images/user1.png";
    const imageSrc =
      config.googleAuth.backend + "profile/" + this.state.user_img;

    return (
      <div class="main-header">
        <div class="header-inner">
          <div class="hedar-links">
            <div class="menu-bar-sec">
              <Menu />
            </div>

            <div class="login-name">
              <p>Welcome {this.state.user_name}</p>
            </div>

            <div class="profile-top">
              <Link to="profile">
                <img
                  src={imageSrc}
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = fallbackSrc;
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Header;
