import React from "react";
import Adminheader from "./common/adminheader";
import Adminfooter from "./common/adminfooter";
import Adminsidebar from "./common/adminsidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./config.js";
import { Switch, Route, Redirect, Link } from "react-router-dom";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Modal from "react-bootstrap-modal";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
const axios = require("axios");
class AdminInventory extends React.Component {
  state = {
    data: [],
    itemsList: [],
    loginflag: false,
    services: [],
    formdata: {
      id: "",
      item_id: "",
      service_id: "",
    },
    errors: [],
  };

  initialState = {
    id: "",
    item_id: "",
    service_id: "",
    price: "",
  };
  closeModal = () => this.setState({ open: false });

  openModal = async (id = 0) => {
    await this.getservices();
    await this.getitems();
    this.setState({ open: true });
    if (id > 0) {
      let k = await this.getitem(id);
    } else {
      this.setState({ formdata: this.initialState });
    }
    setTimeout(() => {
      $(".modal").css({ display: "block" });
    }, 500);
  };

  getservices = () => {
    fetch(`${config.googleAuth.backURL}admin/services`)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ services: responseJson.data });
        console.log(this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  changedata = (e) => {
    //this.setState({[e.target.name]:e.target.value.toLowerCase()})
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
  };
  componentDidMount() {
    this.getInventories();
    $(document).ready(function () {
      setTimeout(function () {
        $("#example1").DataTable();
      }, 1000);
    });
  }

  getitem = async (id) => {
    await fetch(`${config.googleAuth.backURL}admin/inventory/` + id)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ formdata: responseJson.data });
        console.log(this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getInventories = () => {
    fetch(`${config.googleAuth.backURL}admin/inventories`)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ data: responseJson.data });
        console.log(this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getitems = () => {
    fetch(`${config.googleAuth.backURL}admin/items`)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ itemsList: responseJson.data });
        console.log(this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.formdata.service_id === "") {
      //this.setState({ loginpassValid: 'Password is required*' })
      arry += "Please select any one Service* <br/>";
      error++;
    }
    if (this.state.formdata.item_id === "") {
      //this.setState({ loginpassValid: 'Password is required*' })
      arry += "Item  can not be empty* <br/>";
      error++;
    }

    console.log(error);

    //this.setState({ errors: arry })
    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.formdata),
      };
      fetch(`${config.googleAuth.backURL}admin/addInventory`, options) //
        .then((res) => {
          //console.log("response",res)
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            this.getInventories();
            data.code == 500
              ? toast("Inventory updated successfully!!")
              : toast("Inventory added successfully!!");
            this.setState({ formdata: this.initialState });
            setTimeout(() => {
              $(".modal").css({ display: "none" });
            }, 10);
          } else {
            toast("Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  delhost = (id) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    };
    fetch(`${config.googleAuth.backURL}admin/delInventory`, options)
      .then((res) => {
        //console.log("response",res)
        return res.json();
      })
      .then((data) => {
        if (data.status == true) {
          this.getInventories();
          toast("Item deleted successfully!!");
          this.setState(this);
        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  delUni = (id) => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => (
        <div className="custom-ui">
          <h3>Are you sure?</h3>
          <p>You want to delete this?</p>
          <button onClick={onClose}>No</button>
          <button
            onClick={() => {
              this.delhost(id);
              onClose();
            }}
          >
            Yes, Delete it!
          </button>
        </div>
      ),
    });
  };

  // downloadQR = async (id) => {
  //   const qrCodeURL = document.getElementById(id);

  //   const canvas = await html2canvas(qrCodeURL);

  //   // Find the non-empty bounding box of the content
  //   const scaledWidth = canvas.width * 3;
  //   const scaledHeight = canvas.height * 3;

  //   const scaledCanvas = document.createElement("canvas");
  //   scaledCanvas.width = scaledWidth;
  //   scaledCanvas.height = scaledHeight;
  //   const ctx2 = scaledCanvas.getContext("2d");
  //   ctx2.imageSmoothingEnabled = false;
  //   // Scale and draw the original content onto the scaled canvas
  //   ctx2.drawImage(canvas, 0, 0, scaledWidth, scaledHeight);

  //   // Convert the scaled canvas to a PNG
  //   const imgData = scaledCanvas.toDataURL("image/png");

  //   // Create a download link and trigger the download
  //   const a = document.createElement("a");
  //   a.href = imgData;
  //   a.download = `${id}.png`;
  //   a.click();
  // };
  downloadQR = async (id) => {
    const qrCodeURL = document.getElementById(id);
    //   const node = document.getElementById(this.props.id);

    htmlToImage
      .toPng(qrCodeURL, { pixelRatio: 2 })
      .then((dataUrl) => {
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = `${id}.png`;
        a.click();
      })
      .catch((error) => {
        console.error("Error capturing screenshot:", error);
      });
  };
  render() {
    var i = 1;
    return (
      <div>
        <Adminheader />
        <Adminsidebar />
        <ToastContainer />
        <div class="wrapper">
          <div class="main">
            <div class="main-content">
              <div class="container-fluid">
                <h3 class="page-title">Inventory List</h3>
                <button
                  onClick={this.openModal}
                  class="pull-right btn btn-success"
                >
                  Add Inventory
                </button>
                <div class="row">
                  <div class="col-md-12">
                    <div class="panel">
                      <div class="panel-body">
                        <table class="table" id="example1">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th> Inventory name</th>
                              <th>Room</th>
                              <th>Qr Code</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data
                              ? this.state.data.map((l) => {
                                  return (
                                    <tr>
                                      <td>{i++}</td>

                                      <td>{l.name}</td>
                                      <td>
                                        <div>
                                          Room : {l.roomName} <br/>
                                          floor : {l.floor} <br/>
                                          Block : {l.block} <br/>
                                          Hostel : {l.hostelName} <br/>
                                          University : {l.uniName} <br/>
                                        </div>
                                      </td>
                                      <td>
                                        <p style={{ fontWeight: 600 }}>
                                          {l.qrcodenew}
                                        </p>
                                        <div
                                          id={"qrCode" + l.id}
                                          style={{
                                            width: "fit-content",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "10px",
                                            background:"white"
                                          }}
                                        >
                                          <QRCode
                                            value={JSON.stringify(l)}
                                            size={120}
                                            level="L"
                                            includeMargin={true}
                                          />
                                        </div>
                                        <a
                                          href="#!"
                                          onClick={() => {
                                            this.downloadQR("qrCode" + l.id);
                                          }}
                                        >
                                          {" "}
                                          Download QR{" "}
                                        </a>
                                      </td>
                                      <td>
                                        {/*<Link to={'/admin/hostels/'+l.id} class="btn btn-success"><i class="fa fa-plus"></i> Hostels</Link> &nbsp;&nbsp;                                               */}
                                        {/* <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.openModal(l.id);
                                          }}
                                          class="btn btn-success"
                                        >
                                          <i class="fa fa-pencil"></i>
                                        </a> */}
                                        &nbsp;&nbsp;
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.delUni(l.id);
                                          }}
                                          class="btn btn-success"
                                        >
                                          <i class="fa fa-trash"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Adminfooter />

        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id="shareModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              {this.state.formdata.item_id
                ? " Update Inventory"
                : "Add Inventory"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p id="error"></p>
            <form onSubmit={this.mySubmit}>
              <select
                class="form-control"
                name="service_id"
                value={this.state.formdata.service_id}
                onChange={this.changedata}
              >
                <option value="">-- Select Service ---</option>
                {this.state.services &&
                this.state.services &&
                this.state.services.length > 0
                  ? this.state.services.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                  : ""}
              </select>
              <br />
              <select
                class="form-control"
                name="item_id"
                value={this.state.formdata.item_id}
                onChange={this.changedata}
              >
                <option value="">-- Select item ---</option>
                {this.state.itemsList &&
                this.state.itemsList &&
                this.state.itemsList.length > 0
                  ? this.state.itemsList.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                  : ""}
              </select>
              {/* <input
                class="form-control"
                placeholder="Item Name"
                onChange={this.changedata}
                value={this.state.formdata.name}
                name="name"
                type="text"
              /> */}

              <br />

              <button type="submit" class="btn btn-success">
                Submit
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AdminInventory;
