import React from "react";
import { Link, withRouter } from "react-router-dom";
import Menu from "./menu";


function Maintenance () {
  return (
    <div class="appHdr">
        <header>
            <div class="main-header">
                 <div class="header-inner">
                    <div class="hedar-links">
                         <div class="menu-bar-sec">
                                <Menu />
                        </div>
                        <div class="login-name">
                              <p>Welcome Sandeep</p>
                        </div>  
                        <div class="profile-top">
                            <Link to="profile"> 
                                <img src="images/user1.png"/>
                            </Link>   
                        </div>    
                     </div>       
                </div>    
            </div>
        </header>
        <section class="appBody wrapper">
            <div class="login-block form login">
            <div class="login-row">
                <div class="main-heading text-center">
                    <h2>Preventive Maintenance</h2>	
                </div>
                <form>
                    <div class="login-form common-form">  
                        <div class="field input form-group">
                            <label>Asset ID </label>
                            <input class="form-control" type="text" name="email" placeholder="123AACCDDl" required=""/>
                            <Link class="qr-icn" to="/">		  
                                <i class="fa fa-qrcode" aria-hidden="true"></i>
                            </Link>	
                        </div>
                        <div class="field input form-group">
                            <label>PM SE</label>
                            <input class="form-control" type="text" name="password" placeholder="Sandeep Parsad" required="" />
                        </div>
                        <div class="field input form-group">
                            <label>Condition </label>
                            <div class="src-cat-grp">
                                <select class="form-control" name="dpdHomeCategory" id="dpdHomeCategory">
                                    <option value="">Choose</option>
                                    <option value="Service 1">Repairable</option>
                                    <option value="Service 2">Unrepairable </option>
                                </select>
                                <div class="src-bars">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div class="field input form-group">
                            <label>Short Discription</label>
                            <input class="form-control" type="text" name="password" placeholder="Enter your password" required="" />
                        </div>
                        <div class="field input form-group">
                            <label>Work notes</label>
                            <textarea class="form-control" type="text" placeholder="Notes..." id="message" name="message"></textarea>
                        </div>
                        <div class="upload-section">
                            <label class="upload-image" for="upload-image-one">
                                 <input type="file" id="upload-image-one"/>
                            </label>
                            <span class="add-more">
                                 <i class="fa fa-camera" aria-hidden="true"></i>
                                  Upload Image
                            </span>  
                        </div>
                        <div class="attach-files">
                            <ul>
                                <li>
                                    <img src="images/placeholder.jpg" />
                                    <Link class="fa fa-close file-remove" to="/"></Link>
                                </li>
                                <li>
                                    <img src="images/placeholder.jpg" />
                                    <Link class="fa fa-close file-remove" to="/"></Link>
                                </li>
                            </ul>
                        </div>
                        <div class="field form-btn button mt-40">
                            <input class="btn btn-common btn-blue btn-round btn-big" type="submit" name="submit" value="Update" />
                        </div>
                    </div>
                </form>
              </div>  
            </div>
            <div class="footer-menu">
            <ul>  
                <li class="menu-ic">
                    <Link class="ftr-icn" to="chatlist">		  
                        <i class="fa fa-comments" aria-hidden="true"></i>
                        <span>Chat</span>
                    </Link>		 
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn" to="my-ticket">		  
                        <i class="fa fa-list" aria-hidden="true"></i>
                        <span>List</span>
                    </Link>	   
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn active" to="asset-detail">
                        <i class="fa fa-home" aria-hidden="true"></i>
                        <span>Home</span>
                    </Link>		  
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn" to="create-ticket">		  
                        <i class="fa fa-ticket" aria-hidden="true"></i>
                        <span>Ticket</span>
                    </Link>	   
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn" to="services-list">		  
                        <i class="fa fa-anchor" aria-hidden="true"></i>
                        <span>Services</span>
                    </Link>	   
                </li>
            </ul>
                </div>
        </section>
    </div>
  );
}

export default Maintenance;