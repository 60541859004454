import React from "react";
import { Link, withRouter } from "react-router-dom";
import Menu from "./menu";
import Footer from "./footer";
import Header from "./header";
import jwt from "jsonwebtoken";
import config from "./admin/config.js";
import helper from "../helper.js";
import { GlobalStoreContext } from "../ContextAPI/MyContext";

class AssetDetail extends React.Component {
  static contextType = GlobalStoreContext;
  state = {
    user_name: "",
    user_flag: "",
    user_id: "",
    mainID: "",
    userInfoData: [],
    ticket: [],
    AssetDetail: [],
  };

  componentDidMount = async (e) => {
    const { updateData } = this.context;
    updateData(null)
    jwt.verify(localStorage.getItem("user_name"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_name: decoded.user_name });
      }
    });
    jwt.verify(localStorage.getItem("user_flag"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_flag: decoded.user_flag });
      }
    });

    let sessionFlag = jwt.verify(
      localStorage.getItem("user_flag"),
      "pokemon",
      (err, decoded) => {
        if (err) {
          localStorage.clear();
        } else {
          return decoded;
        }
      }
    );

    let sessionMainID = jwt.verify(
      localStorage.getItem("mainID"),
      "pokemon",
      (err, decoded) => {
        if (err) {
          localStorage.clear();
        } else {
          return decoded;
        }
      }
    );

    jwt.verify(localStorage.getItem("user_id"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_id: decoded.user_id });
        if (sessionFlag.user_flag === "student") {
          this.getAssetDetail(decoded.user_id);
        }
        const getStdidValue = this.getStdId();

        this.getUserInfo(decoded.user_id, sessionFlag.user_flag);

        getStdidValue.then((res) =>
          this.getOneTicket(res, sessionFlag.user_flag)
        );
      }
    });
  };
  getStdId() {
    return Promise.resolve().then(function () {
      return localStorage.getItem("stdId");
    });
  }

  getOneTicket = (stdId, stdFlag) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stdId: stdId, stdFlag: stdFlag }),
    };
    fetch(`${config.googleAuth.backURL}user/getOneTicket`, options)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        if (data.status == true) {
          console.log(data.data);
          this.setState({ ticket: data.data });
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  getUserInfo = (stdId, stdFlag) => {
    localStorage.setItem("stdId", stdId);
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stdId: stdId, stdFlag: stdFlag }),
    };
    fetch(`${config.googleAuth.backURL}user/getUserInfo`, options)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        if (data.status == true) {
          this.setState({ userInfoData: data.data[0] });
          console.log(this.state.userInfoData);
        } else {
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  getAssetDetail = (stdId) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ student_id: stdId }),
    };
    fetch(`${config.googleAuth.backURL}user/getAssetDetail`, options)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        console.log(data.data);
        if (data.status == true) {
          this.setState({ AssetDetail: data.data });
        } else {
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  render() {
    return (
      <div class="appHdr">
        <header>
          <Header />
        </header>
        <section class="appBody wrapper">
          <div class="login-block form login">
            <div class="login-row">
              {this.state.user_flag === "student" ? (
                <>
                  <div class="asst-inr-dis">
                    <div class="main-heading text-left mr-30">
                      <h2>Asset Details</h2>
                    </div>
                    <div class="asset-dis">
                      <ul>
                        {this.state.AssetDetail.map((asset, index) => {
                          return (
                            <li>
                              <b>Asset {index + 1}:</b> {asset.itemName} : {asset.asset_id}
                            </li>
                          );
                        })}
                      </ul>
                      <Link class="add-nw" to="add-asset">
                        <span class="chat-icn ">Add New</span>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div class="asst-inr-dis">
                <div class="main-heading text-left">
                  <h2>Details</h2>
                </div>
                <div class="asset-dis">
                  <ul>
                    <li>
                      <b>Email ID:</b> {this.state.userInfoData["email"]}
                    </li>
                    <li>
                      <b>Full Name:</b> {this.state.userInfoData["fullname"]}
                    </li>
                    {this.state.userInfoData["student_id"] ? (
                      <>
                        <li>
                          <b>Student ID:</b>{" "}
                          {this.state.userInfoData["student_id"]}
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <b>Engineer ID:</b>{" "}
                          {this.state.userInfoData["eng_id"]}
                        </li>
                      </>
                    )}

                    <li>
                      <b>Mobile:</b> {this.state.userInfoData["mobile"]}
                    </li>
                    {this.state.userInfoData["flag"]==="SE" && (
                      <li>
                        <b>Location:</b> {this.state.userInfoData["location"]}
                      </li>
                    )}
                    {this.state.userInfoData["flag"]==="UR" && (
                      <li>
                        <b>University:</b> {this.state.userInfoData["uniName"]}
                      </li>
                    )}
                    {/* {this.state.userInfoData["flag"]==="UR" && (
                      <li>
                        <b>Hostel:</b> {this.state.userInfoData["hostelName"]}
                      </li>
                    )} */}
                  </ul>
                </div>
              </div>

              <div class="asst-inr-dis">
                <div class="main-heading text-left">
                  <h2>Tickets</h2>
                </div>

                {this.state.ticket.length > 0 ? (
                  <>
                    <div class="asset-dips ticket-staus">
                      <Link  to={"ticket-detail?id=" + this.state.ticket[0].id}>
                        <h4 class="tct-hd">{this.state.ticket[0].ticket_no}</h4>
                      </Link>
                      <p>{this.state.ticket[0].short_desc}</p>
                      <ul class="ticket-time">
                        <Link>
                          {this.state.ticket[0].ticket_status === "open" ? (
                            <>
                              <li>
                                <span class="tct-open">
                                  {this.state.ticket[0].ticket_status}
                                </span>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.state.ticket[0].ticket_status === "wip" ? (
                            <>
                              <li>
                                <span class="tct-pro">
                                  {this.state.ticket[0].ticket_status}
                                </span>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.state.ticket[0].ticket_status === "closed" ? (
                            <>
                              <li>
                                <span class="tct-close">
                                  {this.state.ticket[0].ticket_status}
                                </span>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </Link>
                        <li>
                          {helper.dateDiffence(
                            this.state.ticket[0].open_date,
                            Date.now()
                          )}{" "}
                          {helper.dateDiffence(
                            this.state.ticket[0].open_date,
                            Date.now()
                          ) <= 1
                            ? "Day"
                            : "Days"}{" "}
                        </li>

                        {(this.state.ticket[0].ticket_status === "open" &&
                          this.state.ticket[0].eng_id != 0) ||
                        (this.state.ticket[0].ticket_status !== "wip" &&
                          this.state.ticket[0].eng_id != 0) ? (
                          <>
                            <li>
                              <Link
                                to={`chat?ticketno=${this.state.ticket[0].ticket_no}&ticket_status=${this.state.ticket[0].ticket_status}`}
                              >
                                <span class="chat-icn">
                                  <i
                                    class="fa fa-comments"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {this.state.ticket_status}
                                </span>
                              </Link>
                            </li>
                          </>
                        ) : this.state.ticket[0].ticket_status !== "closed" ? (
                          <li>
                            <span class="tct-close">Waiting for approval</span>
                          </li>
                        ) : (
                          <li></li>
                        )}
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div class="asset-dis ticket-staus">
                      <p>Empty</p>
                    </div>
                  </>
                )}
              </div>

              {this.state.ticket.length > 0 ? (
                <>
                  <div class="field form-btn button mt-40">
                    <Link
                      to="/my-ticket"
                      class="btn btn-common btn-blue btn-round btn-big"
                      to="/my-ticket"
                    >
                      {" "}
                      View More{" "}
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Footer />
        </section>
      </div>
    );
  }
}
export default withRouter(AssetDetail);
