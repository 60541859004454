import React from "react";
import Adminheader from "./common/adminheader";
import Adminfooter from "./common/adminfooter";
import Adminsidebar from "./common/adminsidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./config.js";
import { Link } from "react-router-dom";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Modal from "react-bootstrap-modal";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DownloadIcon from "./downloadIcon";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment/moment";
class Admintickets extends React.Component {
  state = {
    engineer: [],
    services: [],
    data: [],
    loginflag: false,
    id: "",
    eng_id: "",
    service_id: "",
    datefrom: "",
    dateto: "",
    admin_status: "",
    work_note: "",
    statusModal: false,
  };

  initialState = {
    id: "",
    eng_id: "",
    service_id: "",
    datefrom: "",
    dateto: "",
  };

  changedata = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTicketStatus = (e) => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ title, message, onClose }) => (
        <div className="custom-ui">
          <h4 className="m-0 text-primary">Are you sure?</h4>
          <hr className="my-2"></hr>
          <p>You want to change the status?</p>
          <button className="btn btn-warning mr-2" onClick={onClose}>
            No
          </button>
          <button
            className="btn btn-primary"
            onClick={(e) => {
              this.changeStatus(e);
              onClose();
            }}
          >
            Yes!
          </button>
        </div>
      ),
      overlayClassName: "overlay-custom-class-name",
    });
  };

  changeStatus = (e) => {
    e.preventDefault();

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.state.id,
        admin_status: this.state.admin_status,
        work_note: this.state.work_note,
      }),
    };
    fetch(`${config.googleAuth.backURL}admin/updateTicketStatus`, options)
      .then((res) => {
        //console.log("response",res)
        return res.json();
      })
      .then((data) => {
        if (data.status == true) {
          this.getalltickets();
          this.closeModal();
          toast("updated successfully!!");
          this.setState(this);
        } else {
          toast(data.message || "Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  changedatedata = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    this.getalltickets();
  }
  getalltickets = (e) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: "0" }),
    };
    fetch(config.googleAuth.backURL + "admin/getalltickets", options)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          this.setState({ data: responseJson.data });
        }

        // console.log("shweta", this.state);
      })
      .catch((error) => {
        console.error(error);
      });
    $(document).ready(function () {
      setTimeout(function () {
        $(".example1").DataTable();
      }, 1000);
    });
  };

  closeModal = () => this.setState({ open: false, statusModal: false });

  openModal = async (id = 0, eng_id = 0, service_id = 0) => {
    this.setState({ open: true });
    this.setState({ id: id });
    this.setState({ service_id: service_id });
    this.setState({ eng_id: eng_id });

    this.getEngineers();
    this.getServices(); //services

    setTimeout(() => {
      $(".modal").css({ display: "block" });
    }, 500);
  };

  getEngineers = () => {
    fetch(config.googleAuth.backURL + "admin/getallEngineers1")
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          this.setState({ engineer: responseJson.data });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getServices = () => {
    fetch(config.googleAuth.backURL + "admin/services")
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          this.setState({ services: responseJson.data });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.eng_id === "") {
      arry += "Engineer can not be empty* <br/>";
      error++;
    }
    if (this.state.service_id === "") {
      arry += "Service can not be empty* <br/>";
      error++;
    }

    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");

      var id = this.state.id;
      var eng_id = this.state.eng_id;
      var service_id = this.state.service_id;

      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          eng_id: eng_id,
          service_id: service_id,
        }),
      };

      fetch(`${config.googleAuth.backURL}admin/assignEnginnerTicket`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            toast("Assigned successfully!!");
            this.setState({ formdata: this.initialState, open: false });
            this.getalltickets();
          } else {
            toast("Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  mySubmitdate = (e) => {
    e.preventDefault();

    var datefrom = this.state.datefrom;
    var dateto = this.state.dateto;
    // console.log ((new Date(datefrom)).getTime ());
    var datefromnew = new Date(datefrom).getTime();
    var datetimenew = new Date(dateto).getTime();

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        datefrom: datefromnew,
        dateto: datetimenew,
      }),
    };

    fetch(`${config.googleAuth.backURL}admin/getticketsacctodate`, options)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.status == true) {
          this.setState({ data: data.data });
        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        //window.location.reload();
        console.log("error", err);
      });
  };

  exportToXLS = () => {
    try {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      var data = this.state.data.map((obj) => {
        const newObj = {
          ...obj,
          open_date: Number(obj.open_date)
            ? moment(Number(obj.open_date)).format("lll")
            : "",
          close_date: Number(obj.insertdate)
            ? moment(Number(obj.insertdate)).format("lll")
            : "",
        };
        delete newObj.insertdate
        delete newObj.stuId;
        delete newObj.created;
        return newObj;
      });
      console.log("first", data);
      // Create a new workbook
      const ws = XLSX.utils.json_to_sheet(data);
      const keys = Object.keys(data[0]);

      // Capitalize and replace underscores with blank spaces
      const formattedKeys = keys.map((key) =>
        key.replace(/_/g, " ").toUpperCase()
      );
      const customHeaders = formattedKeys;
      XLSX.utils.sheet_add_aoa(ws, [customHeaders], { origin: "A1" });

      // Style the header row
      const headerCellStyle = {
        font: { bold: true },
        fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFFF00" } },
      };

      // Apply the style to each header cell
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        ws[cellAddress].s = headerCellStyle;
      }

      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const newdata = new Blob([excelBuffer], { type: fileType });
      saveAs(newdata, "TicketList" + fileExtension);
    } catch (e) {
      console.log(e?.message);
    }
  };

  render() {
    var i = 1;
    console.log(this.state, "Dss");
    return (
      <div>
        <Adminheader />
        <Adminsidebar />
        <ToastContainer />
        <div class="wrapper">
          <div class="main">
            <div class="main-content">
              <div class="container-fluid">
                <h3 class="page-title">Tickets List</h3>
                <div class="row">
                  <div class="col-md-12">
                    <div class="panel"></div>
                    <div class="panel">
                      <div class="panel-body">
                        {/* <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button"
                          table="table-to-xls"
                          filename="test"
                          sheet="tablexls"
                          buttonText="Download as XLS"
                        /> */}
                        <div
                          onClick={this.exportToXLS}
                          class="btn btn-success align-items-center d-flex"
                        >
                          Download as XLS{" "}
                          <span style={{ marginBottom: "-5px" }}>
                            <DownloadIcon />
                          </span>
                        </div>
                        <h1>Selected Date</h1>
                        <form onSubmit={this.mySubmitdate}>
                          <div class="row">
                            <div class="col-md-3">
                              <input
                                type="date"
                                name="datefrom"
                                class="form-control"
                                required
                                onChange={this.changedatedata}
                              />
                            </div>
                            <div class="col-md-3">
                              <input
                                type="date"
                                name="dateto"
                                class="form-control"
                                required
                                onChange={this.changedatedata}
                              />
                            </div>

                            <div class="col-md-3">
                              <input type="submit" value="Submit" />
                            </div>
                          </div>
                        </form>

                        <table className="table example1" id="table-to-xls">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Ticket Number</th>
                              <th>Open Date</th>
                              <th>Asset ID</th>
                              <th>Student Id</th>
                              <th>priority</th>
                              <th>Service Engineer</th>
                              <th>Location</th>
                              {/* <th>Notes</th> */}
                              <th>Ticket Status</th>
                              <th>Verify Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.map((l) => {
                              return (
                                <tr>
                                  <td>{i++}</td>

                                  <td>{l.ticket_no}</td>
                                  <td>
                                    {new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    }).format(l.open_date)}
                                  </td>

                                  <td>{l.asset_id}</td>
                                  <td>{l.student_id}</td>
                                  <td>{l.priority}</td>
                                  <td>{l.engName}</td>
                                  <td>{l.engLocation}</td>
                                  {/* <td>{l.work_note}</td> */}
                                  <td>
                                    {![null, ""].includes(l.ticket_status) ? (
                                      <div
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {" "}
                                        {l.ticket_status}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {["closed"].includes(l.ticket_status) ||
                                    ["reopened"].includes(l.admin_status) ? (
                                      <button
                                        style={{ textTransform: "capitalize" }}
                                        disabled
                                        // onClick={(e) => {
                                        //   this.setState({
                                        //     statusModal: true,
                                        //     admin_status:l.admin_status
                                        //   });
                                        //   this.openModal(
                                        //     l.id,
                                        //     l.eng_id_fk,
                                        //     l.service_id
                                        //   );
                                        // }}
                                      >
                                        {l.admin_status
                                          ? l.admin_status
                                          : "Not verified"}
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </td>

                                  <td style={{ "white-space": "nowrap" }}>
                                    {!l.engName ? (
                                      <>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.openModal(
                                              l.id,
                                              l.eng_id_fk,
                                              l.service_id
                                            );
                                          }}
                                          class="btn btn-success"
                                        >
                                          <i class="fa fa-plus"></i>
                                        </a>
                                        &nbsp;&nbsp;
                                      </>
                                    ) : (
                                      <>
                                        <a
                                          href="javascript:void(0)"
                                          disabled
                                          class="btn btn-success"
                                        >
                                          <i class="fa fa-plus"></i>
                                        </a>
                                        &nbsp;&nbsp;
                                      </>
                                    )}

                                    <Link
                                      to={"/admin/ticketdetail/" + l.id}
                                      class="btn btn-success"
                                    >
                                      <i class="fa fa-eye"></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Adminfooter />

        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id="shareModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="ModalHeader">
              {this.state.statusModal
                ? "Update ticket status"
                : "Assign Service Engineer"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p id="error"></p>

            {this.state.statusModal ? (
              <form onSubmit={this.handleTicketStatus}>
                <select
                  class="form-control"
                  name="admin_status"
                  value={this.state.admin_status}
                  onChange={this.changedata}
                  required
                  // onChange={
                  //      onChange={this.changedata}
                  //   (e)=>{
                  //   // console.log({[e.target.name]:e.target.value},"EEEEE")
                  //   const admin_status=e.target.value
                  //   this.handleTicketStatus(l.id,admin_status)
                  // }}
                >
                  <option value="">-- Select status ---</option>
                  {[
                    { label: "Reopened", value: "reopened" },
                    {
                      label: "Verified",
                      value: "verified",
                    },
                  ].map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
                <br />

                <div class="field input form-group">
                  <label>Work notes (Optional)</label>
                  <textarea
                    class="form-control"
                    type="text"
                    placeholder="Notes..."
                    onChange={this.changedata}
                    id="work_note"
                    name="work_note"
                  ></textarea>
                </div>
                <br />

                <button type="submit" class="btn btn-success">
                  Submit
                </button>
              </form>
            ) : (
              <form onSubmit={this.mySubmit}>
                <select
                  class="form-control"
                  name="eng_id"
                  value={this.state.eng_id}
                  onChange={this.changedata}
                >
                  <option value="">-- Select Service Engineer ---</option>
                  {this.state.engineer &&
                  this.state.engineer &&
                  this.state.engineer.length > 0
                    ? this.state.engineer.map((item) => (
                        <option value={item.eng_id_fk}>{item.fullname}</option>
                      ))
                    : ""}
                </select>
                <br />

                <select
                  class="form-control"
                  name="service_id"
                  value={this.state.service_id}
                  onChange={this.changedata}
                >
                  <option value="">-- Select Service ---</option>
                  {this.state.services &&
                  this.state.services &&
                  this.state.services.length > 0
                    ? this.state.services.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))
                    : ""}
                </select>
                <br />

                <button type="submit" class="btn btn-success">
                  Submit
                </button>
              </form>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Admintickets;
