import React from "react";
import { Link, withRouter } from "react-router-dom";
import Menu from "./menu";

function ServicesPrice() {
  return (
    <div class="appHdr">
        <header>
            <div class="main-header">
            <div class="header-inner">
                    <div class="hedar-links">
                         <div class="menu-bar-sec">
                                <Menu />
                        </div>
                        <div class="login-name">
                              <p>Welcome Sandeep</p>
                        </div>  
                        <div class="profile-top">
                            <Link to="profile"> 
                                <img src="images/user1.png"/>
                            </Link>   
                        </div>    
                     </div>       
                </div>      
            </div>
        </header>
        <section class="appBody wrapper">
            <div class="login-block form login">
                <div class="services-blk">
                    <div class="main-heading text-center mb-50">
                        <h2>Service Price</h2>	
                    </div>    
                    <div class="serv-prc-lst">
                        <div class="serv-rate">
                            <span class="price-icn">
                                <img src="images/user1.png"/>
                            </span>
                            <h4>Handle Repair</h4>
                            <span class="price-srv">Price: 100RM</span>
                        </div>    
                    </div>   
                    <div class="serv-prc-lst">
                        <div class="serv-rate">
                            <span class="price-icn">
                                <img src="images/user1.png"/>
                            </span>
                            <h4>Chair Repair</h4>
                            <span class="price-srv">Price: 250RM</span>
                        </div>    
                    </div>    
                    <div class="serv-prc-lst">
                        <div class="serv-rate">
                            <span class="price-icn">
                                <img src="images/user1.png"/>
                            </span>
                            <h4>Table Repair</h4>
                            <span class="price-srv">Price: 335RM</span>
                        </div>    
                    </div>    
                    <div class="serv-prc-lst">
                        <div class="serv-rate">
                            <span class="price-icn">
                                <img src="images/user1.png"/>
                            </span>
                            <h4>Handle  Repair</h4>
                            <span class="price-srv">Price: 110RM</span>
                        </div>    
                    </div>    
                    <div class="serv-prc-lst">
                        <div class="serv-rate">
                            <span class="price-icn">
                                <img src="images/user1.png"/>
                            </span>
                            <h4>Handle Repair</h4>
                            <span class="price-srv">Price: 90RM</span>
                        </div>    
                    </div>     
                </div>   
            </div>
            <div class="footer-menu">
            <ul>  
                <li class="menu-ic">
                    <Link class="ftr-icn" to="chatlist">		  
                        <i class="fa fa-comments" aria-hidden="true"></i>
                        <span>Chat</span>
                    </Link>		 
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn" to="my-ticket">		  
                        <i class="fa fa-list" aria-hidden="true"></i>
                        <span>List</span>
                    </Link>	   
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn active" to="asset-detail">
                        <i class="fa fa-home" aria-hidden="true"></i>
                        <span>Home</span>
                    </Link>		  
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn" to="create-ticket">		  
                        <i class="fa fa-ticket" aria-hidden="true"></i>
                        <span>Ticket</span>
                    </Link>	   
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn" to="services-list">		  
                        <i class="fa fa-anchor" aria-hidden="true"></i>
                        <span>Services</span>
                    </Link>	   
                </li>
            </ul>
                </div>
        </section>
    </div>
  );
}

export default ServicesPrice;