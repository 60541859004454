import React from 'react'

class Home extends React.Component{

  state={
    username:"",
    data:[],
     visible : false,
    visible2: false,
    visible3: false,
    NAME: "",
    EMAIL: "",
    PASSWORD: "",
    LOGINEMAIL: "",
    LOGINPASSWORD: "",
    loginemailValid: "",
    loginpassValid: "",
    loginflag: false,
    userstatus: localStorage.getItem("status"),
  }
  componentDidMount(){
    
        
  }
  
  render(){
   
    return(
      <div>
      <h2>404 Page Not Found!!</h2>
      
  </div>
      )
  }
}

export default Home