// GlobalStoreContext.js

import React, { createContext, Component } from "react";

const GlobalStoreContext = createContext();

class GlobalStoreProvider extends Component {
  state = {
    // Define your global state here
    assetList: null,
  };

  // Define methods to modify the global state
  updateData = (value) => {
    console.log(value,"UPDAEDATA")
    this.setState({
        assetList: value,
      }
  )
  };

 

  render() {
    return (
      <GlobalStoreContext.Provider
        value={{
          myData: this.state.assetList,
          updateData: this.updateData,
        }}
      >
        {this.props.children}
      </GlobalStoreContext.Provider>
    );
  }
}

export { GlobalStoreProvider, GlobalStoreContext };
