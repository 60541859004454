module.exports = {
  googleAuth: {
    clientID:
      "127430973269-sroinqafd4ab6ujek55g22b5g5875orb.apps.googleusercontent.com",
    clientSecret: "6lRYAnWM1aSHp6VjdQeRKxJu",
    callbackURL: "https://192.168.1.10:3000/",
    backURL: "https://jurukincms.com:9092/api/",
    backend: "https://jurukincms.com:9092/",
    // backURL: "http://localhost:9092/api/",
    // backend: "http://localhost:9092/",
  },

  fbauth: {
    appId: "2304012543172252",
  },
  password_encrypt: {
    hash: "md5",
  },
  login_secret: {
    key: "pokemon",
  },

  login_secretadmin: {
    key: "pokemonlove",
  },
};
