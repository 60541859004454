import React from 'react'
import Adminheader from './common/adminheader'
import Adminfooter from './common/adminfooter'
import Adminsidebar from './common/adminsidebar'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "./config.js"
import { Link } from 'react-router-dom';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Modal from 'react-bootstrap-modal'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class Adminblocks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loginflag: false,
      hostel: [],
      formdata: {
        id: "",
        name: "",
        hostel_id: this.props.match.params.id,
        block_id: 0
      },
      errors: []
    }

    this.initialState = {
      id: "",
      name: "",
      hostel_id: this.props.match.params.id,
      block_id: 0
    };

  }
  closeModal = () => {
    this.setState({ open: false })
    this.setState({ formdata: this.initialState });
  }

  openModal = async (id = 0, f = 0) => {
    this.setState({ open: true })
    this.setState({ formdata: this.initialState });
    console.log(id)
    if (id > 0) {
      await this.getBlock(id)
    } else {
      this.setState({ formdata: this.initialState });
    }

    if (f > 0) {
      this.setState({ formdata: { ...this.state.formdata, name: "" } });
      this.setState({ formdata: { ...this.state.formdata, id: "" } });
      this.setState({ formdata: { ...this.state.formdata, block_id: id } });
    }

    console.log(this.state.formdata)

    setTimeout(() => { $('.modal').css({ 'display': 'block' }); }, 500)
  }

  changedata = (e) => {
    //this.setState({[e.target.name]:e.target.value.toLowerCase()}) 
    this.setState({ formdata: { ...this.state.formdata, [e.target.name]: e.target.value } });
  }
  componentDidMount() {
    this.getBlocks();
    this.gethostel();
    /*$(document).ready(function () {
      setTimeout(function () {
        $('#example1').DataTable();
      }, 1000);
    });*/
  }

  gethostel = async (id) => {
    await fetch(`${config.googleAuth.backURL}admin/hostel/` + this.props.match.params.id)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ hostel: responseJson.data, })
        //console.log(this.state)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getBlock = async (id) => {
    await fetch(`${config.googleAuth.backURL}admin/block/` + id)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ formdata: responseJson.data, })
        //console.log(this.state)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getBlocks = () => {
    fetch(`${config.googleAuth.backURL}admin/blocks/` + this.props.match.params.id)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ data: responseJson.data, })
        console.log(this.state)
      })
      .catch((error) => {
        console.error(error);
      });
  }


  mySubmit = e => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.formdata.name === "") {
      //this.setState({ loginpassValid: 'Password is required*' })
      arry += 'Title can not be empty* <br/>';
      error++;
    }
    console.log(error)

    //this.setState({ errors: arry }) 
    if (error > 0) {
      $('#error').html(arry)
    } else {
      $('#error').html('')
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(this.state.formdata)
      }
      fetch(`${config.googleAuth.backURL}admin/addblock`, options)
        .then(res => {
          //console.log("response",res)
          return res.json();
        })
        .then(data => {
          if (data.status === true) {
            this.getBlocks();
            (data.code === 500) ? toast("Block updated successfully!!") : toast("Block added successfully!!");
            this.setState({ formdata: this.initialState })
          }
          else {
            toast("Something wrong!");
          }
        })
        .catch(err => {
          console.log("error", err)
        })
    }

  }

  delhost = (id) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ 'id': id })
    }
    fetch(`${config.googleAuth.backURL}admin/delblock`, options)
      .then(res => {
        //console.log("response",res)
        return res.json();
      })
      .then(data => {
        if (data.status === true) {
          this.getBlocks();
          toast("Block deleted successfully!!");
          this.setState(this)
        }
        else {
          toast("Something wrong!");
        }
      })
      .catch(err => {
        console.log("error", err)
      })
  }

  delBlock = (id) => {


    confirmAlert({
      customUI: ({ title, message, onClose }) => <div className='custom-ui'>
        <h3>Are you sure?</h3>
        <p>You want to delete ?</p>
        <button onClick={onClose}>No</button>
        <button onClick={() => {
          this.delhost(id)
          onClose()
        }}>Yes, Delete it!</button>
      </div>,
    })
  }

  render() {
    var i = 1;
    return (
      <div>

        <Adminheader />
        <Adminsidebar />
        <ToastContainer />
        <div class="wrapper">
          <div class="main">

            <div class="main-content">
              <div class="container-fluid">
                <h3 class="page-title">Manage Blocks - {this.state.hostel.name}</h3>
                <button onClick={this.openModal} class="pull-right btn btn-success">Add Block</button>&nbsp;&nbsp;
                {/*<button onClick={this.openModal} class="pull-right btn btn-success">Add Floor</button>&nbsp;&nbsp;
          <button onClick={this.openModal} class="pull-right btn btn-success">Add Room</button>*/}
                <div class="row">
                  <div class="col-md-12">

                    <div class="panel">

                      <div class="panel-body">
                        <table class="table" id="">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Title </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(this.state.data) ? this.state.data.map((l) => {
                              return (

                                <tr>
                                  <td>{(l.block_id === 0) ? i++ : ''}</td>

                                  <td>{(l.block_id > 0) ? '------' + l.name : l.name}</td>
                                  <td>

                                    {(l.block_id === 0) ? (<><a href="#!" onClick={() => { this.openModal(l.id, 1) }} class="btn btn-success"><i class="fa fa-plus"></i> Add Floor</a>&nbsp;&nbsp;</>) : (<><Link to={'/admin/rooms/' + this.state.formdata.hostel_id + '/' + l.block_id + '/' + l.id} class="btn btn-success"><i class="fa fa-plus"></i> Add Rooms</Link>&nbsp;&nbsp;</>)}
                                    <a href="#!" onClick={() => { this.openModal(l.id) }} class="btn btn-success"><i class="fa fa-pencil"></i></a>&nbsp;&nbsp;
                                    <a href="#!" onClick={() => { this.delBlock(l.id) }} class="btn btn-success"><i class="fa fa-trash"></i></a>

                                  </td>
                                </tr>

                              )
                            }) : ''}
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Adminfooter />



        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id='shareModal'>
          <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'>Enter Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p id="error"></p>
            <form onSubmit={this.mySubmit}>
              <input class="form-control" placeholder="Title" onChange={this.changedata} value={this.state.formdata.name} name="name" type="text" />
              <br />
              <br />
              <button type="submit" class="btn btn-success">Submit</button>
            </form>
          </Modal.Body>
        </Modal>
      </div>

    )
  }
}

export default Adminblocks