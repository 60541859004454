import React from "react";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./admin/config.js";
import $ from "jquery";

const axios = require("axios");
class Forgot extends React.Component {
  state = {
    formdata: {
      email: "",
    },
  };

  initialState = {
    formdata: {
      email: "",
    },
  };
  mySubmit = (e) => {
    e.preventDefault();

    let error = 0;
    let arry = "";
    if (this.state.formdata.email === "") {
      toast("Email can not be empty");
      error++;
    }

    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");
      let newObj = this.state.formdata;

      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newObj),
      };
      fetch(`${config.googleAuth.backURL}user/forgotPass`, options)
        .then((res) => {
          // alert(res["message"]);
          console.log("response", res);
          return res.json();
        })

        .then((data) => {
          // console.log("Mail sent");
          if (data.status == true) {
            toast(data.message);
          } else {
            toast(data.message);
          }
        })

        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  
  changedata = (e) => {
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
  };
  render() {
    return (
      <div class="appHdr">
        <ToastContainer />
        <header>
          <div class="main-header">
            <div class="hdr-row">
              <a href="/" class="mdlBack-btn">
                <i class="fa fa-angle-left" aria-hidden="true"></i> Home
              </a>
            </div>
          </div>
        </header>
        <section class="appBody wrapper">
          <div class="login-block form login">
            <div class="login-row">
              <div class="main-heading text-center">
                <h2>Forgot Password</h2>
              </div>
              <form onSubmit={this.mySubmit}>
                <p id="error"></p>
                <div class="login-form common-form">
                  <div class="field input form-group">
                    <label>Email</label>
                    <input
                      spellcheck="false"
                      class="form-control"
                      type="email"
                      name="email"
                      onChange={this.changedata}
                      placeholder="Enter your email"
                      required=""
                    />
                  </div>
                  <div class="field form-btn button mt-40">
                    <input
                      class="btn btn-common btn-blue btn-round btn-big"
                      type="submit"
                      name="submit"
                      value="Forgot"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Forgot);
