import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from "./header";
import config from "./admin/config.js";
import jwt from "jsonwebtoken";
import Footer from "./footer";
import helper from "../helper.js";
import { GlobalStoreContext } from "../ContextAPI/MyContext";

class MyTicket extends React.Component {
  static contextType = GlobalStoreContext;
  state = {
    student_id: "",
    user_name: "",
    user_flag: "",
    openTickets: [],
    wipTickets: [],
    closedTickets: [],
  };

  componentDidMount() {
    const { updateData } = this.context;
    updateData(null);
    console.log("componentDidMount");
    const stdId = localStorage.getItem("stdId");
    this.setState({ student_id: stdId });
    jwt.verify(localStorage.getItem("user_name"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_name: decoded.user_name });
      }
    });
    let sessionFlag = jwt.verify(
      localStorage.getItem("user_flag"),
      "pokemon",
      (err, decoded) => {
        if (err) {
          localStorage.clear();
        } else {
          return decoded;
        }
      }
    );

    let sessionId = jwt.verify(
      localStorage.getItem("mainID"),
      "pokemon",
      (err, decoded) => {
        if (err) {
          localStorage.clear();
        } else {
          return decoded;
        }
      }
    );

    this.setState({ user_flag: sessionFlag.user_flag });

    var flag = sessionFlag.user_flag;
    var id = sessionId.mainID;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ flag: flag, id: stdId }),
    };
    fetch(`${config.googleAuth.backURL}user/getTickets`, options)
      .then((res) => {
        //console.log("response",res)
        return res.json();
      })

      .then((data) => {
        if (data.status == true) {
          console.log(data.data);
          this.setState({
            openTickets: data.openTickets,
            wipTickets: data.wipTickets,
            closedTickets: data.closedTickets,
          });
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  }

  render() {
    return (
      <div class="appHdr">
        <header>
          <Header />
        </header>
        <section class="appBody wrapper">
          <div class="login-block form login">
            <div class="login-row">
              <div class="my-ticket-tabs">
                 
                  <Tabs>
                    <div class="top-tab-hd">
                      <TabList>
                        <Tab>Open ({this.state.openTickets?.length})</Tab>
                        <Tab>WIP ({this.state.wipTickets?.length})</Tab>
                        <Tab>Closed ({this.state.closedTickets?.length})</Tab>
                      </TabList>
                    </div>
                    <TabPanel>
                      {this.state.openTickets?.length > 0 ? (
                        <>
                          {this.state?.openTickets?.map((ticket, index) => {
                            return (
                              <div class="asset-dis ticket-staus tckt-lst-bd">
                                <Link to={"ticket-detail?id=" + ticket.id}>
                                  <h4>
                                    {ticket.ticket_no}{" "}
                                    {/* {ticket.admin_status === "reopened" && (
                                    <span
                                      style={{
                                        color: "white",
                                        background: "#eb4444",
                                        borderRadius: "3px",
                                        padding: "5px",
                                        fontSize: "12px",
                                        fontWeight: "300",
                                      }}
                                    >
                                      Reopened
                                    </span>
                                  )} */}
                                  </h4>
                                </Link>
                                <p>{ticket.short_desc} </p>
                                <ul class="ticket-time">
                                  <Link to={"ticket-detail?id=" + ticket.id}>
                                    <li>
                                      <span class="tct-open">Open</span>
                                    </li>{" "}
                                  </Link>
                                  <li>
                                    {helper.dateDiffence(
                                      ticket.open_date,
                                      Date.now()
                                    )}{" "}
                                    {helper.dateDiffence(
                                      ticket.open_date,
                                      Date.now()
                                    ) <= 1
                                      ? "Day"
                                      : "Days"}
                                  </li>

                                  {ticket.eng_id === 0 ? (
                                    <>
                                      <li>
                                        <span class="tct-close">
                                          Waiting for approval
                                        </span>
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      <li>
                                        <Link
                                          to={`chat?ticketno=${ticket.ticket_no}&ticket_status=${ticket.ticket_status}`}
                                        >
                                          <span class="chat-icn">
                                            <i
                                              class="fa fa-comments"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </Link>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <div class="asset-dis ticket-staus">
                            <p>Ticket Empty</p>
                          </div>
                        </>
                      )}
                    </TabPanel>
                    <TabPanel>
                      {this.state.wipTickets?.length > 0 ? (
                        <>
                          {this.state.wipTickets?.map((ticket, index) => {
                            return (
                              <div class="asset-dis ticket-staus tckt-lst-bd">
                                <Link to={"ticket-detail?id=" + ticket.id}>
                                  <h4>{ticket.ticket_no} </h4>
                                </Link>
                                <p>{ticket.short_desc} </p>
                                <ul class="ticket-time">
                                  <Link>
                                    <li>
                                      <span class="tct-pro">
                                        Work-in-Progress
                                      </span>
                                    </li>
                                  </Link>
                                  <li>
                                    {helper.dateDiffence(
                                      ticket.open_date,
                                      Date.now()
                                    )}{" "}
                                    {helper.dateDiffence(
                                      ticket.open_date,
                                      Date.now()
                                    ) <= 1
                                      ? "Day"
                                      : "Days"}
                                  </li>

                                  {ticket.eng_id === 0 ? (
                                    <>
                                      <li>
                                        <span class="tct-close">
                                          Waiting for approval
                                        </span>
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      <li>
                                        <Link
                                          to={`chat?ticketno=${ticket.ticket_no}&ticket_status=${ticket.ticket_status}`}
                                        >
                                          <span class="chat-icn">
                                            <i
                                              class="fa fa-comments"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </Link>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <div class="asset-dis ticket-staus">
                            <p>Ticket Empty</p>
                          </div>
                        </>
                      )}
                    </TabPanel>
                    <TabPanel>
                      {this.state.closedTickets?.length > 0 ? (
                        <>
                          {this.state.closedTickets?.map((ticket, index) => {
                            return (
                              <div class="asset-dis ticket-staus tckt-lst-bd">
                                <Link to={"ticket-detail?id=" + ticket.id}>
                                  <h4>{ticket.ticket_no}</h4>
                                </Link>
                                <p>{ticket.short_desc} </p>
                                <ul class="ticket-time">
                                  <Link>
                                    <li>
                                      <span class="tct-close">Closed</span>
                                    </li>
                                  </Link>
                                  <li>
                                    {helper.dateDiffence(
                                      ticket.open_date,
                                      Date.now()
                                    )}{" "}
                                    {helper.dateDiffence(
                                      ticket.open_date,
                                      Date.now()
                                    ) <= 1
                                      ? "Day"
                                      : "Days"}
                                  </li>

                                  {ticket.eng_id === 0 ? (
                                    <>
                                      <li>
                                        <span class="tct-close">
                                          Waiting for approval
                                        </span>
                                      </li>
                                    </>
                                  ) : (
                                    <>
                                      <li>
                                        <Link
                                          to={`chat?ticketno=${ticket.ticket_no}&ticket_status=${ticket.ticket_status}`}
                                        >
                                          <span class="chat-icn">
                                            <i
                                              class="fa fa-comments"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </Link>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <div class="asset-dis ticket-staus">
                            <p>Ticket Empty</p>
                          </div>
                        </>
                      )}
                    </TabPanel>
                  </Tabs>
                

               
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </div>
    );
  }
}

export default MyTicket;
