import React from "react";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./admin/config.js";
import $ from "jquery";
const axios = require("axios");
class Hostel extends React.Component {
  state = {
    Universities: [],
    hostels: [],
    blocks: [],
    floors: [],
    rooms: [],
    formdata: this.props.data,
    uni_id: "",
    hostel_id: "",
    block_id: "",
    floor_id: "",
    room_id: "",
  };
  componentDidMount() {
    console.log("componentDidMount");
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    fetch(`${config.googleAuth.backURL}user/getUniversity`, options)
      .then((res) => {
        //console.log("response",res)
        return res.json();
      })
      .then((data) => {
        if (data.status == true) {
          console.log(data.data);
          this.setState({ Universities: data.data });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  getHostelbyUni = (e) => {
    let uni_id = e.target.value;
    if (uni_id != "") {
      this.setState({ uni_id: uni_id });
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: uni_id }),
      };
      fetch(`${config.googleAuth.backURL}user/getHostelbyUni`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            this.setState({
              hostels: data.data,
              blocks: [],
              floors: [],
              rooms: [],
            });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  getBlockbyHostel = (e) => {
    let hostel_id = e.target.value;
    if (hostel_id != "") {
      this.setState({ hostel_id: hostel_id });
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: hostel_id }),
      };
      fetch(`${config.googleAuth.backURL}user/getBlockbyhostel`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            console.log(data.data);
            this.setState({ blocks: data.data, floors: [], rooms: [] });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  getFloorbyBlock = (e) => {
    let block_id = e.target.value;
    if (block_id != "") {
      this.setState({ block_id: block_id });
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: block_id }),
      };
      fetch(`${config.googleAuth.backURL}user/getFloorbyBlock`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            console.log(data.data);
            this.setState({ floors: data.data, rooms: [] });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  getRoomsbyFloor = (e) => {
    let floor_id = e.target.value;
    if (floor_id != "") {
      this.setState({ floor_id: floor_id });
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: floor_id }),
      };
      fetch(`${config.googleAuth.backURL}user/getRoomsbyFloor`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            console.log(data.data);
            this.setState({ rooms: data.data });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  setRoomID = (e) => {
    let room_id = e.target.value;
    this.setState({ room_id: room_id });
  };
  mySubmit = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.uni_id === "") {
      toast("University can not be empty");
      error++;
    }
    if (this.state.hostel_id === "") {
      toast("Hostel can not be empty");
      error++;
    }
    if (this.state.block_id === "") {
      toast("Block can not be empty");
      error++;
    }
    if (this.state.floor_id === "") {
      toast("Floor can not be empty");
      error++;
    }
    if (this.state.room_id === "") {
      toast("Room can not be empty");
      error++;
    }
    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");
      let newObj = this.state.formdata;
      newObj["uni_id"] = this.state.uni_id;
      newObj["hostel_id"] = this.state.hostel_id;
      newObj["block_id"] = this.state.block_id;
      newObj["floor_id"] = this.state.floor_id;
      newObj["room_id"] = this.state.room_id;
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newObj),
      };
      fetch(`${config.googleAuth.backURL}user/studentRegistration`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            toast("Successfully Registered!!");
            setTimeout(() => {
              this.props.history.push("/login");
            }, 3000);
          } else {
            toast(data.message);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  render() {
    return (
      <div class="appHdr">
        <ToastContainer />
        <header>
          <div class="main-header">
            <div class="hdr-row">
              <a href="/" class="mdlBack-btn">
                <i class="fa fa-angle-left" aria-hidden="true"></i> Home
              </a>
            </div>
          </div>
        </header>
        <section class="appBody wrapper">
          <div class="login-block form login">
            <div class="login-row">
              <div class="main-heading text-center">
                <h2>Hostel Detail</h2>
              </div>
              <form onSubmit={this.mySubmit}>
                <p id="error"></p>
                <div class="login-form common-form">
                  <div class="field input form-group">
                    <label>Select University </label>
                    <div class="src-cat-grp">
                      <select
                        class="form-control"
                        name="university"
                        id="university"
                        onChange={this.getHostelbyUni}
                      >
                        <option value="">Choose</option>
                        {this.state.Universities.map((uni, index) => {
                          return <option value={uni.id}>{uni.name}</option>;
                        })}
                      </select>
                      <div class="src-bars">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <div class="field input form-group">
                    <label>Select Hostel </label>
                    <div class="src-cat-grp">
                      <select
                        class="form-control"
                        name="hostel"
                        id="dpdHomeCategory"
                        onChange={this.getBlockbyHostel}
                      >
                        <option value="">Choose</option>
                        {this.state.hostels.map((hostel, index) => {
                          return (
                            <option value={hostel.id}>{hostel.name}</option>
                          );
                        })}
                      </select>
                      <div class="src-bars">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <div class="field input form-group">
                    <label>Select Block </label>
                    <div class="src-cat-grp">
                      <select
                        class="form-control"
                        name="block"
                        onChange={this.getFloorbyBlock}
                      >
                        <option value="">Choose</option>
                        {this.state.blocks.map((block, index) => {
                          return <option value={block.id}>{block.name}</option>;
                        })}
                      </select>
                      <div class="src-bars">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <div class="field input form-group">
                    <label>Select Floor </label>
                    <div class="src-cat-grp">
                      <select
                        class="form-control"
                        name="floor"
                        onChange={this.getRoomsbyFloor}
                      >
                        <option value="">Choose</option>
                        {this.state.floors.map((floor, index) => {
                          return <option value={floor.id}>{floor.name}</option>;
                        })}
                      </select>
                      <div class="src-bars">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <div class="field input form-group">
                    <label>Room Number</label>
                    <div class="src-cat-grp">
                      <select
                        class="form-control"
                        name="room"
                        onChange={this.setRoomID}
                      >
                        <option value="">Choose</option>

                        {this.state.rooms.map((room, index) => {
                          return <option value={room.id}>{room.name}</option>;
                        })}
                      </select>
                      <div class="src-bars">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <div class="field form-btn button mt-40">
                    <input
                      class="btn btn-common btn-blue btn-round btn-big"
                      type="submit"
                      name="submit"
                      value="Next"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(Hostel);
