import React from "react";
import { Link, withRouter } from "react-router-dom";

function ProfileSrv() {
    return (
    <div class="appHdr">
    <header>
        <div class="main-header">
            <div class="header-inner">
                <ul>
                    <li><div id="menu"></div></li>
                    <li><p>Welcome! Sandeep</p></li>
                    <li></li>
                </ul>
            </div>    
        </div>
    </header>
    <section class="appBody wrapper">
        <div class="login-block form login">
        <div class="login-row">
                <div class="main-heading text-center">
                    <h2>Profile Details</h2>	
                </div>
                <div class="text-center">
                    <div class="prof-img">
                        <img src="images/user1.png"/> 
                        <Link class="profl-icn" to="/">		  
                            Edit
                        </Link>	
                    </div>    
                </div>
                <form>
                <div class="login-form common-form">  
                    <div class="field input form-group">
                        <label>Full Name </label>
                        <input class="form-control" type="text" name="email" placeholder="Danish Sharma" readonly/>
                    </div>
                    <div class="field input form-group">
                        <label>Email ID</label>
                        <input class="form-control" type="text" name="password" placeholder="abc@gmail.com" readonly />
                    </div>
                    <div class="field input form-group">
                        <label>Mobile</label>
                        <input class="form-control" type="text" name="password" placeholder="8556055809" readonly />
                    </div>
                    <div class="field input form-group">
                        <label>Student ID</label>
                        <input class="form-control" type="text" name="password" placeholder="123DAS" readonly />
                    </div>
                    <div class="field form-btn button mt-40">
                        <Link class="btn btn-common btn-blue btn-round btn-big" to="/">
                            Edit
                        </Link>	
                    </div>
                </div>
                </form>
              </div>  
           
        </div>
        <div class="footer-menu">
            <ul>  
                <li class="menu-ic">
                    <Link class="ftr-icn" to="profile">		  
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <span>Profile</span>
                    </Link>		 
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn" to="my-ticket">		  
                        <i class="fa fa-list" aria-hidden="true"></i>
                        <span>List</span>
                    </Link>	   
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn active" to="/">
                        <i class="fa fa-home" aria-hidden="true"></i>
                        <span>Home</span>
                    </Link>		  
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn" to="create-ticket">		  
                        <i class="fa fa-ticket" aria-hidden="true"></i>
                        <span>Ticket</span>
                    </Link>	   
                </li>
                <li class="menu-ic">
                    <Link class="ftr-icn" to="services-list">		  
                        <i class="fa fa-anchor" aria-hidden="true"></i>
                        <span>Services</span>
                    </Link>	   
                </li>
            </ul>
        </div>
    </section>
</div>
  );
}

export default ProfileSrv;