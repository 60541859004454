import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import QrReader from "react-qr-reader";
import Header from "./header";
import CreateTicket from "./create-ticket";
import Assetadd from "./assetadd";
import QrScanner from "qr-scanner";
import { GlobalStoreContext } from "../ContextAPI/MyContext";
import config from "./admin/config";
import { toast } from "react-toastify";
import jwt from "jsonwebtoken";
class Qrsubmit extends Component {
  static contextType = GlobalStoreContext;
  constructor(props) {
    super(props);
    this.state = {
      delay: 500,
      component: "",
      old_asset_id: "",
      result: "",
      result2: "",
      uploadedImage: null,
      student_id: "",
      asset_id: "",
      ticketId: "",
    };
    this.inputRef = React.createRef();

    this.handleScan = this.handleScan.bind(this);
    this.openImageDialog = this.openImageDialog.bind(this);
    this.handleScan2 = this.handleScan2.bind(this);
    // this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  componentDidMount = (e) => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const old_asset_id = queryParams.get("oldAsset");
    const ticketId = queryParams.get("ticketId");
    this.setState({ component: id, old_asset_id, ticketId });
    jwt.verify(localStorage.getItem("user_id"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({
          student_id: decoded.user_id,
        });
        //this.setState({user_id:decoded.user_id});
      }
    });
  };

  handleScan(result) {
    //alert(result);
    if (result) {
      if (result != "" && result != null) {
        this.setState({ result });

        if (this.state.component == "CreateTicket") {
          this.props.history.push("/create-ticket?asset_id=" + result);
        } else {
          this.props.history.push("/add-asset?asset_id=" + result);
        }
      } else alert("Not detected!!!");
    }
  }

  handleError(err) {
    console.error(err);
  }
  openImageDialog() {
    this.refs.qrReader1.openImageDialog();
  }
  handleScan2(result2) {
    //alert(result2);
    if (result2 != "" && result2 != null) {
      this.setState({ result2 });
      const rese = JSON.parse(result2);
      if (this.state.component === "Assetadd") {
        this.context.updateData(rese?.qrcodenew || null);
        this.props.history.push("/add-asset");
      }
      if (this.state.component == "CreateTicket") {
        this.props.history.push("/create-ticket?asset_id=" + result2);
      } else {
        this.props.history.push("/add-asset?asset_id=" + result2);
      }
    } else alert("Not detected!!!");
  }
  handleError2(err) {
    console.error(err);
  }
  focusInput = () => {
    this.inputRef.current.click();
  };

  render() {
    const previewStyle = {};
    console.log("stare", this.state);

    return (
      <div class="appHdr scan-qr-block">
        <header>
          <div class="main-header">
            <div class="chatHeader">
              <Link
                to={
                  this.state.component === "CreateTicket"
                    ? "create-ticket"
                    : "add-asset"
                }
                class="back-icon"
              >
                <i class="fa fa-angle-left" aria-hidden="true"></i>
              </Link>

              <div class="details">
                <span> </span>
                <p></p>
              </div>
            </div>
          </div>
        </header>

        {/* <QrReader
          ref="qrReader1"
          delay={this.state.delay}
          style={previewStyle}
          // className="qrhide"
          onError={this.handleError}
          onScan={this.handleScan}
          // legacyMode
        /> */}

        <QrReader
          src={this.state.uploadedImage}
          delay={this.state.delay}
          style={previewStyle}
          className="qrcam"
          onError={this.handleError2}
          onScan={this.handleScan2}
        />

        <div class="text-center scan-ftr">
          <p align="center scan-txt" color="green">
            Please Select Valid QR Code
          </p>
          <GlobalStoreContext.Consumer>
            {(context) => (
              <input
                hidden
                style={{ display: "none" }}
                ref={this.inputRef}
                // type="button"
                class="btn btn-primary scan-btn"
                // value="Select QR Code"
                type="file"
                accept="image/*"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  if (file) {
                    console.log();
                    const res = await QrScanner.scanImage(file);
                    this.setState({ result: res });
                    const rese = JSON.parse(res);

                    console.log(rese, "rese");
                    if (this.state.component === "Assetadd") {
                      context.updateData(rese?.qrcodenew || null);
                      this.props.history.push("/add-asset");

                      // let options = {
                      //   method: "POST",
                      //   headers: {
                      //     Accept: "application/json",
                      //     "Content-Type": "application/json",
                      //   },
                      //   body: JSON.stringify({
                      //     asset_id: rese?.qrcodenew,
                      //     // qrcode:rese?.qrcodenew,
                      //     student_id: this.state.student_id,
                      //     insertdate: Date.now(),
                      //   }),
                      // };
                      // fetch(
                      //   `${config.googleAuth.backURL}user/addAssetByStd`,
                      //   options
                      // )
                      //   .then((res) => {
                      //     //console.log("response",res)
                      //     return res.json();
                      //   })

                      //   .then((data) => {
                      //     if (data.status == true) {
                      //       toast("Asset added Successfully");

                      //       setTimeout(() => {
                      //         window.location.replace("/asset-detail");
                      //       }, 2000);
                      //     } else {
                      //       toast(data.message || "Ops! Something went wrong.");
                      //     }
                      //   });
                    } else if (this.state.component === "ReplaceAsset") {
                      context.updateData({
                        value: `${rese?.name} : ${rese?.qrcodenew}`,
                        label: rese?.qrcodenew,
                      });
                      this.props.history.push(
                        `/ticket-detail?id=${this.state.ticketId}`
                      );
                    } else if (this.state.component === "CreateTicket") {
                      context.updateData(
                        { value: rese?.qrcodenew, label: rese?.qrcodenew } ||
                          null
                      );
                      this.props.history.push("/create-ticket");
                    } else {
                      context.updateData(rese?.qrcodenew || null);
                    }
                  }
                }}
              />
            )}
          </GlobalStoreContext.Consumer>
          <input
            class="btn btn-primary scan-btn"
            value="Select QR Code"
            type="button"
            onClick={this.focusInput}
          />{" "}
          {/* {this.state.result && <p> {this.state.result}</p>} */}
        </div>
      </div>
    );
  }
}

export default withRouter(Qrsubmit);
