import React from 'react'
import Adminheader from './common/adminheader'
import Adminfooter from './common/adminfooter'
import Adminsidebar from './common/adminsidebar'
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "./config.js"
import {Switch,Route,Redirect,Link} from 'react-router-dom';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Modal from 'react-bootstrap-modal'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const axios = require("axios");
class Adminhostels extends React.Component{
    state={    
        data:[],
        countries:[],
        universities: [],
        states:[],
        cities:[],
        loginflag:false,
        formdata: {
          id:"",
          uid:"",
          name:"",
          address:"",
          city:"",
          state:"",
          country:""
        },
        errors: []
      }

      initialState = {
          id:"",
          uid:"",
          name:"",
          address:"",
          city:"",
          state:"",
          country:""
        };
    closeModal = () => this.setState({ open: false })

    openModal = async (id=0) => { 
      this.setState({ open: true })
      this.getcountries();
      this.getuniversities();
      if(id>0){
        await this.gethostel(id)
        await this.getstates(this.state.formdata.country)
        await this.getcities(this.state.formdata.state)
      }else {
        this.setState({formdata: this.initialState});
      }
      setTimeout(() => { $('.modal').css({'display':'block'}); }, 500)
    }
       
     changedata=(e)=>{
        //this.setState({[e.target.name]:e.target.value.toLowerCase()}) 
        this.setState({ formdata: { ...this.state.formdata, [e.target.name]:e.target.value} });
        if(e.target.name === 'country'){
          this.getstates(e.target.value)
        }
        if(e.target.name === 'state'){
          this.getcities(e.target.value)
        }   
      }  
      componentDidMount(){
        this.gethostels(window.location.href.split('/')[5]);
        $(document).ready(function () {
          setTimeout(function () {
            $('#example1').DataTable();
          }, 1000);
        });
      }

      getuniversities = () => {
        fetch(`${config.googleAuth.backURL}admin/universities`)
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ universities : responseJson.data, })
              console.log(this.state)
            })
            .catch((error) => {
              console.error(error);
            });        
      }

      gethostel = async (id) => {
        await fetch(`${config.googleAuth.backURL}admin/hostel/`+id)
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ formdata : responseJson.data, })
              console.log(this.state)
            })
            .catch((error) => {
              console.error(error);
            });        
      }

      gethostels = (id='n') => {
        let q = (id=='n')?'':'/'+id
        fetch(`${config.googleAuth.backURL}admin/hostels`+q)
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ data : responseJson.data, })
              console.log(this.state)
            })
            .catch((error) => {
              console.error(error);
            });        
      }

      getcountries = () => {
        fetch(`${config.googleAuth.backURL}admin/countries`)
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ countries : responseJson.data })
              console.log(this.state)
            })
            .catch((error) => {
              console.error(error);
            });
      }

      getstates = (id) => {    
        fetch(`${config.googleAuth.backURL}admin/states/`+id)
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ states : responseJson.data,})
            })
            .catch((error) => {
              console.error(error);
            });
      }

      getcities = (id) => {    
        fetch(`${config.googleAuth.backURL}admin/cities/`+id)
            .then((response) => response.json())
            .then((responseJson) => {
              this.setState({ cities : responseJson.data,})
            })
            .catch((error) => {
              console.error(error);
            });
      }
     
     mySubmit = e => {
        e.preventDefault();
        let error = 0;
        let arry = "";
        if (this.state.formdata.name === "") {
          //this.setState({ loginpassValid: 'Password is required*' })
          arry += 'Hostel name can not be empty* <br/>';
          error++;
        } 
        if (this.state.formdata.address === "") {
          arry += 'Hostel address can not be empty* <br/>';
          error++;
        } 
        // if (this.state.formdata.country === "") {
        //   arry += 'Country cans not be empty* <br/>';
        //   error++;
        // }
        // if (this.state.formdata.state === "") {
        //   arry += 'State cans not be empty* <br/>';
        //   error++;
        // }
        console.log(error)

        //this.setState({ errors: arry }) 
        if (error > 0) {
            $('#error').html(arry)
          } else {
            $('#error').html('')
              let options={
                  method:"POST",
                  headers:{
                  Accept:"application/json",
                    "Content-Type":"application/json"
                  },
                  body:JSON.stringify(this.state.formdata)
                }
                fetch(`${config.googleAuth.backURL}admin/addhostel`,options)
                .then(res=>{
                  //console.log("response",res)
                  return res.json();
                })
                .then(data=>{                
                  if(data.status == true){
                    this.gethostels();
                    (data.code == 500)?toast("Hostel updated successfully!!"):toast("Hostel added successfully!!");                  
                    this.setState({formdata: this.initialState})
                  }
                  else{
                    toast("Something wrong!");
                  }
                })
                .catch(err=>{
                  console.log("error",err)
                })         
         }      
  
  }

  delhost = (id) => {    
        let options={
                  method:"POST",
                  headers:{
                  Accept:"application/json",
                    "Content-Type":"application/json"
                  },
                  body:JSON.stringify({'id':id})
                }
                fetch(`${config.googleAuth.backURL}admin/delhostel`,options)
                .then(res=>{
                  //console.log("response",res)
                  return res.json();
                })
                .then(data=>{                
                  if(data.status == true){
                    this.gethostels(id);
                    toast("Hostel deleted successfully!!");                  
                    this.setState(this)
                  }
                  else{
                    toast("Something wrong!");
                  }
                })
                .catch(err=>{
                  console.log("error",err)
                })   
      }

  delHostel = (id) => {
    

    confirmAlert({      
    customUI: ({ title, message, onClose }) => <div className='custom-ui'>
        <h3>Are you sure?</h3>
        <p>You want to delete this User?</p>
        <button onClick={onClose}>No</button>
        <button onClick={() => {
            this.delhost(id)
            onClose()
        }}>Yes, Delete it!</button>
      </div>,
    })    
  }
  
  render(){
    var i =1;
    return(
      <div>
        
        <Adminheader/>
        <Adminsidebar/>
        <ToastContainer />
        <div class="wrapper">
        <div class="main">
      
      <div class="main-content">
        <div class="container-fluid">
          <h3 class="page-title">Hostels List</h3>
          <button  onClick={this.openModal} class="pull-right btn btn-success">Add Hostel</button>
          <div class="row">
            <div class="col-md-12">
                            
                            <div class="panel">
                                
                                <div class="panel-body">
                        <table class="table" id="example1">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th> Hostel Name</th>
                                                <th> Address</th>                                              
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {(this.state.data)?this.state.data.map((l) => {
   return(
   
                                            <tr>
                                                <td>{i++}</td>
                                                
                                                <td>{l.name}</td>
                                                <td>{l.address}</td>                                                           
                                                <td>

                                                <Link to={'/admin/blocks/'+l.id} class="btn btn-success"><i class="fa fa-plus"></i> Add Blocks</Link> &nbsp;&nbsp;                                               
                                                <a href="javascript:void(0)" onClick={() => { this.openModal(l.id) }} class="btn btn-success"><i class="fa fa-pencil"></i></a>&nbsp;&nbsp;
                                                <a href="javascript:void(0)"  onClick={() => { this.delHostel(l.id) }} class="btn btn-success"><i class="fa fa-trash"></i></a>

                                                </td>
                                            </tr>
                                            
                                            )
}):''}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
          </div>
        </div>
      </div>
     
    </div>
    </div>
        <Adminfooter/>



        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="modal-lg2"
          id='shareModal'>
          <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'>Add Hostel</Modal.Title>
          </Modal.Header>
          <Modal.Body>   
          <p id="error"></p>
            <form onSubmit={this.mySubmit}>
                  <select class="form-control" name="uid" value={this.state.formdata.uid} onChange={this.changedata}>
                    <option value="">-- Select University ---</option>
                  {this.state.universities && this.state.universities && this.state.universities.length>0 ?  (this.state.universities.map((item) =>(<option value={item.id} >{item.name}</option>))   ) : '' }
                  </select>
                   <br/> 

                   <input class="form-control" placeholder="Hostel Name" onChange={this.changedata} value={this.state.formdata.name} name="name" type="text"/>
                  
                  <br/>
                  <input class="form-control" placeholder="Address" name="address" onChange={this.changedata} value={this.state.formdata.address}  type="text"/>
                  {/* <br/>
                  <select class="form-control" name="country" value={this.state.formdata.country} onChange={this.changedata}>
                    <option value="">-- select country ---</option>
                  {this.state.countries && this.state.countries && this.state.countries.length>0 ?  (this.state.countries.map((item) =>(<option value={item.id} >{item.name}</option>))   ) : '' }
                  </select>
                   <br/>  

                   <select class="form-control" name="state" value={this.state.formdata.state} onChange={this.changedata}>
                    <option value="">-- select state ---</option>
                    {this.state.states && this.state.states && this.state.states.length>0 ?  (this.state.states.map((item) =>(<option value={item.id} >{item.name}</option>))   ) : '' }
                  </select>
                  <br/>

                 <select class="form-control" name="city" value={this.state.formdata.city} onChange={this.changedata}>
                    <option value="">-- select city ---</option>
                    {this.state.cities && this.state.cities && this.state.cities.length>0 ?  (this.state.cities.map((item) =>(<option value={item.id} >{item.name}</option>))   ) : '' }
                  </select>
                  */}
                  <br/> 
                  
                  <button type="submit" class="btn btn-success">Submit</button>
                  </form>
          </Modal.Body>          
      </Modal>
        </div>
        
      )
  }
}

export default Adminhostels