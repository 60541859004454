import React from "react";
import { Link, withRouter } from "react-router-dom";
import config from "./admin/config.js"
import Footer from "./footer";
import jwt from 'jsonwebtoken'
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import Header from "./header";
class Changepassword extends React.Component{

    state={
        user_name:'',
        user_id:'',
        formdata: {
            oldPass:'',
            newPass:'',
            confPass:'',
        }
    }
    
    componentDidMount(){
        console.log('componentDidMount');

        jwt.verify(localStorage.getItem("user_name"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({user_name:decoded.user_name});                             
            }
        }) 
          
                
        jwt.verify(localStorage.getItem("user_id"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({user_id:decoded.user_id});                       
            }
        })         
    }


    mySubmit = e => {
        e.preventDefault();

        
        
        let error = 0;
        let arry = "";
        if (this.state.formdata.oldPass === "") {
          
          toast('Old Password can not be empty');
          error++;
        } 

        if (this.state.formdata.newPass === "") {
         
            toast('New Password can not be empty');
          error++;
        }
        
        if (this.state.formdata.confPass === "") {
           
            toast('Confirm Password can not be empty');
            error++;
        }

        if (this.state.formdata.newPass !== this.state.formdata.confPass) {
            
            toast('New password and confirm password does not match!!');
            error++;
        }
          
          
        
        console.log(error)
         

        //this.setState({ errors: arry }) 
        if (error > 0) {
            $('#error').html(arry)
          } else {

           
            let newObj = this.state.formdata
            newObj['user_id']=this.state.user_id
              let options={
                  method:"POST",
                  headers:{
                  Accept:"application/json",
                    "Content-Type":"application/json"
                  },
                  body:JSON.stringify(newObj)
                }
                fetch(`${config.googleAuth.backURL}user/changePassRequest`,options)
                .then(res=>{
                  
                  return res.json();
                })

                .then(data=>{
                    
                    if(data.status == true){
                        toast("Password updated!!");
                        setTimeout(
                            () => this.props.history.push('/change-password'),
                            3000
                          );
                        

                    }else{
                        toast("Ops! You entered wrong  old password");
                    }
                })

                .catch(err=>{
                  console.log("error",err)
                })          
         }      
  
  }

    changedata=(e)=>{
        this.setState({ formdata: { ...this.state.formdata, [e.target.name]:e.target.value} });    
        console.log(this.state)     
    }

render(){
  return (
        <div class="appHdr">
           <ToastContainer />
        <header>
        <Header/>
        </header>

        <section class="appBody wrapper">
            <div class="login-block form login">
                <div class="login-row">
                    <div class="main-heading text-center">
                        <h2>Change Password</h2>	
                    </div>
                    <form onSubmit={this.mySubmit}>
                    <p id="error"></p>
                    <div class="login-form common-form">                      
                        <div class="field input form-group">
                            <label>Old Password </label>
                            <div class="src-cat-grp">
                                <input class="form-control" type="password" name='oldPass' onChange={this.changedata} value={this.state.oldPass} placeholder='Enter Old Password'/>
                            </div>
                        </div>

                        <div class="field input form-group">
                            <label>New Password </label>
                            <div class="src-cat-grp">
                                <input class="form-control" type="password" name='newPass' onChange={this.changedata} value={this.state.newPass} placeholder='Enter New Password'/>
                            </div>
                        </div>

                        <div class="field input form-group">
                            <label>Confirm Password </label>
                            <div class="src-cat-grp">
                                <input class="form-control" type="password" name='confPass' onChange={this.changedata} value={this.state.confPass} placeholder='Enter Confirm Password'/>
                            </div>
                        </div>

                        <div class="field form-btn button mt-40">
                            <input class="btn btn-common btn-blue btn-round btn-big" type="submit" name="submit" value="Change Password" />
                        </div>
                    </div>
                    </form>
                </div>  
            </div>
            <Footer />
        </section>
    </div>
    );
    }
  }
export default withRouter(Changepassword);