import React from "react";
import { Link, withRouter } from "react-router-dom";
import config from "./admin/config.js"
import Menu from "./menu";
import Footer from "./footer";
import jwt from 'jsonwebtoken'
import Header from "./header";

class Hosteldetail extends React.Component{

    state={
        user_name:'',
        user_id:'',
        hostelDetail:[],
        AssetDetail:[]
    }
    
    componentDidMount(){
        console.log('componentDidMount');

        jwt.verify(localStorage.getItem("user_name"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({user_name:decoded.user_name});                             
            }
        }) 
          
                
        jwt.verify(localStorage.getItem("user_id"), 'pokemon', (err, decoded)=>{
            if(err){
                localStorage.clear()
            }else{
                this.setState({user_id:decoded.user_id});  
                this.getHostelDetails(decoded.user_id);   
                
                this.getAssetDetail(decoded.user_id);  
            }
        })      
        
        console.log(this.state);
    }
    getAssetDetail = (stdId) => {
        
        let options={
            method:"POST",
            headers:{
                Accept:"application/json",
                "Content-Type":"application/json"
            },
            body:JSON.stringify({student_id:stdId})    
        } 
        fetch(`${config.googleAuth.backURL}user/getAssetDetail`,options) 
        .then(res=>{
            return res.json();
        })

            .then(data=>{                
                if(data.status == true){
                   
                    this.setState({AssetDetail:data.data});
                }else{
                    
                }
            })

          .catch(err=>{
            console.log("error",err)
        });        
    }
    getHostelDetails = (stdId) => {
        
        let options={
            method:"POST",
            headers:{
            Accept:"application/json",
              "Content-Type":"application/json"
            },
            body:JSON.stringify({id:stdId}) 
        }
          fetch(`${config.googleAuth.backURL}user/getHostelDetails`,options)
          .then(res=>{
            //console.log("response",res)
            return res.json();
        })

            .then(data=>{
              if(data.status == true){
                 
                    this.setState({hostelDetail:data.data[0]});
              }
            })

          .catch(err=>{
            console.log("error",err)
        })         
           
    }

render(){
  return (
        <div class="appHdr">
           
        <header>
            <Header />
        </header>

        <section class="appBody wrapper">
            <div class="login-block form login">
            <div class="login-row">
                <div class="main-heading text-center">
                    <h2>Hostel Detail</h2>	
                </div>
                <form >
                
                    <div class="login-form common-form">  
                    <div class="field input form-group">
                            <label>University </label>
                            <div class="src-cat-grp">

                            <input class="form-control" type="text" placeholder={this.state.hostelDetail['university']} readOnly/>
                                 
                            </div>
                        </div>
                        <div class="field input form-group">
                            <label>Hostel </label>
                            <div class="src-cat-grp">
                            <input class="form-control" type="text" placeholder={this.state.hostelDetail['hostel']} readOnly/>
                                
                            </div>
                        </div>

                        <div class="field input form-group">
                            <label>Block </label>
                            <div class="src-cat-grp">
                            <input class="form-control" type="text" placeholder={this.state.hostelDetail['block']} readOnly/>
                                
                            </div>
                        </div>

                        <div class="field input form-group">
                            <label>Floor </label>
                            <div class="src-cat-grp">
                            <input class="form-control" type="text" placeholder={this.state.hostelDetail['floor']} readOnly/>
                                 
                            </div>
                        </div>

                        <div class="field input form-group">
                            <label>Room Number</label>
                            <div class="src-cat-grp">
                                <input class="form-control" type="text" placeholder={this.state.hostelDetail['room']} readOnly/>
                                 
                            </div>
                        </div>
                       
                    </div>

                    <div class="asst-inr-dis">    
                        <div class="main-heading text-left mr-30">
                            <h2>Asset Details</h2>	
                        </div>
                        <div class="asset-dis">
                            <ul>
                            {this.state.AssetDetail.map((asset,index)=>{
                                return (
                                    <li><b>Asset {index+1}:</b> {asset.itemName} : {asset.asset_id}</li>
                                ); 
                            })}
                            </ul>
                        </div>  
                    </div>

                </form>
            </div>  
            </div>
            <Footer />
        </section>
    </div>
    );
    }
  }
export default Hosteldetail;