import React from "react";
import { Link, withRouter } from "react-router-dom";
import Menu from "./menu";
import Footer from "./footer";
function ChatList() {
    
  return (
    <div class="appHdr">
        <header>
           <div class="main-header">
              <div class="header-inner">
                    <div class="hedar-links">
                         <div class="menu-bar-sec">
                            <Menu />
                        </div>
                        <div class="login-name">
                              <p>Welcome Sandeep</p>
                        </div>  
                        <div class="profile-top">
                            <Link to="profile"> 
                                <img src="images/user1.png"/>
                            </Link>   
                        </div>    
                     </div>       
                </div> 
              </div>
        </header>
        <section class="appBody chatAreaBodyOuter">
          <div class="serch-btn common-form">
            <div class="field input form-group">
                <input class="form-control" type="search" name="search" placeholder="Search something here..."/>
                <Link class="view-pass" to="/">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </Link>
            </div>
          </div>  
           <div class="chat-lst-bdy">
                <Link class="cht-pro-icn" to="chat">		  
                   <span class="cht-icn-img">
                     <img src="images/user1.png"/>   
                   </span>
                   <h4>Sandeep Singh</h4>
                   <p>you:It is a long established fact It is a long established fact</p>
                   <span class="msg-nw">2</span>
                </Link>	
                <Link class="cht-pro-icn" to="chat">		  
                   <span class="cht-icn-img">
                     <img src="images/user1.png"/>   
                   </span>
                   <h4>Sandeep Singh</h4>
                   <p>you:It is a long established fact</p>
                   <span class="msg-nw">2</span>
                </Link>	
                <Link class="cht-pro-icn" to="chat">		  
                   <span class="cht-icn-img">
                     <img src="images/user1.png"/>   
                   </span>
                   <h4>Sandeep Singh</h4>
                   <p>you:It is a long established fact</p>
                </Link>	
                <Link class="cht-pro-icn" to="chat">		  
                   <span class="cht-icn-img">
                     <img src="images/user1.png"/>   
                   </span>
                   <h4>Sandeep Singh</h4>
                   <p>you:It is a long established fact</p>
                   <span class="msg-nw">2</span>
                </Link>	
                <Link class="cht-pro-icn" to="chat">		  
                   <span class="cht-icn-img">
                     <img src="images/user1.png"/>   
                   </span>
                   <h4>Sandeep Singh</h4>
                   <p>you:It is a long established fact</p>
                 
                </Link>	
                <Link class="cht-pro-icn" to="chat">		  
                   <span class="cht-icn-img">
                     <img src="images/user1.png"/>   
                   </span>
                   <h4>Sandeep Singh</h4>
                   <p>you:It is a long established fact</p>
                </Link>	
                <Link class="cht-pro-icn" to="chat">		  
                   <span class="cht-icn-img">
                     <img src="images/user1.png"/>   
                   </span>
                   <h4>Sandeep Singh</h4>
                   <p>you:It is a long established fact</p>
                </Link>	

           </div>
           <div class="footer-menu">
                    <ul>  
                        <li class="menu-ic">
                            <Link class="ftr-icn" to="profile">		  
                                <i class="fa fa-comments" aria-hidden="true"></i>
                                <span>Chat</span>
                            </Link>		 
                        </li>
                        <li class="menu-ic">
                            <Link class="ftr-icn" to="my-ticket">		  
                                <i class="fa fa-list" aria-hidden="true"></i>
                                <span>List</span>
                            </Link>	   
                        </li>
                        <li class="menu-ic">
                            <Link class="ftr-icn active" to="asset-detail">
                                <i class="fa fa-home" aria-hidden="true"></i>
                                <span>Home</span>
                            </Link>		  
                        </li>
                        <li class="menu-ic">
                            <Link class="ftr-icn" to="create-ticket">		  
                                <i class="fa fa-ticket" aria-hidden="true"></i>
                                <span>Ticket</span>
                            </Link>	   
                        </li>
                        <li class="menu-ic">
                            <Link class="ftr-icn" to="services-list">		  
                                <i class="fa fa-anchor" aria-hidden="true"></i>
                                <span>Services</span>
                            </Link>	   
                        </li>
                    </ul>
                </div>  
                <Footer />          
        </section>

    </div>
  );
}

export default ChatList;