import React from "react";
import { Link, withRouter } from "react-router-dom";
import config from "./admin/config.js";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import jwt from 'jsonwebtoken'


const axios = require("axios");
class Login extends React.Component{

  state={
    showIcon: false,
    formdata: {   
      email: "",
      password: "",
    }
}


mySubmit = e => {
  e.preventDefault();
  
  
  let error = 0;
  let arry = "";
  if (this.state.formdata.email === "") {
    toast("Email can not be empty");
    error++;
  }
  
  if(this.state.formdata.password === "") {
      toast("Password can not be empty");
      error++;
  }
  
  if (error > 0) {
      $('#error').html(arry)
    } else {
      $('#error').html('');
       
        let options={
            method:"POST",
            headers:{
            Accept:"application/json",
              "Content-Type":"application/json"
            },
            body:JSON.stringify(this.state.formdata)
          }
          fetch(`${config.googleAuth.backURL}user/loginRequest`,options)
          .then(res=>{
            //console.log("response",res)
            return res.json();
          })

          .then(data=>{
            

              if(data.status == true){
                  toast("Successfully Login!!");
                  const user_id = jwt.sign({ expiresInMinutes: 60,user_id:data.data.id }, 'pokemon')
                  const user_flag = jwt.sign({ expiresInMinutes: 60,user_flag:data.data.flag }, 'pokemon')
                  const user_name = jwt.sign({ expiresInMinutes: 60,user_name:data.data.name }, 'pokemon')
                  const user_unicode = jwt.sign({ expiresInMinutes: 60,user_unicode:data.data.student_eng_id }, 'pokemon')
                  const mainID = jwt.sign({ expiresInMinutes: 60,mainID:data.mainID }, 'pokemon')
                  const user_img = jwt.sign({ expiresInMinutes: 60,user_img:data.photo }, 'pokemon')
            


                  localStorage.setItem("user_id",user_id)
                  localStorage.setItem("user_flag",user_flag)
                  localStorage.setItem("user_name",user_name)
                  localStorage.setItem("user_unicode",user_unicode)
                  localStorage.setItem("mainID",mainID)
                  localStorage.setItem("user_img",user_img)
                  this.props.history.push('/')
              }else{
                  toast(data.message);
              }
          })

          .catch(err=>{
            console.log("error",err)
          })         
   }      

}

changedata=(e)=>{
  this.setState({ formdata: { ...this.state.formdata, [e.target.name]:e.target.value} });        
}
render(){
  return (
    <div class="appHdr">
      <ToastContainer />
        <header>
            <div class="main-header">
                <div class="hdr-row">
                    <a href="/" class="mdlBack-btn">
                        <i class="fa fa-angle-left" aria-hidden="true"></i> Home
                    </a>
                </div>
            </div>
        </header>
        <section class="appBody wrapper">
            <div class="login-block form login">
                <div class="login-row">
                    <div class="login-logo">
                        <img src="images/logo.png" />
                    </div>
                    <div class="main-heading text-center">
                     <h2>Login Now</h2>	
                    </div>
                    <form onSubmit={this.mySubmit}>
                
                  <p id="error"></p>
                    <div class="login-form common-form">  
                        <div class="field input form-group">
                            <label>Email </label>
                            <input spellcheck="false" class="form-control" type="text" name="email" onChange={this.changedata} value={this.state.formdata.email} placeholder="Enter your email" required=""/>
                        </div>
                        <div class="field input form-group">
                            <label>Password</label>
                            <input spellcheck="false" class="form-control pass-view" type={!this.state.showIcon?'password':'text'} name="password" onChange={this.changedata} value={this.state.formdata.password} placeholder="Enter your password" required="" />
                            
                            <a class="view-pass" href="#!">
                            { !this.state.showIcon ? ( <>
                              <i class="fa fa-eye" aria-hidden="true" onClick={() => this.setState({ showIcon: true })}></i>
                            </>) : (<>
                              <i class="fa fa-eye-slash" aria-hidden="true" onClick={() => this.setState({ showIcon: false })}></i>
                              </>)}
                            
                            </a>
                        </div>

                            <div class="forgot-pass">
                                <Link class="nav-link" to="forgot">
                                     Forgot Password?
                                </Link>   
                            </div>
                        <div class="field form-btn button mt-40">
                            <input class="btn btn-common btn-blue btn-round btn-big" type="submit" name="submit" value="Login" />
                        </div>
                        </div>
                    </form>
                    <div class="accont-signp mt-30">
                        <p>Create New account. <Link class="nav-link" to="signup">Sign Up </Link></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
    );
    }
  }
export default withRouter(Login);