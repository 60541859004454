import React from "react";
import Adminheader from "./common/adminheader";
import Adminfooter from "./common/adminfooter";
import Adminsidebar from "./common/adminsidebar";
import { ToastContainer, toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";
import "react-toastify/dist/ReactToastify.css";
import config from "./config.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { jsPDF } from "jspdf";
import { confirmAlert } from "react-confirm-alert";

class Ticketdetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      data: [],
      Activities: [],
      images: [],
      errors: [],
      admin_rating: 0,
      key: 0, 
    };
    this.handleRating = this.handleRating.bind(this);
  }
  componentDidMount() {
    this.getTicketDetails();
  }

  getTicketDetails = async () => {
    var id = this.state.id;

    await fetch(`${config.googleAuth.backURL}admin/getTicketDetails/` + id)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          this.setState({
            data: responseJson.data,
            Activities: responseJson.activity,
            images: responseJson.images,
            admin_rating: Number(responseJson.data.admin_rating),
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  createPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await document.querySelector(".pdf");
    pdf.html(data).then(() => {
      pdf.save("ticketdetail.pdf");
    });
  };

  handleRating = (value) => {
    this.setState({ admin_rating: value });
    try {
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: this.state.id,
          admin_rating: value,
        }),
      };
      fetch(`${config.googleAuth.backURL}admin/addrating`, options)
        .then((res) => {
          //console.log("response",res)
          return res.json();
        })
        .then((data) => {
          if (data.status == true) {
            this.getTicketDetails();
            toast("updated successfully!!");
          } else {
            toast(data.message || "Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (err) {
      console.log("error", err);
    }
  };

  render() {
    var i = 1;
    return (
      <div>
        <Adminheader />
        <Adminsidebar />
        <ToastContainer />
        <div class="wrapper">
          <div class="main">
            <div class="main-content">
              <button
                className="btn-primary ml-4 btn-sm mb-2"
                onClick={this.createPDF}
                type="button"
              >
                Pdf Print
              </button>
              <div class="container-fluid">
                <h3
                  class="page-title"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Ticket Details{" "}
                  <div>
                    <div>
                      <label style={{ fontWeight: 300 }}>Rate</label>
                    </div>

                    <div>
                      <Rating
                      key={this.state.key} 
                        initialValue={this.state.admin_rating}
                        disableFillHover
                        readonly
                        // value={this.state.admin_rating}
                        // onClick={(value) =>
                        //   confirmAlert({
                        //     customUI: ({ title, message, onClose }) => (
                        //       <div className="custom-ui">
                        //         <h4 className="m-0 text-primary">
                        //           Are you sure?
                        //         </h4>
                        //         <hr className="my-2"></hr>
                        //         <p>You want to rate this?</p>
                        //         <button
                        //           className="btn btn-warning mr-2"
                        //           onClick={() => {
                        //             this.setState({ admin_rating: 0, key: Math.random() });
                        //             onClose();
                        //           }}
                        //         >
                        //           No
                        //         </button>
                        //         <button
                        //           className="btn btn-primary"
                        //           onClick={(e) => {
                        //              this.handleRating(value);
                        //             onClose();
                        //           }}
                        //         >
                        //           Yes!
                        //         </button>
                        //       </div>
                        //     ),
                        //     overlayClassName: "overlay-custom-class-name",
                        //   })
                        // }
                        size={24}
                        fillColor="#f1b345"
                      />
                    </div>
                  </div>
                </h3>

                <div class="row pdf">
                  <div class="col-md-12">
                    <div class="panel">
                      <div class="panel-body">
                        <p id="error"></p>

                        <div class="row">
                          <div class="col-md-12">
                            <label>Number</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.ticket_no}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>

                          <div class="col-md-12">
                            <label>Asset ID</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.asset_id}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>

                          <div class="col-md-12">
                            <label>Caller</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.sName}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>

                          <div class="col-md-12">
                            <label>Assigned To</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.eName}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>

                          <div class="col-md-12">
                            <label>Opened</label>
                            <input
                              class="form-control"
                              readonly
                              value={new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }).format(this.state.data.open_date)}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>

                          <div class="col-md-12">
                            <label>Service Name</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.serName}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>

                          <div class="col-md-12">
                            <label>State</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.ticket_status}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          {this.state.data.replacedAsset ? (
                            <div class="col-md-12">
                              <label>New Asset ID </label>
                              <input
                                class="form-control"
                                readonly
                                value={this.state.data.rAsset_id}
                                name="name"
                                type="text"
                              />
                              <br />
                            </div>
                          ) : (
                            ""
                          )}

                          <div class="col-md-12">
                            <label>Short Discription</label>
                            <input
                              class="form-control"
                              readonly
                              value={this.state.data.short_desc}
                              name="name"
                              type="text"
                            />
                            <br />
                          </div>
                          {/* <div class="col-md-6">
                        <label> Work notes</label>
                        <input class="form-control" readonly value={this.state.data.work_note} name="name" type="text"/><br/> 
                    </div> */}

                          <div class="col-md-12">
                            <label>Activities</label>
                            {this.state.Activities.map((actvity, index) => {
                              return (
                                <>
                                  <textarea
                                    class="form-control"
                                    readOnly
                                    type="text"
                                    placeholder={
                                      actvity.work_note +
                                      "  " +
                                      new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                      }).format(actvity.created)
                                    }
                                  >
                                    {actvity.work_note +
                                      "  " +
                                      new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                      }).format(actvity.created)}
                                  </textarea>{" "}
                                  <br />{" "}
                                </>
                              );
                            })}
                          </div>

                          <div class="col-md-12">
                            {this.state.images.map((image, index) => {
                              return (
                                <>
                                  <a
                                    href={`${config.googleAuth.backend}ticket/${image.path}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={`${config.googleAuth.backend}ticket/${image.path}`}
                                      height="150px"
                                      width="150px"
                                    />
                                  </a>{" "}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Adminfooter />
      </div>
    );
  }
}

export default Ticketdetail;
