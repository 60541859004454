
import React from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Pagination, PaginationItem, PaginationLink
} from "reactstrap"

function Pagination1({ totalPage, currentPage, prevPage, nextPage, setNewCurrentPage }) {
    let tolatal = []
    for (let index = 0; index < totalPage; index++) {
        tolatal.push(index)
    }
    return (
        <div style={{
            display: 'block', width: 700, padding: 30
        }}>
            <Pagination>
                <PaginationItem>
                    <PaginationLink previous href="#" onClick={() => setNewCurrentPage(prevPage)} />
                </PaginationItem>
                <PaginationItem>
                    {tolatal.length > 10 ?
                        <>
                            {currentPage <= 3 && <>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(1)}>
                                    {1}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(2)}>
                                    {2}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(3)}>
                                    {3}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(4)}>
                                    {4}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(5)}>
                                    {5}
                                </PaginationLink>
                                &nbsp;.&nbsp;.&nbsp;
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(tolatal.length - 1)}>
                                    {tolatal.length - 1}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(tolatal.length)}>
                                    {tolatal.length}
                                </PaginationLink>
                            </>}
                            {currentPage > 3 && currentPage <= tolatal.length - 3 && <>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(1)}>
                                    {1}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(2)}>
                                    {2}
                                </PaginationLink>
                                &nbsp;.&nbsp;.&nbsp;
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(currentPage - 1)}>
                                    {currentPage - 1}
                                </PaginationLink>
                                <PaginationLink href="#">
                                    {currentPage}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(currentPage + 1)}>
                                    {currentPage + 1}
                                </PaginationLink>
                                &nbsp;.&nbsp;.&nbsp;
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(tolatal.length - 1)}>
                                    {tolatal.length - 1}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(tolatal.length)}>
                                    {tolatal.length}
                                </PaginationLink>
                            </>}
                            {currentPage > tolatal.length - 3 && <>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(1)}>
                                    {1}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(2)}>
                                    {2}
                                </PaginationLink>
                                &nbsp;.&nbsp;.&nbsp;
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(tolatal.length - 4)}>
                                    {tolatal.length - 4}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(tolatal.length - 3)}>
                                    {tolatal.length - 3}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(tolatal.length - 2)}>
                                    {tolatal.length - 2}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(tolatal.length - 1)}>
                                    {tolatal.length - 1}
                                </PaginationLink>
                                <PaginationLink href="#" onClick={() => setNewCurrentPage(tolatal.length)}>
                                    {tolatal.length}
                                </PaginationLink>
                            </>}
                        </>
                        :
                        <>
                            {tolatal.map(element => {
                                return (
                                    <PaginationLink href="#" onClick={() => setNewCurrentPage(element + 1)}>
                                        {element + 1}
                                    </PaginationLink>
                                )
                            })}
                        </>
                    }
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink next href="#" onClick={() => setNewCurrentPage(nextPage)} />
                </PaginationItem>
            </Pagination>
        </div >
    );
}

export default Pagination1;