import React from "react";
import { Link, withRouter } from "react-router-dom";
import Menu from "./menu";
import Footer from "./footer";
import Header from "./header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt from "jsonwebtoken";
import config from "./admin/config.js";
import $ from "jquery";
const axios = require("axios");
class Profile extends React.Component {
  state = {
    editModule: false,
    selectedFile: "",
    user_name: "",
    user_id: "",
    user_flag: "",
    userInfoData: {},
  };

  componentDidMount = (e) => {
    jwt.verify(localStorage.getItem("user_name"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_name: decoded.user_name });
      }
    });
    let sessionFlag = jwt.verify(
      localStorage.getItem("user_flag"),
      "pokemon",
      (err, decoded) => {
        if (err) {
          localStorage.clear();
        } else {
          return decoded;
        }
      }
    );
    this.setState({ user_flag: sessionFlag.user_flag });

    let sessionImg = jwt.verify(
      localStorage.getItem("user_img"),
      "pokemon",
      (err, decoded) => {
        if (err) {
          localStorage.clear();
        } else {
          return decoded;
        }
      }
    );
    this.setState({ user_img: sessionImg.user_img });

    jwt.verify(localStorage.getItem("user_id"), "pokemon", (err, decoded) => {
      if (err) {
        localStorage.clear();
      } else {
        this.setState({ user_id: decoded.user_id });
        this.getUserInfo(decoded.user_id, sessionFlag.user_flag);
      }
    });
  };

  getUserInfo = (stdId, stdFlag) => {
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stdId: stdId, stdFlag: stdFlag }),
    };
    fetch(`${config.googleAuth.backURL}user/getUserInfo`, options)
      .then((res) => {
        return res.json();
      })

      .then((data) => {
        if (data.status == true) {
          this.setState({ userInfoData: {...data.data[0],stdFlag: stdFlag } });
          console.log("userInfoData", this.state.userInfoData);
        } else {
        }
      })

      .catch((err) => {
        console.log("error", err);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.userInfoData.name === "") {
      toast("Name can not be empty");
      error++;
    }

    if (this.state.userInfoData.email === "") {
      toast("Email can not be empty");
      error++;
    }

    if (this.state.userInfoData.mobile === "") {
      toast("Mobile can not be empty");
      error++;
    }

    if (this.state.userInfoData.student_id === "") {
      toast("Id can not be empty");
      error++;
    }

    console.log(error);

    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");

      let newObj = new FormData();
      for (const [key, value] of Object.entries(this.state.userInfoData)) {
        newObj.append(key, value);
      }

      newObj.append("file", this.state.selectedFile);
      newObj.append("user_flag", this.state.user_flag);

      axios
        .post(`${config.googleAuth.backURL}user/updateUserProfile`, newObj)

        .then((res) => {
          if (res.data.status == true) {
            const user_name = jwt.sign(
              { expiresInMinutes: 60, user_name: res.data.fullname },
              "pokemon"
            );
            //const user_email = jwt.sign({ expiresInMinutes: 60,user_email:res.data.email }, 'pokemon')
            //const user_unicode = jwt.sign({ expiresInMinutes: 60,user_unicode:res.data.user_unicode }, 'pokemon')
            const user_img = jwt.sign(
              { expiresInMinutes: 60, user_img: res.data.photo },
              "pokemon"
            );
            //localStorage.setItem("user_unicode",user_unicode)
            localStorage.setItem("user_name", user_name);
            //localStorage.setItem("user_email",user_email)
            localStorage.setItem("user_img", user_img);

            toast("Pending Admin update!!");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast("Ops! Something went wrong please try again later.");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  changedata = (e) => {
    console.log("DefaultUserInfo11 ", this.state);
    this.setState({
      userInfoData: {
        ...this.state.userInfoData,
        [e.target.name]: e.target.value,
      },
    });
  };
  onFileChange = (e) => {
    //this.setState({ userInfoData: { ...this.state.userInfoData, [e.target.name]:e.target.files[0]} });
    this.setState({ selectedFile: e.target.files[0] });
  };

  render() {
    const fallbackSrc =  "/images/user1.png";
    const imageSrc =
      config.googleAuth.backend + "profile/" + this.state.userInfoData["photo"];
    return (
      <div class="appHdr">
        <ToastContainer />
        <header>
          <Header />
        </header>
        <section class="appBody wrapper">
          <div class="login-block form login">
            <div class="login-row">
              <div class="main-heading text-center">
                <h2>Profile Details</h2>
              </div>
              <div class="text-center">
                <div class="prof-img">
                  {/* <img src="images/user1.png"/>  */}
                  <img
                    src={imageSrc}
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = fallbackSrc;
                    }}
                  />
                  {!this.state.editModule ? (
                    <></>
                  ) : (
                    <>
                      <div class="field input form-group uploadImgInput">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          class="form-control"
                          accept="image/*"
                          onChange={this.onFileChange}
                        />
                      </div>
                      <i class="fa fa-edit"></i>
                    </>
                  )}
                </div>
              </div>
              <form onSubmit={this.mySubmit} encType="multipart/form-data">
                <div class="login-form common-form">
                  <div class="field input form-group">
                    <label>Full Name </label>
                    <input
                      class="form-control"
                      type="hidden"
                      name="id"
                      value={this.state.userInfoData["id"]}
                    />
                    <input
                      class="form-control"
                      type="hidden"
                      name="user_flag"
                      value={this.state.user_flag}
                    />
                    {!this.state.editModule ? (
                      <input
                        class="form-control"
                        type="text"
                        name="fullname"
                        placeholder={this.state.userInfoData["fullname"]}
                        readOnly
                      />
                    ) : (
                      <input
                        class="form-control"
                        type="text"
                        name="fullname"
                        onChange={this.changedata}
                        defaultValue={this.state.userInfoData["fullname"]}
                      />
                    )}
                  </div>
                  <div class="field input form-group">
                    <label>Email ID</label>
                    {!this.state.editModule ? (
                      <input
                        class="form-control"
                        type="text"
                        name="email"
                        placeholder={
                          this.state.userInfoData["tmp_email"] !== "" &&
                          this.state.userInfoData["tmp_email"] !== null
                            ? this.state.userInfoData["tmp_email"]
                            : this.state.userInfoData["email"]
                        }
                        readOnly
                      />
                    ) : (
                      <input
                        class="form-control"
                        type="email"
                        name="email"
                        onChange={this.changedata}
                        defaultValue={
                          this.state.userInfoData["tmp_email"] !== "" &&
                          this.state.userInfoData["tmp_email"] !== null
                            ? this.state.userInfoData["tmp_email"]
                            : this.state.userInfoData["email"]
                        }
                      />
                    )}
                    {this.state.userInfoData["tmp_email"] === "" ||
                    this.state.userInfoData["tmp_email"] === null ? (
                      <></>
                    ) : (
                      <small>Pending Admin approval </small>
                    )}
                  </div>
                  <div class="field input form-group">
                    <label>Mobile</label>
                    {!this.state.editModule ? (
                      <input
                        class="form-control"
                        type="text"
                        name="mobile"
                        placeholder={
                          this.state.userInfoData["tmp_mobile"] !== "" &&
                          this.state.userInfoData["tmp_mobile"] !== null
                            ? this.state.userInfoData["tmp_mobile"]
                            : this.state.userInfoData["mobile"]
                        }
                        readOnly
                      />
                    ) : (
                      <input
                        class="form-control"
                        type="text"
                        name="mobile"
                        onChange={this.changedata}
                        defaultValue={
                          this.state.userInfoData["tmp_mobile"] !== "" &&
                          this.state.userInfoData["tmp_mobile"] !== null
                            ? this.state.userInfoData["tmp_mobile"]
                            : this.state.userInfoData["mobile"]
                        }
                      />
                    )}

                    {this.state.userInfoData["tmp_mobile"] === "" ||
                    this.state.userInfoData["tmp_mobile"] === null ? (
                      <></>
                    ) : (
                      <small>Pending Admin approval</small>
                    )}
                  </div>

                 { this.state.userInfoData["stdFlag"] ==="SE" && <div class="field input form-group">
                    <label>Location</label>
                    {!this.state.editModule ? (
                      <input
                        class="form-control"
                        type="text"
                        name="location"
                        placeholder={
                          this.state.userInfoData["location"] !== "" &&
                          this.state.userInfoData["location"] !== null
                            ? this.state.userInfoData["location"]
                            : this.state.userInfoData["location"]
                        }
                        readOnly
                      />
                    ) : (
                      <input
                        class="form-control"
                        type="text"
                        name="location"
                        onChange={this.changedata}
                        defaultValue={
                          this.state.userInfoData["location"] !== "" &&
                          this.state.userInfoData["location"] !== null
                            ? this.state.userInfoData["location"]
                            : this.state.userInfoData["location"]
                        }
                      />
                    )}
                  </div>
}
                  {this.state.userInfoData["student_id"] ? (
                    <>
                      <div class="field input form-group">
                        <label>Student ID</label>

                        {!this.state.editModule ? (
                          <input
                            class="form-control"
                            type="text"
                            name="student_id"
                            placeholder={
                              this.state.userInfoData["tmp_code"] !== "" &&
                              this.state.userInfoData["tmp_code"] !== null
                                ? this.state.userInfoData["tmp_code"]
                                : this.state.userInfoData["student_id"]
                            }
                            readOnly
                          />
                        ) : (
                          <input
                            class="form-control"
                            type="text"
                            name="student_id"
                            placeholder={
                              this.state.userInfoData["tmp_code"] !== "" &&
                              this.state.userInfoData["tmp_code"] !== null
                                ? this.state.userInfoData["tmp_code"]
                                : this.state.userInfoData["student_id"]
                            }
                            readOnly
                          />
                          //<input class="form-control" type="text" name="student_id" onChange={this.changedata} defaultValue={(this.state.userInfoData['tmp_code']!==''&&this.state.userInfoData['tmp_code']!==null)?this.state.userInfoData['tmp_code']:this.state.userInfoData['student_id']}/>
                        )}

                        {this.state.userInfoData["tmp_code"] === "" ||
                        this.state.userInfoData["tmp_code"] === null ? (
                          <></>
                        ) : (
                          <small>Pending Admin approval</small>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div class="field input form-group">
                        <label>Engineer ID</label>

                        {!this.state.editModule ? (
                          <input
                            class="form-control"
                            type="text"
                            name="eng_id"
                            placeholder={
                              this.state.userInfoData["tmp_code"] !== "" &&
                              this.state.userInfoData["tmp_code"] !== null
                                ? this.state.userInfoData["tmp_code"]
                                : this.state.userInfoData["eng_id"]
                            }
                            readOnly
                          />
                        ) : (
                          <input
                            class="form-control"
                            type="text"
                            name="eng_id"
                            placeholder={
                              this.state.userInfoData["tmp_code"] !== "" &&
                              this.state.userInfoData["tmp_code"] !== null
                                ? this.state.userInfoData["tmp_code"]
                                : this.state.userInfoData["eng_id"]
                            }
                            readOnly
                          />
                          // <input class="form-control" type="text" name="eng_id" onChange={this.changedata} defaultValue={(this.state.userInfoData['tmp_code']!==''&&this.state.userInfoData['tmp_code']!==null)?this.state.userInfoData['tmp_code']:this.state.userInfoData['eng_id']}/>
                        )}

                        {this.state.userInfoData["tmp_code"] === "" ||
                        this.state.userInfoData["tmp_code"] === null ? (
                          <></>
                        ) : (
                          <small>Pending Admin approval</small>
                        )}
                      </div>
                    </>
                  )}

                  <div class="field form-btn button mt-40">
                    {!this.state.editModule ? (
                      <a
                        class="btn btn-common btn-blue btn-round btn-big"
                        href="#!"
                        onClick={() => this.setState({ editModule: true })}
                      >
                        Edit
                      </a>
                    ) : (
                      <button
                        type="submit"
                        class="btn btn-common btn-blue btn-round btn-big"
                      >
                        Update{" "}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </section>
      </div>
    );
  }
}
export default Profile;
