import React from "react";
import { Link, withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import { GlobalStoreContext } from "../ContextAPI/MyContext";

class Footer extends React.Component {
  static contextType = GlobalStoreContext;
  state = {
    user_flag: "",
  };
  componentDidMount = (e) => {
    let sessionFlag = jwt.verify(
      localStorage.getItem("user_flag"),
      "pokemon",
      (err, decoded) => {
        if (err) {
          localStorage.clear();
        } else {
          return decoded;
        }
      }
    );
    this.setState({ user_flag: sessionFlag.user_flag });
  };
  render() {
    return (
      <div class="footer-menu">
        <ul>
          <li class="menu-ic">
            <Link class="ftr-icn" to="my-ticket">
              <i class="fa fa-comments" aria-hidden="true"></i>
              <span>Chat</span>
            </Link>
          </li>
          <li class="menu-ic">
            <Link class="ftr-icn" to="my-ticket">
              <i class="fa fa-list" aria-hidden="true"></i>
              <span>My Tickets List</span>
            </Link>
          </li>
          <li class="menu-ic">
            <Link class="ftr-icn active" to="asset-detail">
              <i class="fa fa-home" aria-hidden="true"></i>
              <span>Home</span>
            </Link>
          </li>

          {["UR", "student"].includes(this.state.user_flag) ? (
            <>
              <li class="menu-ic" onClick={() => this.context.updateData(null)}>
                <Link class="ftr-icn" to="create-ticket">
                  <i class="fa fa-ticket" aria-hidden="true"></i>
                  <span>Generate Ticket</span>
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}

          <li class="menu-ic">
            <Link class="ftr-icn" to="services-list">
              <i class="fa fa-anchor" aria-hidden="true"></i>
              <span>Services</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
export default Footer;
