import React from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, Link } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import $ from 'jquery';
import config from "./config.js"


const axios = require("axios");
class Adminlogin extends React.Component {

  //for register
  state = {
    LOGINEMAIL: "",
    LOGINPASSWORD: "",
    loginemailValid: "",
    loginpassValid: "",
    loginflag: false
  }
  initialstate = {
    LOGINEMAIL: "",
    LOGINPASSWORD: "",
    loginemailValid: "",
    loginpassValid: "",
    loginflag: true
  }

  changedata = (e) => {
    this.setState({ [e.target.name]: e.target.value.toLowerCase() })
  }

  //for login
  loginSubmit = e => {
    e.preventDefault();
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (this.state.LOGINEMAIL === "") {
      this.setState({ loginemailValid: 'Email is required*' })

    } else {

      if (reg.test(this.state.LOGINEMAIL) === false) {
        this.setState({ loginemailValid: 'Invalid Email' })

      } else {
        this.setState({ loginemailValid: '' })
      }
    }

    if (this.state.LOGINPASSWORD === "") {
      this.setState({ loginpassValid: 'Password is required*' })

    } else {
      this.setState({ loginpassValid: '' })
    }
    /*console.log(JSON.stringify(this.state));*/
    if (this.state.LOGINEMAIL === "" || this.state.LOGINPASSWORD === "" || reg.test(this.state.LOGINEMAIL) === false) {

    } else {

      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(this.state)
      }
      fetch(`${config.googleAuth.backURL}adminlogin`, options)
        .then(res => {
          return res.json();
        })
        .then(data => {

          if (data.status == true) {
            console.log('login')

            this.setState(this.initialstate)

            const token_email = jwt.sign({ expiresInMinutes: 60, Uidadmin: data.uId }, 'pokemonlove')
            localStorage.setItem("Uidadmin", token_email)

            console.log("localstorage Uid", localStorage.getItem("Uidadmin"))

            jwt.verify(localStorage.getItem("Uidadmin"), 'pokemonlove', (err, decoded) => {
              console.log(decoded)
            })

            //verify
            jwt.verify(token_email, 'pokemonlove', (err, decoded) => {
              console.log("verify", decoded) // bar
            });

            // get the decoded payload and header
            var decoded = jwt.decode(token_email, { complete: true });
            let decode_email = jwt.decode(localStorage.getItem("Uidadmin"))
            console.log("decode data", decode_email)
            console.log("header", decoded.header);
            console.log("payload", decoded.payload)
            toast(data.message);
            window.location.href = `/admin/admindashboard`
          }else {
            toast(data.message);
          }
        })
        .catch(err => {
          console.log("error", err)
        })
    }
  }

  componentDidMount() {
        $('link[href*="/front/css/master.css"]').attr("disabled", "true");
        $('head').append($('<link rel="stylesheet" type="text/css" />').attr('href', '/css/main.css'));    
  }

  render() {
    return (
      <div>
        {this.state.loginflag ? <Redirect to='/admin/admindashboard' /> : ""}
        <ToastContainer />

        <section class="login-block wrapper pd-80">
          <div class="container">
            <div class="login-row">
              <div class="row">
                <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 col-col-xs-12">
                  <div class="admin-logo text-center">
                    {/* <img src="../images/admin/logo.svg" alt="Admin Logo" /> */}
                  </div>
                  <div class="login-form common-form">
                    <form class="form-auth-small" onSubmit={this.loginSubmit}>
                      <div class="form-group">
                        <label for="signin-email" class="control-label sr-only">Email</label>
                        <input type="text" name="LOGINEMAIL" class="form-control" value={this.state.LOGINEMAIL} onChange={this.changedata} placeholder="inf@login.com" />
                        <div class="requiredblk" >{this.state.loginemailValid}</div>
                      </div>
                      <div class="form-group">
                        <label for="signin-password" class="control-label sr-only">Password</label>
                        <input type="password" name="LOGINPASSWORD" class="form-control" value={this.state.LOGINPASSWORD} onChange={this.changedata} placeholder="Password" />
                        <div class="requiredblk" >{this.state.loginpassValid}</div>
                      </div>
                      <div class="form-btn text-center">
                        <button type="submit" class="btn btn-common btn-blue btn-round">LOGIN</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    )
  }
}

export default Adminlogin