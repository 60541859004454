import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./admin/config.js";
import Hostel from "./hostel";
import $ from "jquery";

const axios = require("axios");
class SignUp extends React.Component {
  state = {
    showDiv: "S",
    hostels: [],
    Universities: [],
    uni_id: "",
    // hostel_id: "",
    formdata: {
      id: "",
      name: "",
      email: "",
      password: "",
      student_eng_id: "",
      student_ur_id: "",
      mobile: "",
      location: null,
      checkStudent: false,
    },
  };

  initialState = {
    showDiv: "S",
    hostels: [],
    Universities: [],
    uni_id: "",
    // hostel_id: "",
    formdata: {
      id: "",
      name: "",
      email: "",
      password: "",
      location: null,
      student_eng_id: "",
      student_ur_id: "",
      mobile: "",
      checkStudent: false,
    },
  };

  componentDidMount() {
    console.log("componentDidMount");
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    fetch(`${config.googleAuth.backURL}user/getUniversity`, options)
      .then((res) => {
        //console.log("response",res)
        return res.json();
      })
      .then((data) => {
        if (data.status == true) {
          console.log(data.data);
          this.setState({ Universities: data.data });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  mySubmit = (e) => {
    e.preventDefault();

    let error = 0;
    let arry = "";
    if (this.state.formdata.name === "") {
      toast("Name can not be empty");
      error++;
    }

    if (this.state.formdata.email === "") {
      toast("Email can not be empty");
      error++;
    }

    if (this.state.formdata.password === "") {
      toast("Password can not be empty");
      error++;
    }
    if (this.state.formdata.mobile === "") {
      toast("Mobile can not be empty");
      error++;
    }

    if (
      this.state.formdata.student_ur_id === "" &&
      this.state.showDiv === "UR"
    ) {
      toast("Id can not be empty");
      error++;
    }

    if (
      this.state.formdata.student_eng_id === "" &&
      ["E", "S"].includes(this.state.showDiv)
    ) {
      toast("Id can not be empty");
      error++;
    }

    if (error > 0) {
      $("#error").html(arry);
    } else {
      if (this.state.showDiv === "S") {
        // this.setState({checkStudent: true});
        // return;

        let options1 = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ Code: this.state.formdata.student_eng_id }),
        };

        fetch(`${config.googleAuth.backURL}user/checkStudentCode`, options1)
          .then((res) => {
            //console.log("response",res)
            return res.json();
          })

          .then((data) => {
            if (data.status == true) {
              this.setState({ checkStudent: true });
            } else {
              toast(data.message);
            }
          })

          .catch((err) => {
            console.log("error", err);
          });
        return;
      }

      $("#error").html("");
      let newObj = {
        ...this.state.formdata,
        uni_id: this.state.uni_id,
        // hostel_id: this.state.hostel_id,
      };
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newObj),
      };
      if (this.state.showDiv === "UR") {
        fetch(`${config.googleAuth.backURL}user/ur_registration`, options)
          .then((res) => {
            //console.log("response",res)
            return res.json();
          })

          .then((data) => {
            if (data.status == true) {
              toast("Successfully Register!!");

              setTimeout(() => {
                this.props.history.push("/login");
              }, 2000);
            } else {
              toast(data.message);
            }
          })

          .catch((err) => {
            console.log("error", err);
          });
      } else {
        fetch(`${config.googleAuth.backURL}user/registration`, options)
          .then((res) => {
            //console.log("response",res)
            return res.json();
          })

          .then((data) => {
            if (data.status == true) {
              toast("Successfully Register!!");

              setTimeout(() => {
                this.props.history.push("/login");
              }, 2000);
            } else {
              toast(data.message);
            }
          })

          .catch((err) => {
            console.log("error", err);
          });
      }
    }
  };
  changedata = (e) => {
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
  };

  getHostelbyUni = (e) => {
    let uni_id = e.target.value;
    this.setState({ uni_id: uni_id });
    // if (uni_id != "") {
    //   this.setState({ uni_id: uni_id });
    //   let options = {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ id: uni_id }),
    //   };
    //   fetch(`${config.googleAuth.backURL}user/getHostelbyUni`, options)
    //     .then((res) => {
    //       return res.json();
    //     })
    //     .then((data) => {
    //       if (data.status == true) {
    //         this.setState({ hostels: data.data });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("error", err);
    //     });
    // }
  };

  // getBlockbyHostel = (e) => {
  //   let hostel_id = e.target.value;
  //   if (hostel_id != "") {
  //     this.setState({ hostel_id: hostel_id });
  //   }
  // };
  render() {
    console.log('this.state', this.state)
    return (
      <div class="appHdr">
        <ToastContainer />
        <header>
          <div class="main-header">
            <div class="hdr-row">
              <a href="/" class="mdlBack-btn">
                <i class="fa fa-angle-left" aria-hidden="true"></i> Back
              </a>
            </div>
          </div>
        </header>
        {this.state.checkStudent ? (
          <Hostel data={this.state.formdata} />
        ) : (
          <section class="appBody wrapper">
            <div class="login-block form login">
              <div class="login-row">
                <div class="main-heading text-center">
                  <h2>Register Now</h2>
                </div>
                <div class="logn-chck">
                  <div class="form-group rgstr-type-grp">
                    <div class="radio radio-success radio-inline">
                      <input
                        name="registerType"
                        id="seller"
                        value="S"
                        type="radio"
                        checked={this.state.showDiv == "S"}
                        onClick={(e) =>
                          this.setState({ showDiv: e.target.value })
                        }
                      />
                      <label for="seller" class="radio-inline">
                        Student
                      </label>
                    </div>
                  </div>
                  <div class="form-group rgstr-type-grp">
                    <div class="radio radio-success radio-inline">
                      <input
                        name="registerType"
                        id="buyer"
                        value="E"
                        type="radio"
                        checked={this.state.showDiv == "E"}
                        onClick={(e) =>
                          this.setState({ showDiv: e.target.value })
                        }
                      />
                      <label for="buyer" class="radio-inline">
                        Service Engineer
                      </label>
                    </div>
                  </div>
                  <div class="form-group rgstr-type-grp">
                    <div class="radio radio-success radio-inline">
                      <input
                        name="registerType"
                        id="Representative"
                        value="UR"
                        type="radio"
                        checked={this.state.showDiv == "UR"}
                        onClick={(e) =>
                          this.setState({ showDiv: e.target.value })
                        }
                      />
                      <label for="Representative" class="radio-inline">
                        University Representative
                      </label>
                    </div>
                  </div>
                </div>

                <form onSubmit={this.mySubmit}>
                  <p id="error"></p>
                  <div class="login-form common-form">
                    {this.state.showDiv === "UR" && (
                      <>
                        <div class="field input form-group">
                          <label>Select University </label>
                          <div class="src-cat-grp">
                            <select
                              class="form-control"
                              name="university"
                              id="university"
                              onChange={this.getHostelbyUni}
                            >
                              <option value="">Choose</option>
                              {this.state.Universities.map((uni, index) => {
                                return (
                                  <option value={uni.id}>{uni.name}</option>
                                );
                              })}
                            </select>
                            <div class="src-bars">
                              <i
                                class="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                        {/* <div class="field input form-group">
                          <label>Select Hostel </label>
                          <div class="src-cat-grp">
                            <select
                              class="form-control"
                              name="hostel"
                              id="dpdHomeCategory"
                              onChange={this.getBlockbyHostel}
                            >
                              <option value="">Choose</option>
                              {this.state.hostels.map((hostel, index) => {
                                return (
                                  <option value={hostel.id}>
                                    {hostel.name}
                                  </option>
                                );
                              })}
                            </select>
                            <div class="src-bars">
                              <i
                                class="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div> */}
                      </>
                    )}
                    <div class="field input form-group">
                      <label>Email ID </label>
                      <input
                        spellcheck="false"
                        class="form-control"
                        type="email"
                        onChange={this.changedata}
                        value={this.state.formdata.email}
                        name="email"
                        placeholder="Enter your email"
                        required=""
                      />
                    </div>
                    <div class="field input form-group">
                      <label>Password</label>
                      <input
                        spellcheck="false"
                        class="form-control"
                        type="password"
                        onChange={this.changedata}
                        value={this.state.formdata.password}
                        name="password"
                        placeholder="Enter your password"
                        required=""
                      />
                    </div>
                    <div class="field input form-group">
                      <label>Full Name</label>
                      <input
                        spellcheck="false"
                        class="form-control"
                        type="text"
                        name="name"
                        onChange={this.changedata}
                        value={this.state.formdata.name}
                        placeholder="Full Name"
                        required=""
                      />
                    </div>

                    {this.state.showDiv == "S" && (
                      <div class="field input form-group">
                        <label>Student ID</label>
                        <input
                          spellcheck="false"
                          class="form-control"
                          type="text"
                          name="student_eng_id"
                          onChange={this.changedata}
                          value={this.state.formdata.student_eng_id}
                          placeholder="Student ID"
                          required=""
                        />
                      </div>
                    )}

                    {this.state.showDiv == "E" && (
                      <div class="field input form-group">
                        <label>Service Engineer ID</label>
                        <input
                          spellcheck="false"
                          class="form-control"
                          type="text"
                          onChange={this.changedata}
                          value={this.state.formdata.student_eng_id}
                          name="student_eng_id"
                          placeholder="Enter your Service Engineer ID"
                          required=""
                        />
                      </div>
                    )}

                    {this.state.showDiv == "UR" && (
                      <div class="field input form-group">
                        <label>University Representative ID</label>
                        <input
                          spellcheck="false"
                          class="form-control"
                          type="text"
                          onChange={this.changedata}
                          value={this.state.formdata.student_ur_id}
                          name="student_ur_id"
                          placeholder="Enter your University Representative ID"
                          required=""
                        />
                      </div>
                    )}
                    <div class="field input form-group">
                      <label>Mobile</label>
                      <input
                        spellcheck="false"
                        class="form-control"
                        type="text"
                        onChange={this.changedata}
                        value={this.state.formdata.mobile}
                        name="mobile"
                        placeholder="Mobile"
                        required=""
                      />
                    </div>

                    {this.state.showDiv == "E" && (
                      <div class="field input form-group">
                        <label>Location</label>
                        <input
                          spellcheck="false"
                          class="form-control"
                          type="text"
                          onChange={this.changedata}
                          value={this.state.formdata.location}
                          name="location"
                          placeholder="Location"
                          required=""
                        />
                      </div>
                    )}

                    <div class="field form-btn button mt-40">
                      {/* { !this.state.showDiv ? (<>
                        <Link class="nav-link" to="/login">Next</Link>
                      </>) : ( <> */}
                      <button
                        type="submit"
                        class="btn btn-common btn-blue btn-round btn-big"
                      >
                        Next{" "}
                      </button>
                    </div>
                  </div>
                </form>

                <div class="accont-signp mt-30">
                  <p>
                    Already have an account?{" "}
                    <Link class="nav-link" to="/login">
                      Sign In{" "}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
export default withRouter(SignUp);
